import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import { baseUrl } from "../helper/helper";
import ReportHeader from "../components/reportComponents/reportHeader";
import CountUp from 'react-countup';

import $ from 'jquery';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import 'nice-select2/dist/css/nice-select2.css';
import NiceSelect from 'nice-select2';
import { AppContext } from "../globleDataComponent/contaxtData";

const ShipmentSummary = () => {
  const { globalData, setGlobalData } = useContext(AppContext);

    const [filterOpen, setFilterOpen] = useState(false)
    const datePickerRef = useRef(false);  
    const initializedRef = useRef(false); 

    
    useEffect(() => {
        if ($('.nice_Select').length && !initializedRef.current) {
        $('.nice_Select').each(function () {
            new NiceSelect(this);
        });
        initializedRef.current = true;
        }
    }, []);

    useEffect(() => {
        // Initialize Air Datepicker
        new AirDatepicker(datePickerRef.current, {
          range: true,
          multipleDatesSeparator: ' - ',
          dateFormat: 'MM/dd/yyyy', // Change this format as needed (e.g., 'dd/MM/yyyy')
          language: {
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            months: [
              'January', 'February', 'March', 'April', 'May', 'June',
              'July', 'August', 'September', 'October', 'November', 'December'
            ],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear',
            dateFormat: 'MM/dd/yyyy',
            timeFormat: 'HH:mm',
            firstDay: 0 // Sunday as the first day of the week
          },
          position: 'bottom left', // Adjusts the calendar position
          autoClose: true, // Closes the calendar after selecting a date range
          onSelect: ({ formattedDate }) => {
            console.log('Selected date range:', formattedDate);
          }
        });
      }, []);
  return (
    <div>
      <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
        <Header />

        <div className="main_content_iner overly_inner ">
          <div className="container-fluid p-0 ">
            <div className="row">
             <ReportHeader />
              <div className="col-lg-12">
                <div className="white_card card_height_100 mb_30 filterCard">
                  <div className="white_card_header carHeadertwo">
                    <div className="box_header m-0">
                      <div className="main-title">
                        <h3 className="m-0">Filters</h3>
                        <button
                          className="collapsed"
                        //   data-toggle="collapse"
                        //   data-target="#filter"
                        //   aria-expanded="false"
                        //   aria-controls="filter"
                        onClick={()=>setFilterOpen(!filterOpen)}
                        >
                          <i className="fa-solid fa-filter" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`white_card_body collapse ${filterOpen?"show":''}`}
                    id="filter"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="ProductName">Configuration</label>
                          <select className="nice_Select wide" id="ProductName">
                            <option value="">--select--</option>
                            <option value={1}>Shopify</option>
                            <option value={2}>RouteWay</option>
                            <option value={3}>WordPress</option>
                            <option value={4}>Wix</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label htmlFor="PaymentMethod">Date</label>
                          <input
                            className="form-control datepicker-here  digits"
                            type="text"
                            ref={datePickerRef}
                            placeholder="Select date rang"
                          />
                        </div>
                      </div>
                      <div className="col-md-1">
                        <label
                          htmlFor="PaymentMethod"
                          style={{ display: "block", visibility: "hidden" }}
                        >
                          Date
                        </label>
                        <button type="submit" className="btn btn-primary">
                          <i className="fa-solid fa-magnifying-glass" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="user_crm_wrapper">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                        <div className="plan_left d-flex align-items-center">
                          <div className="thumb">
                            <img src={`${baseUrl}front/img/icon/Delivered.png`} alt="" />
                          </div>
                          <div>
                            <span>Booked</span>
                            <h5>
                              <span className="counter">
                              <CountUp
                                    start={0}
                                    end={337}
                                    duration={5000 / 1000} 
                                    delay={0.1} 
                                />
                                                    </span>
                            </h5>
                          </div>
                        </div>
                        <div className="header_more_tool">
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                            >
                              <i className="ti-more-alt" />
                            </span>
                            <div
                              className="dropdown-menu dropdown-menu-right"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <Link className="dropdown-item" to="#">
                                <i className="ti-printer" /> Print
                              </Link>
                              <Link className="dropdown-item" to="#">
                                <i className="fa fa-download" /> Download
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                        <div className="plan_left d-flex align-items-center">
                          <div className="thumb">
                            <img src={`${baseUrl}front/img/icon/orders.png`} alt="" />
                          </div>
                          <div>
                            <span>Shipment Picked</span>
                            <h5>
                              <span className="counter">
                              <CountUp
                                    start={0}
                                    end={337}
                                    duration={5000 / 1000} 
                                    delay={0.1} 
                                />
                              </span>
                            </h5>
                          </div>
                        </div>
                        <div className="header_more_tool">
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                            >
                              <i className="ti-more-alt" />
                            </span>
                            <div
                              className="dropdown-menu dropdown-menu-right"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <Link className="dropdown-item" to="#">
                                <i className="ti-printer" /> Print
                              </Link>
                              <Link className="dropdown-item" to="#">
                                <i className="fa fa-download" /> Download
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                        <div className="plan_left d-flex align-items-center">
                          <div className="thumb">
                            <img src={`${baseUrl}front/img/icon/Intransit.png`} alt="" />
                          </div>
                          <div>
                            <span>Deliverd</span>
                            <h5>
                              <span className="counter"> <CountUp
                                    start={0}
                                    end={62}
                                    duration={5000 / 1000} 
                                    delay={0.1} 
                                /></span>
                            </h5>
                          </div>
                        </div>
                        <div className="header_more_tool">
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                            >
                              <i className="ti-more-alt" />
                            </span>
                            <div
                              className="dropdown-menu dropdown-menu-right"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <Link className="dropdown-item" to="#">
                                <i className="ti-printer" /> Print
                              </Link>
                              <Link className="dropdown-item" to="#">
                                <i className="fa fa-download" /> Download
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                        <div className="plan_left d-flex align-items-center">
                          <div className="thumb">
                            <img src={`${baseUrl}front/img/icon/rto.png`} alt="" />
                          </div>
                          <div>
                            <span>RTO Initiated</span>
                            <h5>
                              <span className="counter">
                              <CountUp
                                    start={0}
                                    end={212}
                                    duration={5000 / 1000} 
                                    delay={0.1} 
                                />
                              </span>
                            </h5>
                          </div>
                        </div>
                        <div className="header_more_tool">
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                            >
                              <i className="ti-more-alt" />
                            </span>
                            <div
                              className="dropdown-menu dropdown-menu-right"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <Link className="dropdown-item" to="#">
                                <i className="ti-printer" /> Print
                              </Link>
                              <Link className="dropdown-item" to="#">
                                <i className="fa fa-download" /> Download
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                        <div className="plan_left d-flex align-items-center">
                          <div className="thumb">
                            <img src={`${baseUrl}front/img/icon/rto-delivered.png`} alt="" />
                          </div>
                          <div>
                            <span>RTO Deliverd</span>
                            <h5>
                              <span className="counter">
                              <CountUp
                                    start={0}
                                    end={119}
                                    duration={5000 / 1000} 
                                    delay={0.1} 
                                />
                              </span>
                            </h5>
                          </div>
                        </div>
                        <div className="header_more_tool">
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                            >
                              <i className="ti-more-alt" />
                            </span>
                            <div
                              className="dropdown-menu dropdown-menu-right"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <Link className="dropdown-item" to="#">
                                <i className="ti-printer" /> Print
                              </Link>
                              <Link className="dropdown-item" to="#">
                                <i className="fa fa-download" /> Download
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </div>
  );
};

export default ShipmentSummary;
