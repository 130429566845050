import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ProductWiseSummaryTable = () => {
  useEffect(() => {
    if (window.$ && window.$.fn.DataTable) {
        const table = window.$('#product_wise_summary_table').DataTable({
            bLengthChange: false,
            bDestroy: true,
            language: {
              search: "<i class='ti-search'></i>",
              searchPlaceholder: 'Quick Search',
              paginate: {
                next: "<i class='ti-arrow-right'></i>",
                previous: "<i class='ti-arrow-left'></i>",
              },
            },
            columnDefs: [
              { visible: false },
            ],
            responsive: true,
            searching: true,
          }); 

        // Cleanup on component unmount
        //   return () => {
        //     if (window.$.fn.DataTable.isDataTable('#DataTables_Table_2')) {
        //       table.destroy(true);
        //     }
        //   };
    }
}, []);

  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-lg-12">
          <div className="white_card card_height_100 mb_20 ">
            <div className="white_card_header">
              <div className="box_header m-0">
                <div className="main-title">
                  <h3 className="m-0">Product Wise Summary</h3>
                </div>
                <div className="header_more_tool">
                  <div className="dropdown">
                    <span
                      className="dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                    >
                      <i className="ti-more-alt" />
                    </span>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link className="dropdown-item" href="#">
                        <i className="ti-trash" />
                        Delete
                      </Link>
                      <Link className="dropdown-item" href="#">
                        <i className="ti-printer" />
                        Print
                      </Link>
                      <Link className="dropdown-item" href="#">
                        <i className="fa fa-download" /> Download
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="white_card_body QA_section orderList manageList">
              <div className="QA_table">
                <div className="dataTables_wrapper no-footer">
                  <table className="table orderlisttable dataTable lms_table_active p-0 collapsed " id="product_wise_summary_table">
                    <thead>
                      <tr role="row">
                        <th>Product Name</th>
                        <th>Product SKU</th>
                        <th>Total Shipments</th>
                        <th>Booked</th>
                        <th>Pending Pickup</th>
                        <th>In Transit</th>
                        <th>Delivered</th>
                        <th>RTO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr role="row" className="odd">
                        <td> Roop Ujala Face Pack 200gm </td>
                        <td />
                        <td>1047</td>
                        <td>0(0%)</td>
                        <td>17(1.3%)</td>
                        <td>983(83.78%)</td>
                        <td>148(11.09%)</td>
                        <td>50(3.76%)</td>
                      </tr>
                      <tr role="row" className="odd">
                        <td> Roop Ujala Face Pack 200gm </td>
                        <td />
                        <td>1047</td>
                        <td>0(0%)</td>
                        <td>17(1.3%)</td>
                        <td>983(83.78%)</td>
                        <td>148(11.09%)</td>
                        <td>50(3.76%)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductWiseSummaryTable;
