import React from 'react'
import { baseUrl } from '../helper/helper'

const ProductDetailsBottomSection = () => {
  return (
    <>
       <div className="col-md-12">
                                        <div className="otherDetails">
                                            <h3 className="f_w_800 titleprmain">Routeway Assurance</h3>
                                            <ul className="assuranceTabs">
                                                <li>
                                                    <img src={`${baseUrl}front/img/icon/quality-assurance.png`} alt="ico" />
                                                    <h5>Quality Assured Poducts</h5>
                                                </li>
                                                <li>
                                                    <img src={`${baseUrl}front/img/icon/Free-Shipping.png`} alt="ico" />
                                                    <h5>Free Shipping</h5>
                                                </li>
                                                <li>
                                                    <img src={`${baseUrl}front/img/icon/cash-on-delivery.png`} alt="ico" />
                                                    <h5>Cash On Delivery</h5>
                                                </li>
                                                <li>
                                                    <img src={`${baseUrl}front/img/icon/product-return.png`} alt="ico" />
                                                    <h5>5 Days Easy Return</h5>
                                                </li>
                                              
                                            </ul>
                                        </div>
                                        
                                        <div className="prheighlights">
                                            <h3 className="f_w_800 titleprmain">Highlights</h3>
                                            <ul className="normalList">
                                              <li>
                                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates officia enim perferendis aperiam, non temporibus nobis at sapiente magni beatae molestias amet deserunt! Ea, dicta eaque assumenda tenetur numquam omnis.
                                              </li>
                                              <li>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt laboriosam cumque voluptatem accusamus natus aperiam ipsam rerum. Doloribus, quaerat quo! Mollitia voluptatum adipisci numquam nam eveniet quaerat. Eos, inventore consequuntur?
                                              </li>
                                              <li>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse voluptatum qui vel itaque, dolorum molestiae facere dolores culpa eum delectus ut vero aliquid aperiam repellendus quo? Omnis iste et ullam?
                                              </li>
                                              <li>
                                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam ad quisquam quis quod, sapiente provident. Temporibus quam optio nisi illo, eligendi soluta fugiat, sit expedita aliquid, voluptatum adipisci veritatis neque?
                                              </li>
                                            </ul>
                                        </div>
                                    </div>
    </>
  )
}

export default ProductDetailsBottomSection
