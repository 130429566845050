import React, { useEffect } from "react";

const DailyOrderSummary = () => {

    

    useEffect(() => {
        if (window.$ && window.$.fn.DataTable) {
            const table = window.$('#daily_order_summary_table').DataTable(
                {
                    bLengthChange: false,
                    bDestroy: true,
                    language: {
                      search: "<i class='ti-search'></i>",
                      searchPlaceholder: 'Quick Search',
                      paginate: {
                        next: "<i class='ti-arrow-right'></i>",
                        previous: "<i class='ti-arrow-left'></i>",
                      },
                    },
                    columnDefs: [
                      { visible: false },
                    ],
                    responsive: true,
                    searching: true,
                  }
            );
          

            // Cleanup on component unmount
            //   return () => {
            //     if (window.$.fn.DataTable.isDataTable('#DataTables_Table_2')) {
            //       table.destroy(true);
            //     }
            //   };
        }
    }, []);


  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-lg-12">
          <div className="white_card card_height_100 mb_20 ">
            <div className="white_card_header">
              <div className="box_header m-0">
                <div className="main-title">
                  <h3 className="m-0">Daily Order Summary</h3>
                </div>
                <div className="header_more_tool">
                  <div className="dropdown">
                    <span
                      className="dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                    >
                      <i className="ti-more-alt" />
                    </span>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        <i className="ti-trash" />
                        Delete
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="ti-printer" />
                        Print
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="fa fa-download" /> Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="white_box_tittle list_header mt-3" style="margin-bottom: 0px;">
                                      <div class="box_right d-flex lms_block">
                                          <div class="serach_field_2">
                                              <div class="search_inner">
                                                  <form active="#">
                                                      <div class="search_field">
                                                          <input type="text" placeholder="Search content here...">
                                                      </div>
                                                      <button type="submit"> <i class="ti-search"></i> </button>
                                                  </form>
                                              </div>
                                          </div>
                                          <div class="add_button ml-10">
                                              <a href="#" data-toggle="modal" data-target="#addcategory" class="btn_1">Add New</a>
                                          </div>
                                      </div>
                                  </div> */}
            </div>
            <div className="white_card_body QA_section orderList manageList">
              <div className="QA_table">
                <div className="dataTables_wrapper no-footer">
                  <table className="table orderlisttable dataTable lms_table_active p-0 collapsed " id="daily_order_summary_table">
                    <thead>
                      <tr role="row">
                        <th>Pushed Date &amp; Time</th>
                        <th>Shipment Picked</th>
                        <th>In Transit</th>
                        <th>Exception</th>
                        <th>Deliverd</th>
                        <th>RTO In-Transit</th>
                        <th>RTO Delivered</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr role="row" className="odd">
                        <td>09 Aug 2024, 10:16 AM</td>
                        <td>
                          24 <i className="fa-solid fa-cloud-arrow-down" />
                        </td>
                        <td>
                          1
                          <span>
                            (4.17%)
                            <a href="#" className="action_btn mr_10">
                              <i className="fa-solid fa-cloud-arrow-down" />
                            </a>
                          </span>
                        </td>
                        <td>
                          4
                          <span>
                            (16.67%)
                            <a href="#" className="action_btn mr_10">
                              <i className="fa-solid fa-cloud-arrow-down" />
                            </a>
                          </span>
                        </td>
                        <td>
                          1
                          <span>
                            (4.17%)
                            <a href="#" className="action_btn mr_10">
                              <i className="fa-solid fa-cloud-arrow-down" />
                            </a>
                          </span>
                        </td>
                        <td>
                          17
                          <span>
                            (70.83%)
                            <a href="#" className="action_btn mr_10">
                              <i className="fa-solid fa-cloud-arrow-down" />
                            </a>
                          </span>
                        </td>
                        <td>
                          1
                          <span>
                            (4.17%)
                            <a href="#" className="action_btn mr_10">
                              <i className="fa-solid fa-cloud-arrow-down" />
                            </a>
                          </span>
                        </td>
                      </tr>
                      <tr role="row" className="odd">
                        <td>09 Aug 2024, 10:16 AM</td>
                        <td>
                          24 <i className="fa-solid fa-cloud-arrow-down" />
                        </td>
                        <td>
                          1
                          <span>
                            (4.17%)
                            <a href="#" className="action_btn mr_10">
                              <i className="fa-solid fa-cloud-arrow-down" />
                            </a>
                          </span>
                        </td>
                        <td>
                          4
                          <span>
                            (16.67%)
                            <a href="#" className="action_btn mr_10">
                              <i className="fa-solid fa-cloud-arrow-down" />
                            </a>
                          </span>
                        </td>
                        <td>
                          1
                          <span>
                            (4.17%)
                            <a href="#" className="action_btn mr_10">
                              <i className="fa-solid fa-cloud-arrow-down" />
                            </a>
                          </span>
                        </td>
                        <td>
                          17
                          <span>
                            (70.83%)
                            <a href="#" className="action_btn mr_10">
                              <i className="fa-solid fa-cloud-arrow-down" />
                            </a>
                          </span>
                        </td>
                        <td>
                          1
                          <span>
                            (4.17%)
                            <a href="#" className="action_btn mr_10">
                              <i className="fa-solid fa-cloud-arrow-down" />
                            </a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyOrderSummary;
