import React, {useEffect, useRef} from 'react'
import $ from 'jquery';

const DeliverybyMargin = () => {

    const tableRef = useRef(null);
  useEffect(() => {
    // Initialize DataTable
    if(tableRef){
    const table = $(tableRef.current).DataTable({
      bLengthChange: false,
      bDestroy: true,
      language: {
        search: "<i class='ti-search'></i>",
        searchPlaceholder: 'Quick Search',
        paginate: {
          next: "<i class='ti-arrow-right'></i>",
          previous: "<i class='ti-arrow-left'></i>",
        },
      },
      columnDefs: [
        { visible: false },
      ],
      responsive: true,
      searching: false,
    });

    // Cleanup on component unmount
    // return () => {
    //   table.destroy(true);
    // };
  }
  }, []);
  return (
    <div className="col-lg-6">
              <div className="white_card card_height_100 mb_20 ">
                <div className="white_card_header">
                  <div className="box_header m-0">
                    <div className="main-title">
                      <h3 className="m-0">Delivery by Margin <span className="text"> <i className="fa-solid fa-circle-exclamation" data-toggle="tooltip" title="Margin % shown here is a % of Routeway Price"></i> </span> </h3>
                    </div>
                    <div className="header_more_tool">
                      <div className="dropdown"> <span className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown"> <i className="ti-more-alt"></i> </span>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton"> <a className="dropdown-item" href="#"> <i className="ti-trash"></i> Delete</a> <a className="dropdown-item" href="#"> <i className="ti-printer"></i> Print</a> <a className="dropdown-item" href="#"> <i className="fa fa-download"></i> Download</a> </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="white_card_body QA_section">
                  <div className="QA_table ">
                    <div id="" className="dataTables_wrapper no-footer">
                      <table className="table tableDatatotal lms_table_active p-0 collapsed cenetered-table normalTable"
                                                    ref={tableRef}>
                        <thead>
                          <tr role="row">
                              <th scope="col" rowspan="2" className="sorting_asc" tabindex="0" aria-controls="DataTables_Table_1"  colspan="1" 
                              style={{width: '152.2px'}} aria-sort="ascending" aria-label="State: activate to sort column descending">
                            Margin %
                              </th>
                            <th scope="col" colspan="2" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1">Your Analysis</th>
                            <th scope="col" colspan="2" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1">Platform Analysis <span className="text"><i data-toggle="tooltip" title="This shows the order share and delivered% of all orders across different dropshippers on the Routeway platform." className="fa-solid fa-circle-exclamation"></i></span> </th>
                          </tr>
                          <tr role="row">
                            <th>Order Share %</th>
                            <th>Delivered %</th>
                            <th>Order Share %</th>
                            <th>Delivered %</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" className="odd">
                            <td tabindex="0" className="sorting_1">1% - 100%</td>
                            <td className="color_text_6">1%</td>
                            <td className="color_text_6">42%</td>
                            <td className="color_text_6">7%</td>
                            <td className="color_text_6">34%</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td tabindex="0" className="sorting_1">1% - 100%</td>
                            <td className="color_text_6">1%</td>
                            <td className="color_text_6">42%</td>
                            <td className="color_text_6">7%</td>
                            <td className="color_text_6">34%</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td tabindex="0" className="sorting_1">1% - 100%</td>
                            <td className="color_text_6">1%</td>
                            <td className="color_text_6">42%</td>
                            <td className="color_text_6">7%</td>
                            <td className="color_text_6">34%</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td tabindex="0" className="sorting_1">1% - 100%</td>
                            <td className="color_text_6">1%</td>
                            <td className="color_text_6">42%</td>
                            <td className="color_text_6">7%</td>
                            <td className="color_text_6">34%</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td tabindex="0" className="sorting_1">1% - 100%</td>
                            <td className="color_text_6">1%</td>
                            <td className="color_text_6">42%</td>
                            <td className="color_text_6">7%</td>
                            <td className="color_text_6">34%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  )
}

export default DeliverybyMargin
