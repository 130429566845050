import React from 'react'
import aksLogo from '../front/img//aks.png';


export default function Footer() {
  return (
    <>
       <div className="footer_part">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="footer_iner text-left">
                    <p>2024 © Routeway | All Rights Reserved</p>
                  </div>
                </div>
                <div className="col-md-6 aks-logo">
                  <div className="ltn__copyright-menu text-end">
                    <a href="https://www.akswebsoft.com" title="AKS Websoft Consulting Pvt. Ltd." target="_blank" rel="noreferrer">
                     <img src={aksLogo} alt="AKS Websoft Consulting Pvt. Ltd." /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}
