import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChart = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const chBar = chartRef.current;

    if (chartInstance.current) {
      // Destroy previous chart instance if it exists
      chartInstance.current.destroy();
    }

    // Create new chart instance
    chartInstance.current = new Chart(chBar, {
      type: 'bar',
      data: {
        labels: ["New Order", "Cancel By DS", "Cancel By System", "Delivered", "RTO"],
        datasets: [
          {
            data: [13, 99, 6, 28, 112],
            backgroundColor: '#007c84',
            borderRadius: 10,
            barThickness: 30,
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            barPercentage: 0.4,
            categoryPercentage: 0.5
          }
        }
      }
    });

    // Cleanup function to destroy the chart when component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <canvas 
      ref={chartRef} 
      style={{ display: 'block', height: '269px', width: '539px' }} 
      width="673" 
      height="336"
      className="chartjs-render-monitor"
    ></canvas>
  );
};

export default BarChart;
