import React, { createContext, useState } from 'react'

export const AppContext = createContext();
const AppProvider = ({ children }) => {

    const [globalData, setGlobalData] = useState({
        user:null,
        menu:false,
        mobileMenu:false,
    })
  return (
    <AppContext.Provider value={{globalData, setGlobalData}}>
       {children}
    </AppContext.Provider>
  )
}

export default AppProvider
