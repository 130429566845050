import React from 'react'
import { baseUrl } from '../../helper/helper'
import { Link } from 'react-router-dom'

const RouteWayUpadteSection = ({data}) => {
  return (
    <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade active show"
                            id="AllUpdates"
                            role="tabpanel"
                            aria-labelledby="AllUpdates-tab"
                          >
                            <div className="updatesWrapper">
                              <div className="row">
                                {data.length>0 && data.map((item, index)=>(
                                    <div className="col-md-6">
                                    <Link to="#">
                                      <div className="updateCard">
                                        <div className="updateTitle">
                                          <h4>{item.title}</h4>
                                          <p>{item.date}</p>
                                        </div>
                                        <div className="updateImg">
                                          <img
                                            src={item.image}
                                            alt="img"
                                          />
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                ))}
                                {/* <div className="col-md-6">
                                  <Link to="#">
                                    <div className="updateCard">
                                      <div className="updateTitle">
                                        <h4>Just In: New Arrivals</h4>
                                        <p>25 Sep 2024</p>
                                      </div>
                                      <div className="updateImg">
                                        <img
                                          src={`${baseUrl}front/img/updates/img1.jpg`}
                                          alt="img"
                                        />
                                      </div>
                                    </div>
                                  </Link>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          
                        </div>
  )
}

export default RouteWayUpadteSection
