import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Category from '../page/category';
import { baseUrl } from '../helper/helper';
import { AppContext } from '../globleDataComponent/contaxtData';


export default function Sidebar() { 
    const { globalData, setGlobalData } = useContext(AppContext);


    const loaction = useLocation();
    const currentUrl = loaction.pathname;

    function mobileMenuClose(){
        console.log('menu togle')
        
        setGlobalData((prevData) => {
          const updatedData = { ...prevData, mobileMenu: !prevData.mobileMenu };
          console.log('Updated Global Data:', updatedData);
          return updatedData;
        })
       }


    const rtoIntelligence = ['/rto-pincodes', '/rto-faqs'];
    const accountUrls = ['/payment-invoice', '/purchase-bill'];
    const reportUrls = ['/custom-reports', '/product-wise-summary', '/state-wise-summary', '/daily-summary', '/top-ndr-reasons', '/shipment-summary'];
    const settingUrls = ['/setting', '/api-configuration', '/company-info', '/payment-setting' , '/dropshipper', '/contract' , '/account-setting'];
  return (
    <> 
    <nav className={`sidebar ${globalData.menu?"mini_sidebar":''}  ${globalData.mobileMenu?"active_sidebar":''}`}>
        <div className="logo d-flex justify-content-between">
            <Link className="large_logo" to="/"><img src={`${baseUrl}front/img/logo.png`} alt="" /></Link>
            <Link className="small_logo" to="/"><img src={`${baseUrl}front/img/mini_logo.png`} alt="" /></Link>
            <div className="sidebar_close_icon d-lg-none" 
            onClick={mobileMenuClose}>
                <i className="ti-close"></i>
            </div>
        </div>
        <ul id="sidebar_menu">
            <li className="">
                <Link to="/" aria-expanded="false" className={`${currentUrl==="/"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-house"></i>
                    </div>
                    <div className="nav_title">
                        <span>Home</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/analytics" aria-expanded="false" className={`${currentUrl==="/analytics"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-chart-simple"></i>
                    </div>
                    <div className="nav_title">
                        <span>Analytics</span>
                    </div>
                </Link>
            </li>

            <li class="">
                <Link to={`${baseUrl}manage-products`} aria-expanded="false"
                 className={`${currentUrl==="/manage-products"?'active':''}`}>
                    <div class="nav_icon_small">
                        <i class="fa-solid fa-boxes-stacked"></i>
                    </div>
                    <div class="nav_title">
                        <span>Manage Products</span>
                    </div>
                </Link>
            </li>

            <li class="">
                <Link to={`${baseUrl}source-a-product`} aria-expanded="false" class=""
                className={`${currentUrl==="/source-a-product"?'active':''}`} >
                    <div class="nav_icon_small">
                        <i class="fa-solid fa-box-archive"></i>
                    </div>
                    <div class="nav_title">
                        <span>Source a Product</span>
                    </div>
                </Link>
            </li>

            <li class="">
                <Link   href="#" aria-expanded="false"
                 className={`has-arrow ${rtoIntelligence.includes(currentUrl) ? 'active' : ''}`}
                 >
                    <div class="nav_icon_small">
                        <i class="fa-solid fa-lightbulb"></i>
                    </div>
                    <div class="nav_title">
                        <span>RTO Intelligence </span>
                    </div>
                </Link>
                <ul  
                className={` ${rtoIntelligence.includes(currentUrl) ? 'mm-show' : ''}`}
                
                style={{height: '5px'}}>
                    <li><Link to={`${baseUrl}rto-pincodes`} className={`${currentUrl==="/rto-pincodes"?'sub_active':''}`}>High RTO Picode List</Link></li>
                    <li><Link to={`${baseUrl}rto-faqs`} className={`${currentUrl==="/rto-faqs"?'sub_active':''}`} >RTO FAQs</Link></li>
                </ul>
            </li>

            <li class="">
                <Link to="/payments" aria-expanded="false" className={`${currentUrl==="/payments"?'active':''}`}>
                    <div class="nav_icon_small">
                        <i class="fa-solid fa-money-bill"></i>
                    </div>
                    <div class="nav_title">
                        <span>Payments</span>
                    </div>
                </Link>
            </li>


            <li className="">
                <Link to="/products" aria-expanded="false" className={`${currentUrl==="/products"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-box"></i>
                    </div>
                    <div className="nav_title">
                        <span>Products</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/push-on-portal" aria-expanded="false" className={`${currentUrl==="/push-on-portal"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-cart-plus"></i>
                    </div>
                    <div className="nav_title">
                        <span>Push On Portal</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/orders" aria-expanded="false" className={`${currentUrl==="/orders"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-bag-shopping"></i>
                    </div>
                    <div className="nav_title">
                        <span>Orders</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/orders-status" aria-expanded="false" className={`${currentUrl==="/orders-status"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-chart-column"></i>
                    </div>
                    <div className="nav_title">
                        <span>Order Status</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/upload-bulk-order" aria-expanded="false" className={`${currentUrl==="/upload-bulk-order"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-box-open"></i>
                    </div>
                    <div className="nav_title">
                        <span>Upload Bulk Order</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/ndr" aria-expanded="false" className={`${currentUrl==="/ndr"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-truck"></i>
                    </div>
                    <div className="nav_title">
                        <span>NDR</span>
                    </div>
                </Link>
            </li>

            <li class="mm-active">
                <Link class="has-arrow" to="#" aria-expanded="true"
                className={`has-arrow ${reportUrls.includes(currentUrl) ? 'active' : ''}`} >
                    <div class="nav_icon_small">
                        <i class="fa-solid fa-user"></i>
                    </div>
                    <div class="nav_title">
                        <span>Reports </span>
                    </div>
                </Link>
                <ul className={`mm-collapse ${reportUrls.includes(currentUrl) ? 'mm-show' : ''}`}   >
                    <li><Link to="/custom-reports" className={`${currentUrl==="/custom-reports"?'sub_active':''}`}>Custom Reports</Link></li>
                    <li><Link to="/shipment-summary" className={`${currentUrl==="/shipment-summary"?'sub_active':''}`}>Shipment Summary</Link></li>
                    <li><Link to="/top-ndr-reasons" className={`${currentUrl==="/top-ndr-reasons"?'sub_active':''}`}>Top NDR Reasons</Link></li>
                    <li><Link to="/daily-summary" className={`${currentUrl==="/daily-summary"?'sub_active':''}`}>Daily Summary</Link></li>
                    <li><Link to="/state-wise-summary" className={`${currentUrl==="/state-wise-summary"?'sub_active':''}`}>State Wise Summary</Link></li>
                    <li><Link to="/product-wise-summary" className={`${currentUrl==="/product-wise-summary"?'sub_active':''}`}>Product Wise Summary</Link></li>
                </ul>
            </li>




            <li className="">
                <Link to="#" aria-expanded="false" className={`has-arrow ${accountUrls.includes(currentUrl) ? 'active' : ''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-user"></i>
                    </div>
                    <div className="nav_title">
                        <span>Account </span>
                    </div>
                </Link>
                <ul className={` ${accountUrls.includes(currentUrl) ? 'mm-show' : ''}`}>
                    <li><Link to="/payment-invoice" className={`${currentUrl==="/payment-invoice"?'sub_active':''}`}>Payment Invoice</Link></li>
                    <li><Link to="/purchase-bill" className={`${currentUrl==="/purchase-bill"?'sub_active':''}`}>Sales Invoice</Link></li>
                    <li><Link to="/purchase-bill" className={`${currentUrl==="/payment-invoices"?'sub_active':''}`}>Purchase Bill</Link></li>
                </ul>
            </li>
            <li className="">
                <Link to="/setting" aria-expanded="false" className={` ${settingUrls.includes(currentUrl) ? 'active' : ''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-gear"></i>
                    </div>
                    <div className="nav_title">
                        <span>Settings</span>
                    </div>
                </Link>
            </li>

            <li class="">
                <Link to="/routeway-updates" aria-expanded="false"
                className={`${currentUrl==="/routeway-updates"?'active':''}`}>
                    <div class="nav_icon_small">
                        <i class="fa-solid fa-gear"></i>
                    </div>
                    <div class="nav_title">
                        <span>Routeway Updates</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/support" aria-expanded="false" className={`${currentUrl==="/support"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-user-gear"></i>
                    </div>
                    <div className="nav_title">
                        <span>Support</span>
                    </div>
                </Link>
            </li>

            <li class="">
                <Link to="/training" aria-expanded="false"
                className={`${currentUrl==="/training"?'active':''}`}>
                    <div class="nav_icon_small">
                        <i class="fa-solid fa-user-gear"></i>
                    </div>
                    <div class="nav_title">
                        <span>Training</span>
                    </div>
                </Link>
            </li>

        </ul>
    </nav>
    </>
  )
}
