import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import $ from 'jquery'
 
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import { AppContext } from "../globleDataComponent/contaxtData";

const Payments = () => {

    const [filterOpen, setFilterOpen] = useState(false);
    const datePickerRef = useRef(null);
    const { globalData, setGlobalData } = useContext(AppContext);

    useEffect(() => {
        // Initialize Air Datepicker
        new AirDatepicker(datePickerRef.current, {
          range: true,
          multipleDatesSeparator: ' - ',
          dateFormat: 'MM/dd/yyyy', // Change this format as needed (e.g., 'dd/MM/yyyy')
          language: {
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            months: [
              'January', 'February', 'March', 'April', 'May', 'June',
              'July', 'August', 'September', 'October', 'November', 'December'
            ],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear',
            dateFormat: 'MM/dd/yyyy',
            timeFormat: 'HH:mm',
            firstDay: 0 // Sunday as the first day of the week
          },
          position: 'bottom left', // Adjusts the calendar position
          autoClose: true, // Closes the calendar after selecting a date range
          onSelect: ({ formattedDate }) => {
            console.log('Selected date range:', formattedDate);
          }
        });
      }, []);

    useEffect(() => {
        if (window.$ && window.$.fn.DataTable) {
            const table = window.$('#payment_table1').DataTable({
                bLengthChange: false,
                bDestroy: true,
                language: {
                  search: "<i class='ti-search'></i>",
                  searchPlaceholder: 'Quick Search',
                  paginate: {
                    next: "<i class='ti-arrow-right'></i>",
                    previous: "<i class='ti-arrow-left'></i>",
                  },
                },
                columnDefs: [
                  { visible: false },
                ],
                responsive: true,
                searching: true,
              }); 

            // Cleanup on component unmount
            //   return () => {
            //     if (window.$.fn.DataTable.isDataTable('#DataTables_Table_2')) {
            //       table.destroy(true);
            //     }
            //   };
        }
    }, []);

  return (
    <div>
      <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
        <Header />

        <div className="main_content_iner overly_inner ">
          <div className="container-fluid p-0 ">
            
            <div className="row">
              <div className="col-lg-12">
                <div className="white_card card_height_100 mb_30 filterCard">
                  <div className="white_card_header carHeadertwo">
                    <div className="box_header m-0">
                      <div className="main-title">
                        <h3 className="m-0">Filters</h3>
                        <button
                          className="collapsed"
                        //   data-toggle="collapse"
                        //   data-target="#filter"
                        //   aria-expanded="false"
                        //   aria-controls="filter"
                          onClick={()=>setFilterOpen(!filterOpen)}
                        >
                          <i className="fa-solid fa-filter" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`white_card_body collapse ${filterOpen?"show":''}`}
                    id="filter"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="PaymentMethod">Date</label>
                          <input
                            className="form-control datepicker-here  digits"
                            type="text"
                            
                            placeholder="Select date range"
                            ref={datePickerRef}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="PaymentMethod"
                          style={{ display: "block", visibility: "hidden" }}
                        >
                          Date
                        </label>
                        <button type="button" className="btn btn-secondary">
                          Reset
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="white_card card_height_100 mb_20 ">
                      <div className="white_card_header">
                        <div className="box_header m-0">
                          <div className="main-title">
                            <h2 className="f_w_700">Payments</h2>
                            <p>
                              Find all the past payments made to you on daily
                              and weekly basis.
                            </p>
                            <h4 className="highlightedText mt-2">
                              Total payment till date since 08 Feb 2023:
                              <span>₹2,070,175</span>
                            </h4>
                          </div>
                          <div className="header_more_tool">
                            <div className="dropdown">
                              <span
                                className="dropdown-toggle"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                              >
                                <i className="ti-more-alt" />
                              </span>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a className="dropdown-item" href="#">
                                  <i className="ti-trash" />
                                  Delete
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="ti-printer" />
                                  Print
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-download" /> Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="white_card_body QA_section orderList">
                        <div className="QA_table">
                          <div id="" className="dataTables_wrapper no-footer ">
                            <table className="table orderlisttable text-center dataTable lms_table_active p-0 collapsed " id="payment_table1">
                              <thead>
                                <tr role="row">
                                  <th scope="col">Payment Date</th>
                                  <th scope="col">Day</th>
                                  <th scope="col">Payment Cycle</th>
                                  <th scope="col">Transaction ID</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr role="row" className="odd">
                                  <td>24 Sep 2024</td>
                                  <td>Tuesday</td>
                                  <td>Daily</td>
                                  <td>TRANSA20001</td>
                                  <td>₹ 7500</td>
                                  <td className="color_text_6">
                                    <div className="action_btns d-flex justify-content-center">
                                      <a href="javascript:void(0)">
                                        <i className="fa-solid fa-download" />
                                        Download
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr role="row" className="odd">
                                  <td>24 Sep 2024</td>
                                  <td>Tuesday</td>
                                  <td>Daily</td>
                                  <td>TRANSA20001</td>
                                  <td>₹ 7500</td>
                                  <td className="color_text_6">
                                    <div className="action_btns d-flex justify-content-center">
                                      <a href="javascript:void(0)">
                                        <i className="fa-solid fa-download" />
                                        Download
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </div>
  );
};

export default Payments;
