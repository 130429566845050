export const productDeatil = {
  "ProdutDetails": [
    {
      "SKU": "RW630",
      "Product_ID": 747,
      "CATEGORYNAME": "Women Clothes",
      "Product_Name": "New Floral Shirt",
      "CurrentStock": 1000,
      "Our_Cost": 349.00,
      "GST": 18.00,
      "Product_Details": "",
      "PackingWeight": null,
      "docname": "https://app.routeway.in/doc/product/747_202458090712.jpeg",
      "Selected": 0
    }
  ],
  "Varient": [
    {
      "PVID": 1603,
      "CurrentStock": 0,
      "Product_ID": 747,
      "Variant_Name": "M-Printed",
      "price": 349.00,
      "sprice": 0.00,
      "margin": 0.00
    },
    {
      "PVID": 1602,
      "CurrentStock": 0,
      "Product_ID": 747,
      "Variant_Name": "S-Printed",
      "price": 349.00,
      "sprice": 0.00,
      "margin": 0.00
    }
  ],
  "Image": [
    {
      "PIID": 1793,
      "FileName": "https://app.routeway.in/doc/product/747_202458090703.jpeg"
    },
    {
      "PIID": 1794,
      "FileName": "https://app.routeway.in/doc/product/747_202458090712.jpeg"
    },
    {
      "PIID": 1795,
      "FileName": "https://app.routeway.in/doc/product/747_202458090723.jpeg"
    },
    {
      "PIID": 1796,
      "FileName": "https://app.routeway.in/doc/product/747_202458090734.jpeg"
    }
  ],
  "Portal": [
    {
      "MDAPIID": 3,
      "Portal": "Patel solutions(https://ad473f.myshopify.com)",
      "Pushed": false
    },
    {
      "MDAPIID": 66,
      "Portal": "myMart(https://01mymart.ind.in)",
      "Pushed": false
    },
    {
      "MDAPIID": 10652,
      "Portal": "routewayapptest(routewayapptest.myshopify.com)",
      "Pushed": false
    },
    {
      "MDAPIID": 20698,
      "Portal": "ssdfsdf(dfsdfs)",
      "Pushed": false
    }
  ]
}