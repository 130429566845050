import React, { useEffect } from 'react'

const RTOAction = ({tableTab}) => {

    useEffect(() => {
        let table;
    
        if (window.$ && window.$.fn.DataTable) {
          // Initialize DataTable
          table = window.$('#DataTables_Table_4').DataTable();
        }
    
        // Cleanup on component unmount
        return () => {
          if (table && window.$.fn.DataTable.isDataTable('#DataTables_Table_4')) {
            // table.destroy(true);
          }
        };
      }, []); 

  return (
    <div style={{display:`${tableTab==="RTOAction"?'':'none'}`}}>
      
      <table className="table orderlisttable dataTable lms_table_active p-0 collapsed" id='DataTables_Table_4'>
                                                            <thead>
                                                                <tr role="row">
                                                                    <th>S.no</th>
                                                                    <th>
                                                                        Order no
                                                                    </th>
                                                                    <th >
                                                                    Order Date</th>
                                                                    <th>SKU</th>
                                                                    {/* <th><input type="checkbox" className="formcheck" id="exampleCheck1" /></th> */}
                                                                    <th> Qty</th>
                                                                    <th style={{minWidth:'250px'}}> Product</th>
                                                                    <th>
                                                                        M/SP</th>
                                                                    <th >
                                                                        Customer</th>
                                                                    <th >
                                                                        Mobile</th>
                                                                    <th>
                                                                        AWB</th>
                                                                    <th style={{display:`${tableTab==="ActionRequired"?'none':''}`}}>
                                                                        Status</th>
                                                                    <th style={{display:`${tableTab==="ActionRequired"?'':'none'}`}}>
                                                                        Action</th>
                                                                </tr>
                                                            </thead>
                                                             
                                                            <tbody>
        
                                                                {/* <!-- <tr role="row" className="odd">
                                                                    <td>1</td>
                                                                    <td><input type="checkbox" className="formcheck"
                                                                            id="exampleCheck1"></td>
                                                                    <td tabindex="0" className="sorting_1">
                                                                        RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2
                                                                    </td>
                                                                    <td className="color_text_6">#1034 14 Apr 2024</td>
                                                                    <td className="color_text_6">21/Apr/2024</td>
                                                                    <td className="color_text_6">1</td>
                                                                    <td className="color_text_6"> 699</td>
                                                                    <td className="color_text_6">475</td>
                                                                    <td className="color_text_6">Rajeshbhai -</td>
                                                                    <td className="color_text_6">9998163653</td>
                                                                    <td><a href="#" className="status_btn">Active</a></td>
                                                                    <td className="color_text_6">
                                                                        <div className="action_btns d-flex">
                                                                            <a href="#" className="action_btn mr_10"> <i
                                                                                    className="far fa-edit"></i> </a>
                                                                            <a href="#" className="action_btn"> <i
                                                                                    className="fas fa-trash"></i> </a>
                                                                        </div>
                                                                    </td>
                                                                </tr> --> */}
        
                                                            </tbody>
                                                        </table>
             </div>
  )
}

export default RTOAction
