export const ndrRto = {
  "NDROrder": [
    {
      "ODID": 44197,
      "OrderAssignToSupplierDate": "2023-11-25T16:52:26.763",
      "Order_No": "1063",
      "Order_Date": "2023-11-25 11:00:30.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Adivasi Neelgiri Herbal Hair Oil 125ML (Pack Of 2)",
      "SKU": "RW369",
      "Qty": 1.00,
      "DS_Total_Cost": 266.00,
      "Sale_Total_Cost": 499.00,
      "Shi_Add_Name": "Babita -, Mangti chowk khajauli, Mangti chowk, Madhubani, Bihar, 847228, India, ",
      "Shi_Add_Phone": "6205722743",
      "Shi_Email": "",
      "Cloud_Id": "1063-8-36-44197",
      "Awb_Code": "24875611467421",
      "Courrer_Company": "Delhivery O",
      "ProfileImage": "https://app.routeway.in/doc/product/433_202304140749.jpeg",
      "shipment_status": "Shipment Lost (Shipment LOST)"
    },
    {
      "ODID": 37951,
      "OrderAssignToSupplierDate": "2023-08-21T07:41:50.523",
      "Order_No": "1009",
      "Order_Date": "2023-08-20 09:20:37.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Skin Whitening & Brightening Cream ( Buy 1 Get 1 Free )",
      "SKU": "RW375",
      "Qty": 1.00,
      "DS_Total_Cost": 553.00,
      "Sale_Total_Cost": 799.00,
      "Shi_Add_Name": "Royal bhabhor 2393, Samrka gam, Samrka gam, Samrka gam, Gujarat, 385565, India, ",
      "Shi_Add_Phone": "7623936906",
      "Shi_Email": "",
      "Cloud_Id": "1009-8-36-37951",
      "Awb_Code": "FSPC0001053351",
      "Courrer_Company": "Ekart",
      "ProfileImage": "https://app.routeway.in/doc/product/440_202346110821.jpeg",
      "shipment_status": "RTO (Delivered to )"
    },
    {
      "ODID": 67565,
      "OrderAssignToSupplierDate": "2024-05-21T13:47:20.987",
      "Order_No": "1129",
      "Order_Date": "2024-04-07 22:25:32.7782996 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "SELF DEFENCE STICK",
      "SKU": "RW338",
      "Qty": 1.00,
      "DS_Total_Cost": 519.00,
      "Sale_Total_Cost": 899.00,
      "Shi_Add_Name": "Faiz Ahmad, Lal bagh purnea city, Kt classes, Purnea, BIHAR, 854302, india, ",
      "Shi_Add_Phone": "8340519815",
      "Shi_Email": "",
      "Cloud_Id": "1129-8-168-67565",
      "Awb_Code": "78001099503",
      "Courrer_Company": "Blue Dart - Express",
      "ProfileImage": "https://app.routeway.in/doc/product/401_202300080656.jpeg",
      "shipment_status": "RTO Delivered (SHIPMENT DELIVERED)"
    },
    {
      "ODID": 55425,
      "OrderAssignToSupplierDate": "2023-12-05T18:26:26.04",
      "Order_No": "2033",
      "Order_Date": "2023-12-05 00:18:26.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "ORGANIC GREY REVERSE SHAMPOO BAR😍😍 (PACK OF 2)",
      "SKU": "RW376",
      "Qty": 1.00,
      "DS_Total_Cost": 384.00,
      "Sale_Total_Cost": 599.00,
      "Shi_Add_Name": "Ankit kumar rawat, rampur baghelan, , rampur baghelan, Madhya Pradesh, 485115, India, ",
      "Shi_Add_Phone": "8629978184",
      "Shi_Email": "",
      "Cloud_Id": "2033-8-36-55425",
      "Awb_Code": "411002385828",
      "Courrer_Company": "Ecom Express",
      "ProfileImage": "https://app.routeway.in/doc/product/442_202331230802.jpeg",
      "shipment_status": "RTO Delivered (  - Delivered)"
    },
    {
      "ODID": 55432,
      "OrderAssignToSupplierDate": "2023-12-05T18:30:21.833",
      "Order_No": "1985",
      "Order_Date": "2023-12-04 18:14:14.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "BLACK Hair Color Shampoo for Men & Women😍😍",
      "SKU": "RW400",
      "Qty": 1.00,
      "DS_Total_Cost": 372.00,
      "Sale_Total_Cost": 699.00,
      "Shi_Add_Name": "Nathmil Arengh, Darongadu p o purakasia, , Village, Meghalaya, 794104, India, ",
      "Shi_Add_Phone": "8837484935",
      "Shi_Email": "",
      "Cloud_Id": "1985-8-241-55432",
      "Awb_Code": "411002385800",
      "Courrer_Company": "Ecom Express",
      "ProfileImage": "https://app.routeway.in/doc/product/489_202327071100.jpeg",
      "shipment_status": "RTO Delivered (  - Delivered)"
    },
    {
      "ODID": 55454,
      "OrderAssignToSupplierDate": "2023-12-05T18:50:49.017",
      "Order_No": "1915",
      "Order_Date": "2023-12-04 09:17:19.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "BLACK Hair Color Shampoo for Men & Women😍😍",
      "SKU": "RW400",
      "Qty": 1.00,
      "DS_Total_Cost": 372.00,
      "Sale_Total_Cost": 699.00,
      "Shi_Add_Name": "Harsing Rongpi, Balipathar, Dilawjan hospital, Bokajan, Assam, 782470, India, ",
      "Shi_Add_Phone": "6000389203",
      "Shi_Email": "",
      "Cloud_Id": "1915-8-241-55454",
      "Awb_Code": "FSPC0002055883",
      "Courrer_Company": "Ekart",
      "ProfileImage": "https://app.routeway.in/doc/product/489_202327071100.jpeg",
      "shipment_status": "RTO Delivered (Delivered to )"
    },
    {
      "ODID": 55406,
      "OrderAssignToSupplierDate": "2023-12-05T18:02:28.36",
      "Order_No": "1983",
      "Order_Date": "2023-12-04 18:04:12.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "ORGANIC GREY REVERSE SHAMPOO BAR😍😍 (PACK OF 2)",
      "SKU": "RW376",
      "Qty": 1.00,
      "DS_Total_Cost": 384.00,
      "Sale_Total_Cost": 599.00,
      "Shi_Add_Name": "Ranjan nam das, Mohoripara, Magurmari to khoirabari road, Goreswar, Assam, 784522, India, ",
      "Shi_Add_Phone": "9365265968",
      "Shi_Email": "",
      "Cloud_Id": "1983-8-36-55406",
      "Awb_Code": "FSPC0002051151",
      "Courrer_Company": "Ekart",
      "ProfileImage": "https://app.routeway.in/doc/product/442_202331230802.jpeg",
      "shipment_status": "RTO Delivered (Delivered to )"
    },
    {
      "ODID": 55416,
      "OrderAssignToSupplierDate": "2023-12-05T18:24:02.79",
      "Order_No": "2051",
      "Order_Date": "2023-12-05 12:01:42.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "ORGANIC GREY REVERSE SHAMPOO BAR😍😍 (PACK OF 2)",
      "SKU": "RW376",
      "Qty": 1.00,
      "DS_Total_Cost": 384.00,
      "Sale_Total_Cost": 599.00,
      "Shi_Add_Name": "Buban Chatterjee, Assam. Dhubri. Bilasipara, Just Road. Bilasipara, Dhubri, Assam, 783348, India, ",
      "Shi_Add_Phone": "6003524092",
      "Shi_Email": "",
      "Cloud_Id": "2051-8-36-55416",
      "Awb_Code": "FSPC0002051161",
      "Courrer_Company": "Ekart",
      "ProfileImage": "https://app.routeway.in/doc/product/442_202331230802.jpeg",
      "shipment_status": "RTO Delivered (Delivered to )"
    },
    {
      "ODID": 55415,
      "OrderAssignToSupplierDate": "2023-12-05T18:23:41.69",
      "Order_No": "2053",
      "Order_Date": "2023-12-05 12:09:39.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "ORGANIC GREY REVERSE SHAMPOO BAR😍😍 (PACK OF 2)",
      "SKU": "RW376",
      "Qty": 1.00,
      "DS_Total_Cost": 384.00,
      "Sale_Total_Cost": 599.00,
      "Shi_Add_Name": "Shubhash Yadav, Raiyam, Baraha chauk, Madhubani, Bihar, 847404, India, ",
      "Shi_Add_Phone": "7371877709",
      "Shi_Email": "",
      "Cloud_Id": "2053-8-36-55415",
      "Awb_Code": "FSPC0002051160",
      "Courrer_Company": "Ekart",
      "ProfileImage": "https://app.routeway.in/doc/product/442_202331230802.jpeg",
      "shipment_status": "RTO Delivered (Delivered to )"
    },
    {
      "ODID": 55451,
      "OrderAssignToSupplierDate": "2023-12-05T18:49:58.41",
      "Order_No": "1918",
      "Order_Date": "2023-12-04 09:48:11.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "ORGANIC GREY REVERSE SHAMPOO BAR😍😍 (PACK OF 2)",
      "SKU": "RW376",
      "Qty": 1.00,
      "DS_Total_Cost": 384.00,
      "Sale_Total_Cost": 599.00,
      "Shi_Add_Name": "Pinky gour, Suroth sabji Mandi holi ka pura, School, Hindon, Rajasthan, 322252, India, ",
      "Shi_Add_Phone": "9784882134",
      "Shi_Email": "",
      "Cloud_Id": "1918-8-36-55451",
      "Awb_Code": "FSPC0002051190",
      "Courrer_Company": "Ekart",
      "ProfileImage": "https://app.routeway.in/doc/product/442_202331230802.jpeg",
      "shipment_status": "RTO Delivered (Delivered to )"
    },
    {
      "ODID": 55407,
      "OrderAssignToSupplierDate": "2023-12-05T18:02:56.187",
      "Order_No": "1980",
      "Order_Date": "2023-12-04 17:20:44.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "ORGANIC GREY REVERSE SHAMPOO BAR😍😍 (PACK OF 2)",
      "SKU": "RW376",
      "Qty": 1.00,
      "DS_Total_Cost": 384.00,
      "Sale_Total_Cost": 599.00,
      "Shi_Add_Name": "PRASHANT Gejage, Maitri khatta hotel Hungary, Mukam post hungry, Sangola, Maharashtra, 413309, India, ",
      "Shi_Add_Phone": "8010013207",
      "Shi_Email": "",
      "Cloud_Id": "1980-8-36-55407",
      "Awb_Code": "FSPC0002051152",
      "Courrer_Company": "Ekart",
      "ProfileImage": "https://app.routeway.in/doc/product/442_202331230802.jpeg",
      "shipment_status": "RTO Delivered (Delivered to )"
    },
    {
      "ODID": 55456,
      "OrderAssignToSupplierDate": "2023-12-05T18:51:39.077",
      "Order_No": "1912",
      "Order_Date": "2023-12-04 08:59:14.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "ORGANIC GREY REVERSE SHAMPOO BAR😍😍 (PACK OF 2)",
      "SKU": "RW376",
      "Qty": 1.00,
      "DS_Total_Cost": 384.00,
      "Sale_Total_Cost": 599.00,
      "Shi_Add_Name": "SHAILESH SAPRA, Ranagadh.pradhmik school pase, School, Surendranagar, Gujarat, 363427, India, ",
      "Shi_Add_Phone": "7863088449",
      "Shi_Email": "",
      "Cloud_Id": "1912-8-36-55456",
      "Awb_Code": "80366868573",
      "Courrer_Company": "Blue Dart Air",
      "ProfileImage": "https://app.routeway.in/doc/product/442_202331230802.jpeg",
      "shipment_status": "RTO Delivered (SHIPMENT DELIVERED )"
    },
    {
      "ODID": 44219,
      "OrderAssignToSupplierDate": "2023-11-25T17:47:31.153",
      "Order_No": "1053",
      "Order_Date": "2023-11-23 14:35:41.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Adivasi Neelgiri Herbal Hair Oil 125ML (Pack Of 2)",
      "SKU": "RW369",
      "Qty": 1.00,
      "DS_Total_Cost": 266.00,
      "Sale_Total_Cost": 499.00,
      "Shi_Add_Name": "Raja Basumatary, Rain santipur, R k high school, Raja Basumatary, Assam, 787061, India, ",
      "Shi_Add_Phone": "9707365650",
      "Shi_Email": "",
      "Cloud_Id": "1053-8-36-44219",
      "Awb_Code": "77847078934",
      "Courrer_Company": "Blue Dart Express",
      "ProfileImage": "https://app.routeway.in/doc/product/433_202304140749.jpeg",
      "shipment_status": "RTO Delivered (SHIPMENT DELIVERED)"
    },
    {
      "ODID": 55511,
      "OrderAssignToSupplierDate": "2023-12-06T09:37:42.827",
      "Order_No": "1002",
      "Order_Date": "2023-12-04 09:31:14.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "ORGANIC GREY REVERSE SHAMPOO BAR( PACK OF 2)",
      "SKU": "RW376",
      "Qty": 1.00,
      "DS_Total_Cost": 384.00,
      "Sale_Total_Cost": 599.00,
      "Shi_Add_Name": "M d salim, Raj pur khurd har Gobind enklov, Gopi dhaba ke pass, Raj pur khurd, Delhi, 110068, India, ",
      "Shi_Add_Phone": "9711923285",
      "Shi_Email": "",
      "Cloud_Id": "1002-8-36-55511",
      "Awb_Code": "FSPC0002056558",
      "Courrer_Company": "Ekart",
      "ProfileImage": "https://app.routeway.in/doc/product/442_202331230802.jpeg",
      "shipment_status": "RTO Delivered (Delivered to )"
    },
    {
      "ODID": 55388,
      "OrderAssignToSupplierDate": "2023-12-05T17:54:45.49",
      "Order_No": "2019",
      "Order_Date": "2023-12-04 22:10:34.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "ORGANIC GREY REVERSE SHAMPOO BAR😍😍 (PACK OF 2)",
      "SKU": "RW376",
      "Qty": 1.00,
      "DS_Total_Cost": 384.00,
      "Sale_Total_Cost": 599.00,
      "Shi_Add_Name": "Kafeel -, House no 1012 nihal vihar Nangloi west Delhi, Nearby deler mehendi form house, West Delhi, Delhi, 110041, India, ",
      "Shi_Add_Phone": "7065785701",
      "Shi_Email": "",
      "Cloud_Id": "2019-8-36-55388",
      "Awb_Code": "FSPC0002051369",
      "Courrer_Company": "Ekart",
      "ProfileImage": "https://app.routeway.in/doc/product/442_202331230802.jpeg",
      "shipment_status": "RTO Delivered (Delivered to )"
    },
    {
      "ODID": 55447,
      "OrderAssignToSupplierDate": "2023-12-05T18:47:32.693",
      "Order_No": "1927",
      "Order_Date": "2023-12-04 11:10:50.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "ORGANIC GREY REVERSE SHAMPOO BAR😍😍 (PACK OF 2)",
      "SKU": "RW376",
      "Qty": 1.00,
      "DS_Total_Cost": 384.00,
      "Sale_Total_Cost": 599.00,
      "Shi_Add_Name": "Ujjawal -, Surajpur greater Noida Gautam Buddha Nagar, Heera colony near Gyan bharti public school, Greater Noida, Uttar Pradesh, 201306, India, ",
      "Shi_Add_Phone": "7464807927",
      "Shi_Email": "",
      "Cloud_Id": "1927-8-36-55447",
      "Awb_Code": "FSPC0002051185",
      "Courrer_Company": "Ekart",
      "ProfileImage": "https://app.routeway.in/doc/product/442_202331230802.jpeg",
      "shipment_status": "RTO Delivered (Delivered to )"
    },
    {
      "ODID": 44213,
      "OrderAssignToSupplierDate": "2023-11-25T17:45:08.65",
      "Order_No": "1062",
      "Order_Date": "2023-11-25 10:17:05.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Adivasi Neelgiri Herbal Hair Oil 125ML (Pack Of 2)",
      "SKU": "RW369",
      "Qty": 1.00,
      "DS_Total_Cost": 266.00,
      "Sale_Total_Cost": 499.00,
      "Shi_Add_Name": "Rakesh Kumar, sector 38,C Chandigarh woolen market House no. 2523/1, Sector 40, Chandigarh, Punjab, 160036, India, ",
      "Shi_Add_Phone": "6280290620",
      "Shi_Email": "",
      "Cloud_Id": "1062-8-36-44213",
      "Awb_Code": "24875611467480",
      "Courrer_Company": "Delhivery O",
      "ProfileImage": "https://app.routeway.in/doc/product/433_202304140749.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 44209,
      "OrderAssignToSupplierDate": "2023-11-25T17:43:40.83",
      "Order_No": "1068",
      "Order_Date": "2023-11-25 12:26:17.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Adivasi Neelgiri Herbal Hair Oil 125ML (Pack Of 2)",
      "SKU": "RW369",
      "Qty": 1.00,
      "DS_Total_Cost": 266.00,
      "Sale_Total_Cost": 499.00,
      "Shi_Add_Name": "Amitabh Kumar, Ramjee honda tajpur road samastipur near L I C office, Tajpur road, Samastipur, Bihar, 848101, India, ",
      "Shi_Add_Phone": "9576108353",
      "Shi_Email": "",
      "Cloud_Id": "1068-8-36-44209",
      "Awb_Code": "24875611467443",
      "Courrer_Company": "Delhivery O",
      "ProfileImage": "https://app.routeway.in/doc/product/433_202304140749.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 44227,
      "OrderAssignToSupplierDate": "2023-11-25T17:49:38.263",
      "Order_No": "1042",
      "Order_Date": "2023-11-22 12:40:47.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Adivasi Neelgiri Herbal Hair Oil 125ML (Pack Of 2)",
      "SKU": "RW369",
      "Qty": 1.00,
      "DS_Total_Cost": 266.00,
      "Sale_Total_Cost": 499.00,
      "Shi_Add_Name": "Raju -, रेहुआ, Baundi, Bahraich, Uttar Pradesh, 271902, India, ",
      "Shi_Add_Phone": "8052657357",
      "Shi_Email": "",
      "Cloud_Id": "1042-8-36-44227",
      "Awb_Code": "24875611467605",
      "Courrer_Company": "Delhivery O",
      "ProfileImage": "https://app.routeway.in/doc/product/433_202304140749.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 44222,
      "OrderAssignToSupplierDate": "2023-11-25T17:48:29.803",
      "Order_No": "1051",
      "Order_Date": "2023-11-23 14:22:46.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Adivasi Neelgiri Herbal Hair Oil 125ML (Pack Of 2)",
      "SKU": "RW369",
      "Qty": 1.00,
      "DS_Total_Cost": 266.00,
      "Sale_Total_Cost": 499.00,
      "Shi_Add_Name": "Jugarj yadav, Satal kheri Kota Rajasthan, Yadav mohalla, Ramganjmandi kota, Rajasthan, 326519, India, ",
      "Shi_Add_Phone": "9829805069",
      "Shi_Email": "",
      "Cloud_Id": "1051-8-36-44222",
      "Awb_Code": "24875611467550",
      "Courrer_Company": "Delhivery O",
      "ProfileImage": "https://app.routeway.in/doc/product/433_202304140749.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 44212,
      "OrderAssignToSupplierDate": "2023-11-25T17:44:06.743",
      "Order_No": "1065",
      "Order_Date": "2023-11-25 11:56:17.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Adivasi Neelgiri Herbal Hair Oil 125ML (Pack Of 2)",
      "SKU": "RW369",
      "Qty": 1.00,
      "DS_Total_Cost": 266.00,
      "Sale_Total_Cost": 499.00,
      "Shi_Add_Name": "निकिता काबळे, आंबेडकर भवन, स्नेहा ब्युटी पार्लर, पुणे, Maharashtra, 411026, India, ",
      "Shi_Add_Phone": "9322482926",
      "Shi_Email": "",
      "Cloud_Id": "1065-8-36-44212",
      "Awb_Code": "24875611467476",
      "Courrer_Company": "Delhivery O",
      "ProfileImage": "https://app.routeway.in/doc/product/433_202304140749.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 44216,
      "OrderAssignToSupplierDate": "2023-11-25T17:46:27.083",
      "Order_No": "1059",
      "Order_Date": "2023-11-25 05:10:31.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Adivasi Neelgiri Herbal Hair Oil 125ML (Pack Of 2)",
      "SKU": "RW369",
      "Qty": 1.00,
      "DS_Total_Cost": 266.00,
      "Sale_Total_Cost": 499.00,
      "Shi_Add_Name": "Khushi dubey, Jagua sondha, Primary, Jagua sondha handia, Uttar Pradesh, 221503, India, ",
      "Shi_Add_Phone": "7798461263",
      "Shi_Email": "",
      "Cloud_Id": "1059-8-36-44216",
      "Awb_Code": "24875611467513",
      "Courrer_Company": "Delhivery O",
      "ProfileImage": "https://app.routeway.in/doc/product/433_202304140749.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 44215,
      "OrderAssignToSupplierDate": "2023-11-25T17:46:07.057",
      "Order_No": "1060",
      "Order_Date": "2023-11-25 06:52:00.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Adivasi Neelgiri Herbal Hair Oil 125ML (Pack Of 2)",
      "SKU": "RW369",
      "Qty": 1.00,
      "DS_Total_Cost": 266.00,
      "Sale_Total_Cost": 499.00,
      "Shi_Add_Name": "Devidas rajput, Judawan, शासकीय school.com judawan, Titamghr, Madhya Pradesh, 472005, India, ",
      "Shi_Add_Phone": "9329757906",
      "Shi_Email": "",
      "Cloud_Id": "1060-8-36-44215",
      "Awb_Code": "24875611467502",
      "Courrer_Company": "Delhivery O",
      "ProfileImage": "https://app.routeway.in/doc/product/433_202304140749.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 44214,
      "OrderAssignToSupplierDate": "2023-11-25T17:45:41.95",
      "Order_No": "1061",
      "Order_Date": "2023-11-25 09:26:03.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Adivasi Neelgiri Herbal Hair Oil 125ML (Pack Of 2)",
      "SKU": "RW369",
      "Qty": 1.00,
      "DS_Total_Cost": 266.00,
      "Sale_Total_Cost": 499.00,
      "Shi_Add_Name": "Anil  Kumar -, Bajada, School, Narkatiya jang, Bihar, 845451, India, ",
      "Shi_Add_Phone": "7870936788",
      "Shi_Email": "",
      "Cloud_Id": "1061-8-36-44214",
      "Awb_Code": "24875611467491",
      "Courrer_Company": "Delhivery O",
      "ProfileImage": "https://app.routeway.in/doc/product/433_202304140749.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 44221,
      "OrderAssignToSupplierDate": "2023-11-25T17:48:05.073",
      "Order_No": "1052",
      "Order_Date": "2023-11-23 14:25:46.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Adivasi Neelgiri Herbal Hair Oil 125ML (Pack Of 2)",
      "SKU": "RW369",
      "Qty": 1.00,
      "DS_Total_Cost": 266.00,
      "Sale_Total_Cost": 499.00,
      "Shi_Add_Name": "Lokesh varma, Payladpur lal kuva, Panch mukhi mander, South Delhi, Delhi, 110044, India, ",
      "Shi_Add_Phone": "8960441932",
      "Shi_Email": "",
      "Cloud_Id": "1052-8-36-44221",
      "Awb_Code": "24875611467546",
      "Courrer_Company": "Delhivery O",
      "ProfileImage": "https://app.routeway.in/doc/product/433_202304140749.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 37965,
      "OrderAssignToSupplierDate": "2023-08-21T07:41:50.567",
      "Order_No": "1030",
      "Order_Date": "2023-08-21 07:01:41.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Skin Whitening & Brightening Cream ( Buy 1 Get 1 Free )",
      "SKU": "RW375",
      "Qty": 1.00,
      "DS_Total_Cost": 553.00,
      "Sale_Total_Cost": 799.00,
      "Shi_Add_Name": "Roshan Kapoor, SB Bar Resto Sector 19E Vashi, Plot no.12, Navi Mumbai, Maharashtra, 400705, India, ",
      "Shi_Add_Phone": "9324006653",
      "Shi_Email": "",
      "Cloud_Id": "1030-8-36-37965",
      "Awb_Code": "14344985352000",
      "Courrer_Company": "Xpressbees Surface",
      "ProfileImage": "https://app.routeway.in/doc/product/440_202346110821.jpeg",
      "shipment_status": "RTO Delivered (rto delivered)"
    },
    {
      "ODID": 37959,
      "OrderAssignToSupplierDate": "2023-08-21T07:41:50.547",
      "Order_No": "1024",
      "Order_Date": "2023-08-20 22:41:08.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Skin Whitening & Brightening Cream ( Buy 1 Get 1 Free )",
      "SKU": "RW375",
      "Qty": 1.00,
      "DS_Total_Cost": 553.00,
      "Sale_Total_Cost": 799.00,
      "Shi_Add_Name": "Sipai mahinur banu rahim khan Mahinur, Totana, Totana, Gujarat, 385560, India, ",
      "Shi_Add_Phone": "7490924882",
      "Shi_Email": "",
      "Cloud_Id": "1024-8-36-37959",
      "Awb_Code": "20635512805795",
      "Courrer_Company": "Delhivery",
      "ProfileImage": "https://app.routeway.in/doc/product/440_202346110821.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 37949,
      "OrderAssignToSupplierDate": "2023-08-21T07:41:50.52",
      "Order_No": "1012",
      "Order_Date": "2023-08-20 09:55:08.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Skin Whitening & Brightening Cream ( Buy 1 Get 1 Free )",
      "SKU": "RW375",
      "Qty": 1.00,
      "DS_Total_Cost": 553.00,
      "Sale_Total_Cost": 799.00,
      "Shi_Add_Name": "Anjana Kunju, Kozhikod nadapuram kallachi vilangad, City, Vilangad, Kerala, 673513, India, ",
      "Shi_Add_Phone": "7907098638",
      "Shi_Email": "",
      "Cloud_Id": "1012-8-36-37949",
      "Awb_Code": "20635512805703",
      "Courrer_Company": "Delhivery",
      "ProfileImage": "https://app.routeway.in/doc/product/440_202346110821.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 37963,
      "OrderAssignToSupplierDate": "2023-08-21T07:41:50.56",
      "Order_No": "1028",
      "Order_Date": "2023-08-21 00:02:32.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Skin Whitening & Brightening Cream ( Buy 1 Get 1 Free )",
      "SKU": "RW375",
      "Qty": 1.00,
      "DS_Total_Cost": 553.00,
      "Sale_Total_Cost": 799.00,
      "Shi_Add_Name": "Janani.k Janu, Cottonpet main road bangalore 53, Bengaluru, Karnataka, 560053, India, ",
      "Shi_Add_Phone": "7204956759",
      "Shi_Email": "",
      "Cloud_Id": "1028-8-36-37963",
      "Awb_Code": "14344985351980",
      "Courrer_Company": "Xpressbees Surface",
      "ProfileImage": "https://app.routeway.in/doc/product/440_202346110821.jpeg",
      "shipment_status": "RTO Delivered (rto delivered)"
    },
    {
      "ODID": 38034,
      "OrderAssignToSupplierDate": "2023-08-22T09:03:43.643",
      "Order_No": "1039",
      "Order_Date": "2023-08-21 19:27:15.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Skin Whitening & Brightening Cream ( Buy 1 Get 1 Free )",
      "SKU": "RW375",
      "Qty": 1.00,
      "DS_Total_Cost": 553.00,
      "Sale_Total_Cost": 799.00,
      "Shi_Add_Name": "Merina Khatun, MahammadPur Sompara, Saktipur, West Bengal, 742163, India, ",
      "Shi_Add_Phone": "8538030832",
      "Shi_Email": "",
      "Cloud_Id": "1039-8-36-38034",
      "Awb_Code": "20635512833806",
      "Courrer_Company": "Delhivery",
      "ProfileImage": "https://app.routeway.in/doc/product/440_202346110821.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 37948,
      "OrderAssignToSupplierDate": "2023-08-21T07:41:50.517",
      "Order_No": "1020",
      "Order_Date": "2023-08-20 20:02:33.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Skin Whitening & Brightening Cream ( Buy 1 Get 1 Free )",
      "SKU": "RW375",
      "Qty": 2.00,
      "DS_Total_Cost": 1106.00,
      "Sale_Total_Cost": 1598.00,
      "Shi_Add_Name": "S Thrisha, Pungnur rood Edigpalli, Edigpalli, Andhra Pradesh, 515311, India, ",
      "Shi_Add_Phone": "7013092232",
      "Shi_Email": "",
      "Cloud_Id": "1020-8-36-37948",
      "Awb_Code": "20635512805692",
      "Courrer_Company": "Delhivery",
      "ProfileImage": "https://app.routeway.in/doc/product/440_202346110821.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 38018,
      "OrderAssignToSupplierDate": "2023-08-21T16:51:59.917",
      "Order_No": "1034",
      "Order_Date": "2023-08-21 12:14:16.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Skin Whitening & Brightening Cream ( Buy 1 Get 1 Free )",
      "SKU": "RW375",
      "Qty": 2.00,
      "DS_Total_Cost": 1106.00,
      "Sale_Total_Cost": 1598.00,
      "Shi_Add_Name": "Anshul Verma, Bhuja purva, Bnapur, Uttar Pradesh, 241126, India, ",
      "Shi_Add_Phone": "9936804248",
      "Shi_Email": "",
      "Cloud_Id": "1034-8-36-38018",
      "Awb_Code": "20635512833456",
      "Courrer_Company": "Delhivery",
      "ProfileImage": "https://app.routeway.in/doc/product/440_202346110821.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    },
    {
      "ODID": 37966,
      "OrderAssignToSupplierDate": "2023-08-21T07:42:35.633",
      "Order_No": "1003",
      "Order_Date": "2023-08-20 07:15:05.0000000 +05:30",
      "PaymentMode": "Cash on Delivery (COD)",
      "ProductName": "Skin Whitening & Brightening Cream ( Buy 1 Get 1 Free )",
      "SKU": "RW375",
      "Qty": 1.00,
      "DS_Total_Cost": 553.00,
      "Sale_Total_Cost": 799.00,
      "Shi_Add_Name": "Rajkishor chaudhary, Chilma bazar  basti Basantpur, Basti, Uttar Pradesh, 272301, India, ",
      "Shi_Add_Phone": "6392844481",
      "Shi_Email": "",
      "Cloud_Id": "1003-8-36-37966",
      "Awb_Code": "20635512805854",
      "Courrer_Company": "Delhivery",
      "ProfileImage": "https://app.routeway.in/doc/product/440_202346110821.jpeg",
      "shipment_status": "RTO Delivered (RETURN Accepted)"
    }
  ]
}