import React, { useContext, useEffect, useRef, useState } from 'react'
import Header from '../../components/header'
import OrderStatusFilter from '../../components/orderStatusFilter'
import $ from 'jquery'; 
import { orderStatus } from '../../jsonData/order_status';
import { Link } from 'react-router-dom';
import { AppContext } from '../../globleDataComponent/contaxtData';

const OrderStatus = () => {
    const { globalData, setGlobalData } = useContext(AppContext);

    const tableRef = useRef(null);
    const [filterData, setFilterData] = useState({
        filter_by : '',
        date : '',
        status : '', 
    })

    const allOrder = orderStatus.SearchResult;
    const [order, setOrder] = useState(allOrder);

    function setDataFun(e){
        if (e.target) {
            const { name, value } = e.target; 
            setFilterData({ ...filterData, [name]: value });
          } else {
            // For the datepicker's onSelect event (when no event object is passed)
            setFilterData({ ...filterData, date: e });
          } 
        console.log( filterData);
    }

    // reset filter data
    function resetFilter(){
        setFilterData({
            filter_by : '',
            date : '',
            status : '', 
        })
    }
    // filter data
    function filterProductData(e){
        e.preventDefault();
        console.log(filterData);
        
    }
 

    useEffect(() => {
        if (window.$ && window.$.fn.DataTable) {
            const table = window.$('#order_status_table').DataTable(
                {
                    bLengthChange: false,
                    bDestroy: true,
                    language: {
                      search: "<i class='ti-search'></i>",
                      searchPlaceholder: 'Quick Search',
                      paginate: {
                        next: "<i class='ti-arrow-right'></i>",
                        previous: "<i class='ti-arrow-left'></i>",
                      },
                    },
                    columnDefs: [
                      { visible: false },
                    ],
                    responsive: true,
                    searching: true,
                    
                  }
            );
          

            // Cleanup on component unmount
            //   return () => {
            //     if (window.$.fn.DataTable.isDataTable('#DataTables_Table_2')) {
            //       table.destroy(true);
            //     }
            //   };
        }
    }, []);

    

  


  
  return (
    <>
       <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
       <Header />

       <div className="main_content_iner overly_inner">
            <div className="p-0 container-fluid "> 
                <div className="row">
                     <OrderStatusFilter setDataFun ={setDataFun} filterData={filterData} resetFilter={resetFilter} filterProductData={filterProductData}/>

                     <div className="col-md-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="white_card card_height_100 mb_20 ">
                                    <div className="white_card_header">
                                        <div className="m-0 box_header">
                                            <div className="main-title">
                                                <h3 className="m-0">Order Status</h3>
                                            </div>
                                            <div className="header_more_tool">
                                                <div className="dropdown">
                                                    <span className="dropdown-toggle" id="dropdownMenuButton"
                                                        data-toggle="dropdown">
                                                        <i className="ti-more-alt"></i>
                                                    </span>
                                                    <div className="dropdown-menu dropdown-menu-right"
                                                        aria-labelledby="dropdownMenuButton">
                                                        <a className="dropdown-item" href="#"> <i className="ti-trash"></i>
                                                            Delete</a>
                                                        <a className="dropdown-item" href="#"> <i className="ti-printer"></i>
                                                            Print</a>
                                                        <a className="dropdown-item" href="#"> <i
                                                                className="fa fa-download"></i> Download</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div className="white_card_body">
                                        <div className="QA_section">
                                        <div class="orderstatustab">
                                            <ul>
                                                <li><Link to="#"> Pickups</Link></li>
                                                <li><Link href="#"> In-Transit</Link></li>
                                                <li><Link href="#"> Out For Delivery</Link></li>
                                                <li><Link href="#"> Delivered</Link></li>
                                                <li><Link href="#"> RTO</Link></li>
                                                <li><Link href="#"> Cancelled</Link></li>
                                                <li><Link href="#"> All</Link></li>
                                            </ul>
                                        </div>

                                            <div className="QA_table mb_30">
                                                <table className="table p-0 lms_table_active3" id='order_status_table'>
                                                    <thead>
                                                        <tr>
                                                            <th>S.no</th>
                                                            <th>Cloud id</th>
                                                            <th>Order No</th>
                                                            <th>Order Date</th>
                                                            <th style={{minWidth:'200px'}}>Product Name</th>
                                                            <th>SKU</th>
                                                            <th>Qty</th>
                                                            <th>Margin</th>
                                                            <th style={{minWidth:'200px'}}>Customer</th>
                                                            <th>AWB</th>
                                                            <th>Ship_company</th>
                                                            <th>Delays	</th>
                                                            <th>Packing batch</th>
                                                            <th>Current status	</th>
                                                            <th>Current Update </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {order.map((order,index)=>(
                                                            <tr>
                                                            <td>{index+1}</td>
                                                            <th scope="row"> <a href="#" className="question_content">{order.Cloud_Id}</a></th>
                                                            <td>{order.Order_No}</td>
                                                            <td>{order.Order_Date}</td>
                                                            <td>{order.ProductName}</td>
                                                            <td>{order.SKU}</td>
                                                            <td>{order.Qty}</td>
                                                            <td>{order.Margin}</td>
                                                            <td>{order.Customer}</td>
                                                            <td>{order.AWB}</td>
                                                            <td>{order.Ship_Company}</td>
                                                            <td>{order.Delays}</td>
                                                            <td>{order.PackingBatch}</td>
                                                            <td>
                                                                {order.CurrentStatus && ( 
                                                                      <a href="#" className="status_btn">{order.CurrentStatus}</a>
                                                                    ) 
                                                                }
                                                                </td>
                                                            <td>{order.CurrentStatusDate}</td>
                                                        </tr>
                                                        ))}
                                                         
                                                      
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

              </div>
             </div>
        </section>
    </>
  )
}

export default OrderStatus
