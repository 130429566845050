import React, { useContext } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { Link } from 'react-router-dom'
import { baseUrl } from '../helper/helper'
import { AppContext } from '../globleDataComponent/contaxtData'

const RtoPincodes = () => {
  const { globalData, setGlobalData } = useContext(AppContext);

  return (
    <div>
       <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
       <Header />



       <div className="main_content_iner overly_inner ">
  <div className="container-fluid p-0">
    <div className="sourceProductContainer">
      <div className="row align-items-center">
        <div className="col-md-7">
          <div className="white_card mb_20 ">
            <div className="white_card_body QA_section orderList pushportalPadding">
              <div className="titleSec">
                <h4>Your Search Ends Here!</h4>
                <p>
                  When you don’t find your desired product, let us know, we will
                  try our best to source it.
                </p>
              </div>
              <div className="normalText">
                <p>
                  Based on lakhs of historical orders, our algorithms have
                  identified that certain pincodes are prone to very high RTO%.
                  It is best to avoid targeting customers from these areas as it
                  might lead to a waste of customer acquisition costs.
                </p>
                <div className="w-100 mt-3 mb-3">
                  <Link
                    href="#"
                    className="btn btn-primary"
                    style={{ display: "block" }}
                    onClick={(e)=>e.preventDefault()}
                  >
                    <i className="fa-solid fa-download" /> Hight RTO Pincode
                    List
                  </Link>
                  <small
                    className="text-center w-100"
                    style={{ display: "block" }}
                  >
                    (Last updated on 05 Sep 2024)
                  </small>
                </div>
                <p>
                  These pincodes can be used as negative locations in Facebook’s
                  Ad Manager
                </p>
                <ol>
                  <li>Download the list of pincodes from the above link.</li>
                  <li>
                    Collate the pincodes in a comma-separated form
                    <ul>
                      <li>
                        You can do this in Excel itself using “textjoin”
                        function.
                      </li>
                      <li>
                        Or you can use free sites such as delim.co and paste the
                        pincodes from the downloaded file.
                      </li>
                      <li>
                        Your output should look like this -
                        784514,784148,784529,784145.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Open your Facebook Ad Manager and create your ads as you
                    normally would
                    <ul>
                      <li>
                        In the location section, choose "Exclude" and click on
                        "Add locations in bulk". Choose the postal code option
                        and paste the pincodes in a comma-separated form.
                      </li>
                    </ul>
                  </li>
                  <li>
                    That’s it. Facebook will now avoid showing ads to users in
                    these pincodes.
                  </li>
                </ol>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="imgFull mt-3">
                    <img src={`${baseUrl}front/img/pincode-p-img1.png`} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="imgFull ">
            <img src={`${baseUrl}front/img/rto-pincodes.svg`} alt="img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




       <Footer />
       </section>
    </div>
  )
}

export default RtoPincodes
