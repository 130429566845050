import React, { useContext, useEffect } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { baseUrl } from '../helper/helper'
import { supportData } from '../Http/data/frontData'
import ContactDetailsCart from '../components/supportComponents/contactDetailsCart'
import VideoCart from '../components/supportComponents/videoCart'
import { AppContext } from '../globleDataComponent/contaxtData'

const Support = () => {
  const { globalData, setGlobalData } = useContext(AppContext);

  useEffect(()=>{
    console.log(supportData);
  },[])

  return (
    <>
      
      <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
      <Header />
      <div className="main_content_iner overly_inner"> 
            <div className="container-fluid p-0 ">
                 <div className="row">

                   
                 <div className="col-md-12">
                        <div className="user_crm_wrapper">
                            <div className="row">
                            <div className="col-lg-12">
                              <div className="dashdataCard settingCard mb-3">
                                <div className="plan_left d-flex align-items-center">
                                  <div className="thumb">
                                    <img src={`${baseUrl}front/img/icon/timetable.png`} alt="icon" />
                                  </div>
                                  <div>
                                    <h5>{supportData.heading} </h5>
                                  </div>
                                </div>
                                <div className="supportInfoContainer">
                                  <div className="row justify-content-between">
                                    <div className="col-md-5">
                                      <div className="sideinfoContainer">
                                        <ul>
                                          <li>
                                            <img src={`${baseUrl}front/img/icon/phone-call.png`} alt="img" />
                                            <div className="sideInfo">
                                              <p>Call Us</p>
                                              <h5>
                                                <a href={`tel:${supportData.phone1}`}>{supportData.phone1}</a>
                                              </h5>
                                            </div>
                                          </li>
                                          <li>
                                            <img src={`${baseUrl}front/img/icon/support.png`} alt="img" />
                                            <div className="sideInfo">
                                              <p>Need Help?</p>
                                              <h5>
                                                <a href={`tel:${supportData.phone2}`}>{supportData.phone2}</a>
                                              </h5>
                                            </div>
                                          </li>
                                          <li>
                                            <img src={`${baseUrl}front/img/icon/mail.png`} alt="img" />
                                            <div className="sideInfo">
                                              <p>Mail Us</p>
                                              <h5>
                                                <a href={`mailto:${supportData.email}`}>{supportData.email}</a>
                                              </h5>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="supportrowImg">
                                        <img src={`${baseUrl}front/img/table.svg`} alt="img" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                              </div>
                         </div>
                   </div>

                   {/* ======================================================================================== */}
                  {supportData.supportCart.map((item)=>(

                    <ContactDetailsCart key={item.id} data={item} />
                  ))}
                                 
                  {/* ======================================================================================= */}
                   
                 </div>
            </div>
                   {/* ======================================================================================== */}
 
 
        </div>

        <Footer />
        </section>

    </>
  )
}

export default Support
