import React from 'react'
import { baseUrl } from '../../helper/helper'

const ContactDetailsCart = ({data}) => {
  return (
    <>
       <div className="col-lg-4">
                                  <div className="dashdataCard supportInfoOtherCard settingCard mb-3">
                                    <div className="plan_left d-flex align-items-center">
                                      <div className="thumb">
                                        <img src={`${baseUrl}${data.logo}`} alt="icon" />
                                      </div>
                                      <div>
                                        <h5>{data.heading}</h5>
                                      </div>
                                    </div>
                                    <div className="supportInfoContainer">
                                      <div className="sideinfoContainer">
                                        <ul>
                                          <li>
                                            <img src={`${baseUrl}front/img/icon/phone-call.png`} alt="img" />
                                            <div className="sideInfo">
                                              <p>Phone Number</p>
                                              <h5>
                                                <a href={`tel:${data.phone1}`}>{data.phone1}</a>
                                                <a href={`tel:${data.phone2}`}>{data.phone2}</a>
                                              </h5>
                                            </div>
                                          </li>
                                          <li>
                                            <img src={`${baseUrl}front/img/icon/mail.png`} alt="img" />
                                            <div className="sideInfo">
                                              <p>Email ID</p>
                                              <h5>
                                                <a href={`mailto:${data.email}`}>{data.email}</a>
                                              </h5>
                                            </div>
                                          </li>
                                        </ul>
                                        <p>
                                          <span>
                                           {data.content}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
    </>
  )
}

export default ContactDetailsCart
