import React from 'react'
import { Link } from 'react-router-dom'

export default function Products({pushProduct,product}) {
 
  
  return (
    <>
       <div className="col-md-3">
                        <div className="white_card position-relative mb_20 prCard">
                          <div className="card-body">
                            <div className="ribbon1 rib1-primary"><span className="text-white text-center rib1-primary"><i className="fa-solid fa-box-open" /> {product.CurrentStock}</span></div>
                            <img src={product.docname} alt="" className="d-block mx-auto my-4" height={150} />
                            <div className="row my-4 position-relative">
                              <span className="prCode">{product.SKU}</span>
                              <div className="col-md-12"><span className="badge_btn_3  mb-1"><i className="fas fa-star text-warning font-14" /> 4.5</span> 
                                <Link to={`/product-details/${product.Product_ID}`}  className="f_w_400 color_text_3 f_s_14 d-block prname">{product.Product_Name}</Link>
                              </div>
                              <div className="col-auto">
                                <h4 className="text-dark mt-2">₹{product.Our_Cost}
                                </h4>
                              </div>
                            </div>
                            <button className="btn_2 btn-block pushtoBtn" onClick={()=>pushProduct(product)}>Push to Shopify <i className="fa-solid fa-arrow-right pushArrowwith" /></button>
                          </div>
                        </div>
                      </div>
    </>
  )
}
