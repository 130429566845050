export const landingProducts = {
  "Product": [
    {
      "id":1,
      "SKU": "RW128",
      "Product_ID": 189,
      "CATEGORYNAME": "Beauty & Health",
      "Product_Name": "Eyebrow or Face Hair Removal",
      "CurrentStock": 10,
      "Our_Cost": 60.00,
      "docname": "https://app.routeway.in/doc/product/189_202344080417.jpg",
      "Selected": 0
    },
    {
      "id":2,
      "SKU":"RW836",
      "Product_ID": 962,
      "CATEGORYNAME": "Gadgets",
      "Product_Name": "Earphones with Ultra Bass & Dolby Sound 0.33 mm ",
      "CurrentStock": 449,
      "Our_Cost": 70.00,
      "docname": "https://app.routeway.in/doc/product/no_image.jpg",
      "Selected": 0
    },
    {
      "id":3,
      "SKU": "RW840",
      "Product_ID": 966,
      "CATEGORYNAME": "Gadgets",
      "Product_Name": "Fast Charging HIGH Speed Mobile Data Cable",
      "CurrentStock": 494,
      "Our_Cost": 87.00,
      "docname": "https://app.routeway.in/doc/product/no_image.jpg",
      "Selected": 1
    },
    {
      "id":4,
      "SKU": "RW140",
      "Product_ID": 201,
      "CATEGORYNAME": "Beauty & Health ",
      "Product_Name": "Dead Skin Scrubber Remover",
      "CurrentStock": 10,
      "Our_Cost": 90.00,
      "docname": "https://app.routeway.in/doc/product/201_202359080447.jpg",
      "Selected": 1
    },
    {
      "id":21,
      "SKU": "RW494",
      "Product_ID": 596,
      "CATEGORYNAME": "Beauty & Health ",
      "Product_Name": "Shree Ramkripa Nabhi Tailam Oil ",
      "CurrentStock": 2000,
      "Our_Cost": 100.00,
      "docname": "https://app.routeway.in/doc/product/596_202403050216.jpeg",
      "Selected": 0
    },
    {
      "id":5,
      "SKU": "RW838",
      "Product_ID": 964,
      "CATEGORYNAME": "Gadgets",
      "Product_Name": "Popular Mobile Holder Stand",
      "CurrentStock": 100,
      "Our_Cost": 105.00,
      "docname": "https://app.routeway.in/doc/product/no_image.jpg",
      "Selected": 0
    },
    {
      "id":6,
      "SKU": "RW839",
      "Product_ID": 965,
      "CATEGORYNAME": "Gadgets",
      "Product_Name": "Mobile Chargers",
      "CurrentStock": 449,
      "Our_Cost": 105.00,
      "docname": "https://app.routeway.in/doc/product/no_image.jpg",
      "Selected": 0
    },
    {
      "id":7,
      "SKU": "RW741",
      "Product_ID": 863,
      "CATEGORYNAME": "Beauty & Health ",
      "Product_Name": "Moisturised Lips With Glossy & Shine- 10ml",
      "CurrentStock": 549,
      "Our_Cost": 114.00,
      "docname": "https://app.routeway.in/doc/product/no_image.jpg",
      "Selected": 0
    },
    {
      "id":8,
      "SKU": "RW172",
      "Product_ID": 233,
      "CATEGORYNAME": "Home & Kitchen",
      "Product_Name": "Wireless LED Puck Light",
      "CurrentStock": 500,
      "Our_Cost": 120.00,
      "docname": "https://app.routeway.in/doc/product/233_202301050506.jpg",
      "Selected": 1
    },
    {
      "id":9,
      "SKU": "RW1011",
      "Product_ID": 1141,
      "CATEGORYNAME": "Hot Selling",
      "Product_Name": "Unisex Cotton Solid Hand Gloves",
      "CurrentStock": 555,
      "Our_Cost": 126.00,
      "docname": "https://app.routeway.in/doc/product/no_image.jpg",
      "Selected": 0
    },
    {
      "id":10,
      "SKU": "RW736",
      "Product_ID": 856,
      "CATEGORYNAME": "Beauty & Health ",
      "Product_Name": "Invisible Double-sided Body Tape for Fashion",
      "CurrentStock": 459,
      "Our_Cost": 130.00,
      "docname": "https://app.routeway.in/doc/product/no_image.jpg",
      "Selected": 0
    },
    {
      "id":11,
      "SKU": "RW509-2-C368",
      "Product_ID": 615,
      "CATEGORYNAME": "Beauty & Health ",
      "Product_Name": "Adivasi natural hair oil",
      "CurrentStock": 996,
      "Our_Cost": 132.00,
      "docname": "https://app.routeway.in/doc/product/615_202436140245.jpeg",
      "Selected": 0
    },
    {
      "id":12,
      "SKU": "RW509-C368",
      "Product_ID": 611,
      "CATEGORYNAME": "Beauty & Health ",
      "Product_Name": "Adivasi natural hair oil",
      "CurrentStock": 1932,
      "Our_Cost": 132.00,
      "docname": "https://app.routeway.in/doc/product/611_202415120230.jpeg",
      "Selected": 0
    },
    {
      "id":13,
      "SKU": "RW1026",
      "Product_ID": 1156,
      "CATEGORYNAME": "Men's Fashion",
      "Product_Name": "Aluminium Card Wallet 6 Card Holder (Set of 1)",
      "CurrentStock": 475,
      "Our_Cost": 133.00,
      "docname": "https://app.routeway.in/doc/product/no_image.jpg",
      "Selected": 0
    },
    {
      "id":15,
      "SKU": "RW149",
      "Product_ID": 210,
      "CATEGORYNAME": "Home & Kitchen",
      "Product_Name": "Washer Dryer Anti Vibration Pads",
      "CurrentStock": 10,
      "Our_Cost": 135.00,
      "docname": "https://app.routeway.in/doc/product/210_202343080442.jpg",
      "Selected": 1
    },
    {
      "id":16,
      "SKU": "RW1022",
      "Product_ID": 1152,
      "CATEGORYNAME": "Men's Fashion",
      "Product_Name": "HD Vision Day & Night Goggles",
      "CurrentStock": 145,
      "Our_Cost": 140.00,
      "docname": "https://app.routeway.in/doc/product/no_image.jpg",
      "Selected": 0
    },
    {
      "id":17,
      "SKU": "RW732",
      "Product_ID": 852,
      "CATEGORYNAME": "Beauty & Health ",
      "Product_Name": "Blackthorn Skin Cleanser",
      "CurrentStock": 499,
      "Our_Cost": 142.00,
      "docname": "https://app.routeway.in/doc/product/no_image.jpg",
      "Selected": 0
    },
    {
      "id":18,
      "SKU": "RW758",
      "Product_ID": 880,
      "CATEGORYNAME": "Beauty & Health ",
      "Product_Name": "Ginger Hair Growth Germinal Oil 30 ML (Pack of 2)",
      "CurrentStock": 400,
      "Our_Cost": 146.00,
      "docname": "https://app.routeway.in/doc/product/880_202438270838.jpeg",
      "Selected": 0
    },
    {
      "id":19,
      "SKU": "RW775",
      "Product_ID": 897,
      "CATEGORYNAME": "Beauty & Health ",
      "Product_Name": "5 Minute Painless Herbal Wax Powder (100g)",
      "CurrentStock": 449,
      "Our_Cost": 149.00,
      "docname": "https://app.routeway.in/doc/product/no_image.jpg",
      "Selected": 0
    },
    {
      "id":20,
      "SKU": "RW478",
      "Product_ID": 580,
      "CATEGORYNAME": "Beauty & Health ",
      "Product_Name": "Charcoale Face Mask Cream",
      "CurrentStock": 2500,
      "Our_Cost": 149.00,
      "docname": "https://app.routeway.in/doc/product/580_202408030257.jpeg",
      "Selected": 0
    }
  ],
  "ProductCategory": [
    {
      "id":1,
      "slug": "new-arrivals",
      "CATEGORYNAME": "new-arrivals",
      "Images": "https://app.routeway.in/doc/product/ct_10.jpg",
      "ORDERS": 1
    },
    {
      "id":2,
      "CATEGORYNAME": "Gadgets",
      "slug": "gadgets",
      "Images": "https://app.routeway.in/doc/product/ct_4.jpg",
      "ORDERS": 2
    },
    {
      "id":3,
      "CATEGORYNAME": "Kids",
      "slug": "kids",
      "Images": "https://app.routeway.in/doc/product/ct_5.jpg",
      "ORDERS": 3
    },
    {
      "id":4,
      "CATEGORYNAME": "Home & Kitchen",
      "slug": "home-n-kitchen",
      "Images": "https://app.routeway.in/doc/product/ct_3.jpg",
      "ORDERS": 4
    },
    {
      "id":5,
      "CATEGORYNAME": "Beauty & Health ",
      "slug": "beauty-n-health",
      "Images": "https://app.routeway.in/doc/product/ct_2.jpg",
      "ORDERS": 5
    },
    {
      "id":6,
      "CATEGORYNAME": "Trending Product",
      "slug": "trending-product",
      "Images": "https://app.routeway.in/doc/product/ct_12.jpg",
      "ORDERS": 6
    },
    {
      "id":7,
      "CATEGORYNAME": "Car & Bike Accessories",
      "slug": "car-n-bike-accessories",
      "Images": "https://app.routeway.in/doc/product/ct_8.jpg",
      "ORDERS": 100
    },
    {
      "id":8,
      "CATEGORYNAME": "Cloths",
      "slug": "cloths",
      "Images": "https://app.routeway.in/doc/product/ct_13.jpg",
      "ORDERS": 100
    },
    {
      "id":9,
      "CATEGORYNAME": "Hot Selling",
      "slug": "hot-selling",
      "Images": "https://app.routeway.in/doc/product/ct_11.jpg",
      "ORDERS": 100
    },
    {
      "id":10,
      "CATEGORYNAME": "Jewelry",
      "slug": "jewelry",
      "Images": "https://app.routeway.in/doc/product/ct_7.jpg",
      "ORDERS": 100
    },
    {
      "id":11,
      "CATEGORYNAME": "Men's Fashion",
      "slug": "mens-fashion",
      "Images": "https://app.routeway.in/doc/product/ct_9.jpg",
      "ORDERS": 100
    },
    {
      "id":12,
      "CATEGORYNAME": "Watches",
      "slug": "watches",
      "Images": "https://app.routeway.in/doc/product/ct_6.jpg",
      "ORDERS": 100
    },
    {
      "id":13,
      "CATEGORYNAME": "Women Clothes",
      "slug": "women-clothes",
      "Images": "https://app.routeway.in/doc/product/ct_14.jpg",
      "ORDERS": 100
    }
  ],
  "Page": [
    {
      "TRecord": 828,
      "TPage": 42,
      "CPage": 1
    }
  ]
}