export const orderStatus = {
  
  "SearchResult": [
    {
      "Cloud_Id": "",
      "Order_No": "1127",
      "Order_Date": "14 Mar 2024",
      "ProductName": "SELF DEFENCE STICK",
      "SKU": "RW338",
      "Qty": 1.00,
      "Margin": 519.0000,
      "Customer": "Pragya Bharti, PATEL NIWAS-2B/47, CHITRAKUT NAGAR, DIGHA, DANAPUR, raushan gate, patna, 800012, india, ",
      "AWB": null,
      "Ship_Company": null,
      "Delays": -183,
      "PackingBatch": "",
      "CurrentStatus": null,
      "CurrentStatusDate": null
    },
    {
      "Cloud_Id": "",
      "Order_No": "1126",
      "Order_Date": "01 Mar 2024",
      "ProductName": "SUNISA Water Beauty and Air Cc Natural Cream Found",
      "SKU": "RW293",
      "Qty": 1.00,
      "Margin": 693.0000,
      "Customer": "Md fai, Lal bagh purnea city, Kt classes, Purnea, 854302, india, ",
      "AWB": null,
      "Ship_Company": null,
      "Delays": -185,
      "PackingBatch": "",
      "CurrentStatus": null,
      "CurrentStatusDate": null
    },
    {
      "Cloud_Id": "",
      "Order_No": "1125",
      "Order_Date": "01 Mar 2024",
      "ProductName": "SUNISA Water Beauty and Air Cc Natural Cream Found",
      "SKU": "RW293",
      "Qty": 1.00,
      "Margin": 693.0000,
      "Customer": "faiz ahmad, lal bagh purn city, dsadar thana, purnea, 854302, india, ",
      "AWB": null,
      "Ship_Company": null,
      "Delays": -185,
      "PackingBatch": "",
      "CurrentStatus": null,
      "CurrentStatusDate": null
    }
  ]
}