import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { baseUrl } from "../helper/helper";
import $ from 'jquery'

import 'nice-select2/dist/css/nice-select2.css';
import NiceSelect from 'nice-select2';
import { AppContext } from "../globleDataComponent/contaxtData";


const SourceAProduct = () => {
  const { globalData, setGlobalData } = useContext(AppContext);

    const selectRef = useRef(null);
    const initializedRef = useRef(false); 
    useEffect(() => {
        if ($('.nice_Select').length && !initializedRef.current) {
          $('.nice_Select').each(function () {
            new NiceSelect(this);
          });
          initializedRef.current = true;
        }
      }, []);


    const [imageType, setImageType] = useState('image')
    function changeImagePath(e){
        const { value} = e.target;
        if(value==="url"){
            setImageType('url')
        }else{
            setImageType('image')

        }

    }

  return (
    <div>
      <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
        <Header />

        <div className="main_content_iner overly_inner ">
          <div className="container-fluid p-0">
            <div className="sourceProductContainer">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <div className="imgFull">
                    <img
                      src={`${baseUrl}front/img/product-sourcing-illustration.svg`}
                      alt="img"
                    />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="titleSec">
                    <h4>Your Search Ends Here!</h4>
                    <p>
                      When you don’t find your desired product, let us know, we
                      will try our best to source it.
                    </p>
                  </div>
                  <div className="white_card mb_20 ">
                    <div className="white_card_body QA_section orderList pushportalPadding">
                      <div>
                        <div className="">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="PaymentMethod">
                                  Product Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="inputText"
                                  id="inputText"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="OrderStatus">
                                  Product Category
                                </label>
                                <select
                                  id="OrderStatus"
                                  className="nice_Select wide"
                                  ref={selectRef}
                                >
                                  <option value="">Select </option>
                                  <option value={1} className="">
                                    Car &amp; Bike Personal Care
                                  </option>
                                  <option value={2} className="">
                                    Gym Wellness
                                  </option>
                                  <option value={3}>
                                    Quirky Home Essentials
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group mt-3">
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sPrSelect"
                                        id="sPrSelect1"
                                        checked={imageType==="image"}
                                        defaultValue="option1"
                                        defaultChecked="image"
                                        onClick={(e)=>changeImagePath(e)}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="sPrSelect1"
                                      >
                                        Product Image
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <p>Or</p>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sPrSelect"
                                        id="sPrSelect2"
                                        defaultValue="url"
                                        checked={imageType==="url"}


                                        onClick={(e)=>changeImagePath(e)}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="sPrSelect2"
                                      >
                                        Proudct URL
                                        <i
                                          data-toggle="tooltip"
                                          title="You can share any reference link for the product from other platforms"
                                          className="fa-solid fa-circle-exclamation"
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                    {imageType==="image" && (
                                        <>
                                        <input
                                        type="file"
                                        className="form-control"
                                        name="inputText"
                                        id="uploadImage"
                                        placeholder="Text Input"
                                      />
                                      <small className="text-center">
                                        File type: JPG, PNG | Max file size: 500
                                        kb | Max images: 5
                                      </small>
                                        </>
                                    )}

                                    {imageType==="url" && (
                                        <>
                                        <input
                                        type="url"
                                        className="form-control"
                                        name="image_url"
                                        // id="uploadImage"
                                        placeholder="Url"
                                      />
                                       
                                        </>
                                    )}
                                      
                                    </div>
                                  </div>
                                  {/* <div className="col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="inputText"
                                        id="inputText"
                                        placeholder="Text Input"
                                      />
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 ">
                              <div className="form-group">
                                <label htmlFor="PaymentMethod">
                                  Expected Routeway Price (₹)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="inputText"
                                  id="inputText"
                                  placeholder="Text Input"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="dailyOrders">
                                  Expected Daily Orders
                                </label>
                                <select
                                  className="nice_Select wide"
                                  id="dailyOrders"
                                >
                                  <option>1-4</option>
                                  <option>5-30</option>
                                  <option>31-100</option>
                                  <option>100+</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <button type="submit" className="btn btn-primary">
                                Submit Request
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </div>
  );
};

export default SourceAProduct;
