import React, { useEffect, useRef, useState } from 'react'
import 'metismenu/dist/metisMenu.min.css';
import $ from 'jquery';
 
 
import 'nice-select2/dist/css/nice-select2.css';
import NiceSelect from 'nice-select2';
const Filter = () => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [collapseCompleted, setCollapseCompleted] = useState(false);

    const initializedRef = useRef(false); 
    useEffect(() => {
        if ($('.nice_Select').length && !initializedRef.current) {
          $('.nice_Select').each(function () {
            new NiceSelect(this);
          });
          initializedRef.current = true;
        }
      }, []);

    
    const handleToggle = () => {
      // setCollapseCompleted(true);
      setIsCollapsed(!isCollapsed);
      setTimeout(() => {
        setCollapseCompleted(false); // If you need to handle the state after the collapse animation
      }, 100);

    };

     
    useEffect(()=>{
            const $ = window.$; // Access jQuery from the global window object
                if ($ && $.fn.niceSelect) {
                $('.nice_Select').niceSelect();

                return ()=>{
                    $('.nice_Select').niceSelect('destroy');
                }

                // date picker
                


                } else {
                console.error('jQuery or niceSelect is not available');
                }
        },[])
    
       

    

  return (
    <>
     <div className="col-lg-12">
  <div className="white_card card_height_100 mb_30 filterCard">
    <div className="white_card_header carHeadertwo">
      <div className="box_header m-0">
        <div className="main-title">
          <h3 className="m-0">Filters</h3>
          {/* <button
            className="collapsed"
            data-toggle="collapse"
            data-target="#filter"
            aria-expanded="false"
            aria-controls="filter"
          > */}

<button
                className="btn btn-link "
                onClick={handleToggle}
                aria-expanded={!isCollapsed}
                 aria-controls="filter"
              >


            <i className="fa-solid fa-filter" />
          </button>
        </div>
      </div>
    </div>
    {/* <div
      className="white_card_body collapse"
      id="filter"
      aria-labelledby="headingOne"
      data-parent="#accordion"
    > */}

<div
          className={`white_card_body collapse ${collapseCompleted?'collapsing':''}  ${isCollapsed ? '' : ' show'}`}
          id="filter"
        >


      <div className="row">
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="OrderStatus">Order Status</label>
            <select id="OrderStatus" className="nice_Select wide">
                                                <option value="">Select </option>
                                                <option value="">All </option>
                                                <option value="1" className="">New</option>
                                                <option value="2" className="">Confirmed</option>
                                                <option value="3" className="d-none">Cancelled</option>
                                                <option value="4" className="d-none">Pickup Initiated</option>
                                                <option value="5" className="d-none">Pickup Cancelled</option>
                                                <option value="6" className="d-none">Manifested</option>
                                                <option value="7" className="d-none">Pickup Pending</option>
                                                <option value="8" className="d-none">Pickup Completed</option>
                                                <option value="9" className="d-none">In Transit</option>
                                                <option value="10" className="d-none">Undelivered</option>
                                                <option value="11" className="d-none">Out For Delivery</option>
                                                <option value="12" className="d-none">Delivered</option>
                                                <option value="13" className="d-none">RTO</option>
                                                <option value="14" className="d-none">RTO In Transit</option>
                                                <option value="15" className="d-none">RTO Delivered</option>
                                                <option value="16" className="d-none">Booking in Process</option>
                                                <option value="17" className="d-none">Cancel in Process</option>
                                                <option value="18" className="d-none">Shipment Lost</option>
                                                <option value="19" className="d-none">Shipment Damaged</option>
                                                <option value="20" className="d-none">Order Confirmation In Process</option>
                                                <option value="21" className="d-none">Shipment Cancelled</option>
                                                <option value="22" className="d-none">Out for Pickup</option>
                                                <option value="200" className="d-none">Cancelled on Dropdash</option>
                                                <option value="201" className="d-none">Booked</option>
                                            </select>
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="PaymentMethod">Payment Method</label>
            <select className="nice_Select wide" id="PaymentMethod">
              <option value="">Select</option>
              <option value={1}>COD</option>
              <option value={2}>Prepaid</option>
            </select>
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="ProductName">Product Name</label>
            <select className="nice_Select wide" id="ProductName">
              <option>-- Select --</option>
              <option>
                RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2
              </option>
              <option>RW322 !!! Mini Electric Shaver</option>
              <option>
                RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2
              </option>
            </select>
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="storeSelect">Store</label>
            <select className="nice_Select wide" id="storeSelect">
              <option>-- Select --</option>
              <option value={1}>RouteWay</option>
              <option value={2}>Shopify</option>
            </select>
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="PaymentMethod">Date</label>
            <input
              className="form-control datepicker-here  digits"
              type="text"
              data-range="true"
              data-multiple-dates-separator=" - "
              data-language="en"
            />
          </div>
        </div>
        <div className="col-md-6">
          <label
            htmlFor="PaymentMethod"
            style={{ display: "block", visibility: "hidden" }}
          >
            Date
          </label>
          <button type="button" className="btn btn-secondary">
            Reset
          </button>
          <button type="submit" className="btn btn-primary">
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default Filter
