import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import EditOrderModel from './EditOrderModel';

const NewOrder = ({activeTab, orders}) => {

  const [editOrder ,setEditOrder] = useState(false);

  function closeEditModal(e){
    setEditOrder(false)
  }
  function openEditModal(e){
    e.preventDefault();
      setEditOrder(true)
  }
    
    useEffect(() => {
        let table;
    
        if (window.$ && window.$.fn.DataTable) {
          // Initialize DataTable
          table = window.$('#DataTables_Table_1').DataTable();
        }
    
        // Cleanup on component unmount
        return () => {
          if (table && window.$.fn.DataTable.isDataTable('#DataTables_Table_1')) {
            // table.destroy(true);
          }
        };
      }, []); // Empty dependency array to run only on mount/unmount


  return (
    <div style={{display:`${activeTab === 'pendingOrders' ? '' : 'none'}`}}>
      
        
       <table className="table p-0 orderlisttable dataTable lms_table_active collapsed "  id='DataTables_Table_1' >
                                                            <thead>
                                                                <tr role="row">
                                                                    <th>S.no</th>
                                                                    {/* <th style={{display:`${activeTab === 'pendingOrders' ? '' : 'none'}`}}><input type="checkbox" className="formcheck"
                                                                            id="exampleCheck1" /></th> */}
                                                                    <th style={{minWidth:'200px'}}> Item</th>
                                                                    <th style={{minWidth:'100px'}}> Order no</th>
                                                                    <th>Date</th>
                                                                    <th> Qty</th>
                                                                    <th> Price</th>
                                                                    <th> Margin </th>
                                                                    <th> Customer</th>
                                                                    <th> Mobile</th>
                                                                    <th> Status</th>
                                                                    <th style={{display:`${activeTab === 'pendingOrders' ? '' : 'none'}`}} >
                                                                        Action</th>
                                                                </tr>
                                                             
                                                                </thead>
                                                                <tbody >
        {orders.length>0 && orders.map((order, index)=>(
                
                
              

                                                                    <tr role="row" className="odd" key={order.OID}>
                                                                            <td>{index+1}</td>
                                                                            {/* <td><input type="checkbox" className="formcheck"
                                                                                    id="exampleCheck1" /></td> */}
                                                                            <td tabindex="0" className="sorting_1">
                                                                                {order.ProductName}
                                                                            </td>
                                                                            <td className="color_text_6" dangerouslySetInnerHTML={{ __html: order.Order_NO }}></td>
                                                                            <td className="color_text_6">{`${(new Date(order.Order_Date)).getDate()}/${(new Date(order.Order_Date)).toLocaleString('default', { month: 'short' })}/${(new Date(order.Order_Date)).getFullYear()}`}</td>
                                                                            <td className="color_text_6">{order.Quantity}</td>
                                                                            <td className="color_text_6"> {order.SellingPrice}</td>
                                                                            <td className="color_text_6">{order.Margin}</td>
                                                                            <td className="color_text_6">{order.Name}</td>
                                                                            <td className="color_text_6">{order.Mobile}</td>
                                                                            <td>
                                                                                {order.Statuss?(<Link to="#" className="status_btn">{order.Statuss}</Link>):('')}
                                                                            </td>
                                                                            <td className="color_text_6">
                                                                                <div className="action_btns d-flex">
                                                                                    <Link to="#" className="action_btn mr_10" title='Edit'
                                                                                    onClick={(e)=>openEditModal(e)}> <i
                                                                                            className="far fa-edit"></i> </Link>
                                                                                    <Link to="#" className="action_btn" title='Accept'> <i className="fas fa-check-circle"></i> </Link>
                                                                                    <Link to="#" className="action_btn" title='Cancel'> <i className="fas fa-cancel"></i> </Link>
                                                                                    
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                   
                                                          ))}
        
                    </tbody>
                    </table>
      

     <EditOrderModel editOrder={editOrder} closeEditModal={closeEditModal}/>
    </div>
  )
}

export default NewOrder
