import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import { baseUrl } from "../helper/helper";
import { Link } from "react-router-dom";
import RouteWayUpadteSection from "../components/routeWayUpdateComponents/section";
import { AppContext } from "../globleDataComponent/contaxtData";

const RoutewayUpdates = () => {
  const { globalData, setGlobalData } = useContext(AppContext);


    const [currentTab, setCurrentTab] = useState('all');
    const [data, setData] = useState([]);

const allData =[
    {
        title:"Just In: New Arrivals",
        date:"25 Sep 2024",
        image:`${baseUrl}front/img/updates/img1.jpg`
    },
    {
        title:"Just In: New Arrivals",
        date:"25 Sep 2024",
        image:`${baseUrl}front/img/updates/img1.jpg`
    }
];

const business =[
    {
        title:"Just In: New Arrivals Business",
        date:"16 Sep 2024",
        image:`${baseUrl}front/img/updates/img1.jpg`
    },
    {
        title:"Just In: New Arrivals Business",
        date:"15 Sep 2024",
        image:`${baseUrl}front/img/updates/img2.jpg`
    }
]

const productUpadte =[
    {
        title:"Just In: New Arrivals product update",
        date:"27 Oct 2024",
        image:`${baseUrl}front/img/updates/img2.jpg`
    },
    {
        title:"Just In: New Arrivals product update",
        date:"25 Oct 2024",
        image:`${baseUrl}front/img/updates/img1.jpg`
    }
]

const marketing =[
    {
        title:"Just In: New Arrivals product marketing",
        date:"26 Sep 2024",
        image:`${baseUrl}front/img/updates/img1.jpg`
    },
    {
        title:"Just In: New Arrivals product marketing",
        date:"25 Sep 2024",
        image:`${baseUrl}front/img/updates/img2.jpg`
    }
]


useEffect(()=>{
    changeTabFun('all')
},[])

function changeTabFun(tabName){
    setCurrentTab(tabName)
    if(tabName==="all"){ 
        setData(allData)
    }else if(tabName==="marketing"){ 
        setData(marketing) 
    }else if(tabName==="productUpdates"){ 
        setData(productUpadte) 
    }else if(tabName==="business"){ 
        setData(business)

    }
}

  return (
    <div>
      <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
        <Header />

        <div className="main_content_iner overly_inner ">
          <div className="container-fluid p-0 "> 
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="white_card card_height_100 mb_20 ">
                      <div
                        className="white_card_body QA_section orderList"
                        style={{ paddingTop: 25 }}
                      >
                        <div className="bulder_tab_wrapper">
                          <ul className="nav" id="myTab" role="tablist">
                            <li className="nav-item">
                              <Link
                                className={`nav-link ${currentTab==="all"?"active":''}`}
                                id="AllUpdatesTab"
                                // data-toggle="tab"
                                to="#"
                                onClick={()=>changeTabFun('all')}
                                // role="tab"
                                // aria-controls="AllUpdates"
                                // aria-selected="true"
                              >
                                All <span className="orderQty">4</span>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className={`nav-link ${currentTab==="business"?"active":''}`}
                                id="BusinessTab"
                                // data-toggle="tab"
                                to="#"
                                onClick={()=>changeTabFun('business')}

                                // role="tab"
                                // aria-controls="Business"
                                // aria-selected="true"
                              >
                                Business
                                <span className="orderQty">4</span>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className={`nav-link ${currentTab==="productUpdates"?"active":''}`}
                                id="productUpdates"
                                // data-toggle="tab"
                                to="#"
                                onClick={()=>changeTabFun('productUpdates')}

                                // role="tab"
                                // aria-controls="productUpdates"
                                // aria-selected="true"
                              >
                                Product Updates
                                <span className="orderQty">142</span>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className={`nav-link ${currentTab==="marketing"?"active":''}`}
                                id="marketing"
                                // data-toggle="tab"
                                to="#"
                                onClick={()=>changeTabFun('marketing')}

                                // role="tab"
                                // aria-controls="marketing"
                                // aria-selected="true"
                              >
                                Marketing
                                <span className="orderQty">57</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <RouteWayUpadteSection data={data} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </div>
  );
};

export default RoutewayUpdates;
