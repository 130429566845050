import React, { useEffect } from 'react'
import { reportTab } from '../../Http/data/reportTab'
 
import { baseUrl } from '../../helper/helper'
import { Link, useLocation } from 'react-router-dom';

const ReportHeader = () => {
    const location = useLocation();

    
  return (
    <div className="col-md-12">
    <div className="user_crm_wrapper">
      <div className="row">

        {reportTab.length>0 && reportTab.map((data, index)=>(
             <div className="col-lg-2" key={index}>
             <Link to={data.url}>
               <div className={`dashdataCard settingCard  mb-3 d-flex align-items-center justify-content-center   ${data.url ===location.pathname?'active':''}`}>
                 <div className="plan_left d-flex align-items-center">
                   <div>
                     <h5>{data.title}</h5>
                   </div>
                 </div>
               </div>
             </Link>
           </div>
        ))}
        
      </div>
    </div>
  </div>
  )
}

export default ReportHeader
