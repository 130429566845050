import React from "react";
import { baseUrl } from "../../../helper/helper";

const Calculator = () => {
  return (
    <div
      className="modal fade"
      id="calculatorSec"
      tabindex="-1"
      role="dialog"
      aria-labelledby="calculatorSecTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="calcModHead">
              <div className="calcHeadIcon">
                <i className="fa-solid fa-calculator" />
              </div>
              <div className="calcHeadTitle">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Bumper Dhamaka Offer
                </h5>
                <p>
                  Please enter all the required fields (<span style={{color: 'red'}}>*</span>)
                  to calculate your expected profit
                </p>
              </div>
            </div>
            <button className="calcresetBtn">
              <i className="fa-solid fa-rotate-right" /> Reset
            </button>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="calcprHeader">
              <div className="calcprInfo">
                <div className="calprImg">
                  <img src={`${baseUrl}front/img/products/img-5.png`} alt="img" />
                </div>
                <ul className="calcprinful">
                  <li>
                    <p>Routeway Price</p>
                    <h5>
                      ₹ <span>437</span>
                    </h5>
                  </li>
                  <li>
                    <p>RTO Charges</p>
                    <h5>
                      ₹ <span>72</span>
                      <span className="text">
                        <i
                          data-toggle="tooltip"
                          title="RTO charges are applicable and vary depending on the product weight."
                          className="fa-solid fa-circle-exclamation"
                        />
                      </span>
                    </h5>
                  </li>
                  <li>
                    <p>Product Weight</p>
                    <h5>
                      <span>224</span>gm
                    </h5>
                  </li>
                </ul>
              </div>
              <button type="button" className="btn btn-primary mb-3 pushtoBtn">
                Push to Shopify <i className="fa-solid fa-arrow-right" />
              </button>
            </div>
            <div className="dhamakaOfferWrap calcCardWrapper">
              <div className="dhamakaOffers">
                <div className="dhamakofferCard calcCard">
                  <div className="calcCardContainer">
                    <form>
                      <div className="">
                        <label for="SellingPrice" className="form-label">
                          <p>
                            Selling Price <span style={{color: 'red'}}>*</span>
                          </p>
                          <input
                            type="number"
                            className="form-control"
                            id="SellingPrice"
                            aria-describedby="number"
                          />
                        </label>
                      </div>
                      <hr />
                      <div className="">
                        <label for="ExpectedOrders" className="form-label">
                          <p>
                            Expected Orders <span style={{color: 'red'}}>*</span>
                          </p>
                          <input
                            type="number"
                            className="form-control"
                            id="ExpectedOrders"
                            aria-describedby="number"
                          />
                        </label>
                      </div>
                      <div className="">
                        <label for="ConfirmedOrders" className="form-label">
                          <p>
                            Confirmed Orders (%)
                            <span style={{color: 'red'}}>*</span>
                          </p>
                          <input
                            type="number"
                            className="form-control"
                            id="ConfirmedOrders"
                            aria-describedby="number"
                            disabled
                          />
                        </label>
                      </div>
                      <div className="">
                        <label for="ExpectedDelivery" className="form-label">
                          <p>
                            Expected Delivery (%)
                            <span style={{color: 'red'}}>*</span>
                          </p>
                          <input
                            type="number"
                            className="form-control"
                            id="ExpectedDelivery"
                            aria-describedby="number"
                            disabled
                          />
                        </label>
                      </div>
                      <hr />
                      <div className="">
                        <label for="AdSpendsperorder" className="form-label">
                          <p>
                            Ad Spends per order<span style={{color: 'red'}}>*</span>
                          </p>
                          <input
                            type="number"
                            className="form-control"
                            id="AdSpendsperorder"
                            aria-describedby="number"
                          />
                        </label>
                      </div>
                      <div className="">
                        <label for="TotalMiscCharges" className="form-label">
                          <p>Total Misc. Charges </p>
                          <input
                            type="number"
                            className="form-control"
                            id="TotalMiscCharges"
                            aria-describedby="number"
                          />
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dhamakofferCard calcNetProfitEarnings">
                  <div className="calcNtprearnCard">
                    <ul>
                      <li>
                        <span>
                          <h5>Net Profile</h5>
                          <p>Total Earnings - Total Spends</p>
                        </span>
                        <span>N/A</span>
                      </li>
                      <li>
                        <span>
                          <h5>Net Profile (Per Order)</h5>
                          <p>Net Profit / Expected Orders</p>
                        </span>
                        <span>N/A</span>
                      </li>
                    </ul>
                  </div>
                  <div className="calcNtprearnCard others">
                    <ul>
                      <li>
                        <i className="fa-solid fa-box-open" />
                        <span className="caclntpcrTitle">
                          <h5># Orders</h5>
                        </span>
                        <span>N/A</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-arrow-down" />
                        <span className="caclntpcrTitle">
                          <h5>Total Earnings</h5>
                        </span>
                        <span>N/A</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-arrow-up" />
                        <span className="caclntpcrTitle">
                          <h5>Total Spends</h5>
                        </span>
                        <span>N/A</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="dhamakofferInfos">
                <p>
                  <strong>Note:</strong> This calculator provides estimated
                  figures. Actual results may vary. Routeway does not commit to
                  any expected profit based on these calculations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
