import React, { useContext } from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import { AppContext } from '../../globleDataComponent/contaxtData';
import { baseUrl } from '../../helper/helper';

const MyProfile = () => {
    const { globalData, setGlobalData } = useContext(AppContext);

  return (
    <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
    <Header />

    <div className="main_content_iner overly_inner profileSection">
            <div className="container-fluid p-0 ">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="homeBanner mb_30 myprofileBanner">
                            <div className="homeBnrImg">
                               <a href="javascript:void(0)"> <img src={`${baseUrl}front/img/banners/banner-profile.jpg`} alt="bnrimg" /></a>
                            </div>
                            <div className="profilePicSection">
                                <div className="profileWork">
                                    <img src={`${baseUrl}front/img/profilePictures/1.png`} alt="img" />
                                </div>
                                <div className="profilePicContent">
                                    <h4>Rohit Gupta</h4>
                                    <p>Dropshipper</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="white_card card_height_100 mb_30">
                            <div className="white_card_header">
                                <div className="box_header m-0">
                                    <div className="main-title">
                                        <h3 className="m-0">Profile Details</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="white_card_body">
                                <div className="profileBody">
                                    <form action="#">
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label for="FullName">Full Name</label>
                                                <input type="text" className="form-control" id="FullName" placeholder="" value="Rohit Gupta" disabled />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="emailVendor">Email</label>
                                                <input type="email" className="form-control" id="emailVendor" placeholder="" value="routeway@rohitgupta.in" disabled />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="phoneVendor">Contact</label>
                                                <input type="text" className="form-control" id="phoneVendor" placeholder="" value="+91 8988 888 888" disabled />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="userType">User Type</label>
                                                <input type="text" className="form-control" id="userType" placeholder="" value="Dropshipper" disabled />
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Edit Details</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>


     <Footer />
    </section>
  )
}

export default MyProfile
