import React from 'react'

const OfferModel = () => {
  return (
    <div className="modal fade" id="viewDiscoutnOffer" tabindex="-1" role="dialog" aria-labelledby="viewDiscoutnOfferTitle"
    aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">Bumper Dhamaka Offer</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="dhamakaOfferWrap">
                    <div className="dhamakaOffers">
                        <div className="dhamakofferCard">
                            <div className="dhamakaofferHeader dmofferRow">
                                <span>Orders</span>
                                <span>Discount</span>
                            </div>
                            <div className="dhamakaOfferContainer">
                                <div className="dmofferRow">
                                    <span>1 - 1,000</span>
                                    <span>₹79</span>
                                </div>
                                <div className="dmofferRow">
                                    <span>1,001 - 2,000</span>
                                    <span>₹84</span>
                                </div>
                                <div className="dmofferRow">
                                    <span>2,001 - 5,000</span>
                                    <span>₹86.5</span>
                                </div>
                                <div className="dmofferRow">
                                    <span>5,001 - 10,000</span>
                                    <span>₹89</span>
                                </div>
                                <div className="dmofferRow">
                                    <span>10,001 - 20,000</span>
                                    <span>₹94</span>
                                </div>
                                <div className="dmofferRow">
                                    <span>20,001 - 30,000</span>
                                    <span>₹99</span>
                                </div>
                            </div>
                        </div>
                        <div className="dhamakofferCard">
                            <div className="dhamakaofferHeader dmofferRow">
                                <span>Orders</span>
                                <span>Discount</span>
                            </div>
                            <div className="dhamakaOfferContainer">
                                <div className="dmofferRow">
                                    <span>1 - 1,000</span>
                                    <span>₹79</span>
                                </div>
                                <div className="dmofferRow">
                                    <span>1,001 - 2,000</span>
                                    <span>₹84</span>
                                </div>
                                <div className="dmofferRow">
                                    <span>2,001 - 5,000</span>
                                    <span>₹86.5</span>
                                </div>
                                <div className="dmofferRow">
                                    <span>5,001 - 10,000</span>
                                    <span>₹89</span>
                                </div>
                                <div className="dmofferRow">
                                    <span>10,001 - 20,000</span>
                                    <span>₹94</span>
                                </div>
                                <div className="dmofferRow">
                                    <span>20,001 - 30,000</span>
                                    <span>₹99</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dhamakofferInfos">
                        <p>
                            <strong>e.g. One dropshipper receives 31k orders in Sep 2024, out of which only 20k orders get delivered. He is eligible for the 30,001-50,000 slab and will get ₹104 per delivered order i.e. 20,000*104 = ₹20,80,000.</strong>
                        </p>
                        <p><strong>Note:</strong></p>
                        <ol>
                            <li>The scheme is valid only for a single store ID. Multiple dropshipper store IDs cannot be clubbed or considered as the same account.</li>
                            <li>One dropshipper is eligible for one scheme at a time. The dropshipper is not eligible for multiple discount schemes at any given point.</li>
                            <li>The scheme is valid only for orders placed in the mentioned time period - 1st Sep to 30th Sep 2024.</li>
                            <li>All placed orders net of cancellation will be considered to calculate the order slab for the discount scheme, but the discount payout will be done only on delivered orders.</li>
                            <li>The discount scheme is inclusive of GST.</li>
                            <li>This discount is on the shipping fees charged on the product.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default OfferModel
