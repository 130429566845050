import axios from "axios";

export const baseUrl = "https://routeway.aks.5g.in/";
export const baseUrl2 = "http://localhost:3000/";

export const httpClient = axios.create({
          baseURL:baseUrl2,
          withCredentials: false,
})

export function getDiscount(mrp,price){
    
    const discountPercentage = ((mrp - price) / mrp) * 100;
    return discountPercentage;

}

export const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
      document.body.appendChild(script);
    });
  };

