import React, { useContext, useEffect, useRef, useState } from 'react'
import Footer from '../components/footer'
import Header from '../components/header'
import ReportHeader from '../components/reportComponents/reportHeader'
import $ from 'jquery';
 
 
import 'nice-select2/dist/css/nice-select2.css';
import NiceSelect from 'nice-select2';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import StateWiseSummaryTable from '../components/reportComponents/stateWiseSummaryTable';
import { AppContext } from '../globleDataComponent/contaxtData';



const StateWiseSummary = () => {
    
    const [filterOpen, setFilterOpen] = useState(false);
    const initializedRef = useRef(false); 
    const datePickerRef = useRef(null);
    const { globalData, setGlobalData } = useContext(AppContext);

    useEffect(() => {
        if ($('.nice_Select').length && !initializedRef.current) {
        $('.nice_Select').each(function () {
            new NiceSelect(this);
        });
        initializedRef.current = true;
        }
    }, []);


    useEffect(() => {
        // Initialize Air Datepicker
        new AirDatepicker(datePickerRef.current, {
          range: true,
          multipleDatesSeparator: ' - ',
          dateFormat: 'MM/dd/yyyy', // Change this format as needed (e.g., 'dd/MM/yyyy')
          language: {
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            months: [
              'January', 'February', 'March', 'April', 'May', 'June',
              'July', 'August', 'September', 'October', 'November', 'December'
            ],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear',
            dateFormat: 'MM/dd/yyyy',
            timeFormat: 'HH:mm',
            firstDay: 0 // Sunday as the first day of the week
          },
          position: 'bottom left', // Adjusts the calendar position
          autoClose: true, // Closes the calendar after selecting a date range
          onSelect: ({ formattedDate }) => {
            console.log('Selected date range:', formattedDate);
          }
        });
      }, []);

  return (
    <div>
    <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
      <Header />

      <div className="main_content_iner overly_inner ">
        <div className="container-fluid p-0 ">
          <div className="row">
            <ReportHeader />

            <div className="col-lg-12">
              <div className="white_card card_height_100 mb_30 filterCard">
                <div className="white_card_header carHeadertwo">
                  <div className="box_header m-0">
                    <div className="main-title">
                      <h3 className="m-0">Filters</h3>
                      <button
                        className="collapsed"
                        // data-toggle="collapse"
                        // data-target="#filter"
                        // aria-expanded="false"
                        // aria-controls="filter"
                        onClick={() => setFilterOpen(!filterOpen)}
                      >
                        <i className="fa-solid fa-filter" />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`white_card_body collapse ${filterOpen
                    ? "show"
                    : ""}`}
                  id="filter"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="PaymentMethod">Date</label>
                        <input
                            className="form-control datepicker-here  digits"
                            type="text"
                            
                            placeholder="Select date range"
                            ref={datePickerRef}
                          />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="storeSelect">Method</label>
                        <select className="nice_Select wide" id="storeSelect">
                          <option>-- Select --</option>
                          <option value={1}>All</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="storeSelect">Courier</label>
                        <select className="nice_Select wide" id="storeSelect">
                          <option>-- Select --</option>
                          <option value={1}>All</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="PaymentMethod"
                        style={{ display: "block", visibility: "hidden" }}
                      >
                        Date
                      </label>
                      <button type="button" className="btn btn-secondary">
                        Reset
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <StateWiseSummaryTable />
             


          </div>
        </div>
      </div>

      <Footer />
    </section>
  </div>
  )
}

export default StateWiseSummary
