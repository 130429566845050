import React, { useContext, useState }  from 'react'
import searchIcon from '../front/img//icon/icon_search.svg';
import logo from '../front/img//client_img.png';
import mailImg from '../front/img//icon/mail.png';
import { baseUrl } from '../helper/helper';
import { Link } from 'react-router-dom';
import { AppContext } from '../globleDataComponent/contaxtData';

 

export default function Header() {

 const [sidebarInfo, setSidebarInfo] = useState(false);
 const {globalData, setGlobalData} = useContext(AppContext);

 const hendleSideBar =()=>{
  setSidebarInfo(!sidebarInfo);
 }

 function menuTogleFun(){
  console.log('menu togle')
  setGlobalData((prevData) => {
    const updatedData = { ...prevData, menu: !prevData.menu };
    console.log('Updated Global Data:', updatedData);
    return updatedData;
  })
 }

 function mobileMenuUpdate(){
  console.log('menu togle')
  
  setGlobalData((prevData) => {
    const updatedData = { ...prevData, mobileMenu: !prevData.mobileMenu };
    console.log('Updated Global Data:', updatedData);
    return updatedData;
  })
 }
  return (
    <>
      <div className="container-fluid no-gutters">
            <div className="row">
              <div className="p-0 col-lg-12 ">
                <div className="header_iner d-flex justify-content-between align-items-center">
                  <div className="sidebar_icon d-lg-none"
                  onClick={mobileMenuUpdate}>
                    <i className="ti-menu" />
                  </div>
                  <div className="line_icon open_miniSide d-none d-lg-block"
                  onClick={menuTogleFun}>
                    <img src={`${baseUrl}front/img/line_img.png`} alt="" />
                  </div>
                  <div className="serach_field-area d-flex align-items-center">
                    <div className="search_inner">
                      <form action="#" onSubmit={(e)=>e.preventDefault()}>
                        <div className="search_field">
                          <input type="text" placeholder="Search" />
                        </div>
                        <button type="submit"> <img src={searchIcon} alt="" /> </button>
                      </form>
                    </div>
                  </div>
                  <div className="header_right d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <li>
                        <Link className="dataquestion infoSideBarOpener" to="#" onClick={hendleSideBar}> 
                          <i className="fa-regular fa-circle-question" />
                        </Link>
                        <div className="sidebarInfo" style={{right:`${!sidebarInfo?'-100':'0'}%`}}>
                          <div className="sideinfoHeader" >
                            <h4>Get Help</h4>
                            <Link href="#" className="infoSideBarCloser"  onClick={hendleSideBar}><i className="fa-solid fa-xmark" /></Link>
                          </div>
                          <div className="sideinfoContainer">
                            <ul>
                              <li>
                                <img src={`${baseUrl}front/img//icon/phone-call.png`} alt="front/img/" />
                                <div className="sideInfo">
                                  <p>Call Us</p>
                                  <h5><a href="tel:0000000000">+91 000 000 0000</a><span> Mon - Sat, 10am - 7pm</span></h5>
                                </div>
                              </li>
                              <li>
                                <img src={mailImg} alt="front/img/" />
                                <div className="sideInfo">
                                  <p>Mail Us</p>
                                  <h5><a href="mailto:support@routway.com">support@routway.com</a></h5>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="profile_info">
                      <img src={logo} alt="#" />
                      <div className="profile_info_iner">
                        <div className="profile_author_name">
                          <h5><i className="fa-solid fa-user" /> <span>Rohit Gupta</span></h5>
                          <h5><i className="fa-solid fa-phone-volume" /> <span>88888888888</span></h5>
                          <h5><i className="fa-solid fa-envelope" /> <span>rohitgupt321@gmail.com</span></h5>
                        </div>
                        <div className="profile_info_details">
                          <Link to="#">My Profile </Link>
                          <Link to="/setting">Settings</Link>
                          <Link to="/signup">Log Out </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}
