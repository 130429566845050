import React, { useContext } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import { AppContext } from "../globleDataComponent/contaxtData";

const RtoFaqs = () => {
  const { globalData, setGlobalData } = useContext(AppContext);

  return (
    <div>
      <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
        <Header />

        <div className="main_content_iner overly_inner ">
          <div className="container-fluid p-0">
            <div className="white_card mb_20 ">
              <div className="white_card_body QA_section orderList pushportalPadding">
                <div className="titleSec">
                  <h4>FAQ</h4>
                </div>
                <div id="accordion" className="accordion">
                  <div className="card mb-0">
                    <div
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapseOne"
                    >
                      <a className="card-title">What is RTO?</a>
                    </div>
                    <div
                      id="collapseOne"
                      className="card-body collapse"
                      data-parent="#accordion"
                    >
                      <p>
                        RTO stands for Return to origin. It refers to orders
                        which have been shipped by the supplier, but were not
                        delivered to the customer due to various reasons such as
                        incorrect address, customer’s refusal of order, customer
                        unavailability etc.
                      </p>
                    </div>
                    <div
                      className="card-header collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseTwo"
                    >
                      <a className="card-title">
                        Why is RTO bad for my business as well as Routeway ?
                      </a>
                    </div>
                    <div
                      id="collapseTwo"
                      className="card-body collapse"
                      data-parent="#accordion"
                    >
                      <p>
                        RTO orders represent missed revenue for you as well as
                        Routeway. We also incur significant reverse logistics
                        costs to send the product back to the supplier. To
                        recover these reverse logistics costs, we charge you a
                        fee for every RTO order.
                      </p>
                    </div>
                    <div
                      className="card-header collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseThree"
                    >
                      <a className="card-title">
                        How are RTO charges calculated?
                      </a>
                    </div>
                    <div
                      id="collapseThree"
                      className="card-body collapse"
                      data-parent="#accordion"
                    >
                      <p>
                        RTO charges are applicable on all RTO orders. The
                        charges vary as per product weight. You can check the
                        applicable RTO charges for any product from its product
                        page before pushing it to Shopify. All charges mentioned
                        there are inclusive of GST.
                      </p>
                    </div>
                    <div
                      className="card-header collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseFour"
                    >
                      <a className="card-title">What does RTO risk mean?</a>
                    </div>
                    <div
                      id="collapseFour"
                      className="card-body collapse"
                      data-parent="#accordion"
                    >
                      <p>
                        RTO Risk is our system’s prediction of how likely an
                        order is likely to be RTOed. We classify orders as High,
                        Moderate and No Risk.
                      </p>
                    </div>
                    <div
                      className="card-header collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseFive"
                    >
                      <a className="card-title">How is RTO risk calculated?</a>
                    </div>
                    <div
                      id="collapseFive"
                      className="card-body collapse"
                      data-parent="#accordion"
                    >
                      <p>
                        Based on millions of historical and recent orders, our
                        algorithm factors in various order parameters such as
                        pincode, customer address, price, category, etc. and
                        predicts the probability of RTO happening and assigns a
                        risk score.
                      </p>
                    </div>
                    <div
                      className="card-header collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseSix"
                    >
                      <a className="card-title">
                        When is RTO risk calculated, and where is it displayed?
                      </a>
                    </div>
                    <div
                      id="collapseSix"
                      className="card-body collapse"
                      data-parent="#accordion"
                    >
                      <p>
                        RTO risk is calculated as soon as the order is synced to
                        routeway from your website (expect a few minutes
                        processing time) and is displayed on your Routeway
                        dashboard. As of now, RTO risk is only calculated for
                        cash on delivery orders.
                      </p>
                    </div>
                    <div
                      className="card-header collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseSix"
                    >
                      <a className="card-title">
                        One of my orders has been flagged as High / Moderate
                        Risk. What do I do?
                      </a>
                    </div>
                    <div
                      id="collapseSix"
                      className="card-body collapse"
                      data-parent="#accordion"
                    >
                      <p>
                        Below the risk score of each order, the reason for the
                        risk is also displayed. Depending on this reason, you
                        may take appropriate action steps to reduce the risk.
                      </p>
                    </div>
                    <div
                      className="card-header collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseSeven"
                    >
                      <Link className="card-title">
                        My order has been flagged as High / Moderate Risk
                        because of Short Address Length or Invalid Address. What
                        does this mean and what do I do?
                      </Link>
                    </div>
                    <div
                      id="collapseSeven"
                      className="card-body collapse"
                      data-parent="#accordion"
                    >
                      <p>
                        For delivery to be successful. the customer address
                        needs to be complete. By invalid address, our system
                        detects that the address does not have functional words,
                        but gibberish (say xnururnjnjsew) . By short address
                        length, we suspect that the address is not long enough
                        to be complete. You are advised to contact your customer
                        and get the address updated, by clicking on the view /
                        Edit Details button in the customer details column for
                        the order on your Routeway dashboard. As soon as you
                        update the address, the system re calculates the RTO
                        risk and assigns a new Risk category.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </div>
  );
};

export default RtoFaqs;
