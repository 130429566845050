import React, { useContext, useEffect, useRef, useState } from 'react'
import { baseUrl } from '../../helper/helper'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'; 


import $ from 'jquery';
import 'parsleyjs';
import { AppContext } from '../../globleDataComponent/contaxtData';


const UserSignUp = () => {

    const { globalData, setGlobalData } = useContext(AppContext);

    const formRef = useRef(null); 
    
 
    useEffect(() => {
        
        if (formRef.current) {
          $(formRef.current).parsley();
        }
    
        // Clean up Parsley on form unmount
        return () => {
          if (formRef.current) {
            $(formRef.current).parsley().destroy();
          }
        };
      }, []);





    const [signUpData, setSignUpData] = useState({
        first_name : '',
        last_name : '',
        mobile : '',
        email : '',
        company_name : '',
        password : '',
        confirm_password : '',
        term_n_condition : false,
    });

    const submitSignUpForm=(e)=>{
        e.preventDefault();
        console.log('submit ');
    }

    const setUserData =(e)=>{

        const { name, value } = e.target;

        if(name === "term_n_condition"){
            const { checked } = e.target; 
            if(checked){
                setSignUpData({ 
                    ...signUpData,
                    [name]: true
                });
            }else{
                setSignUpData({ 
                    ...signUpData,
                    [name]: false
                });
            }
            return 1;
        }
        setSignUpData({ 
            ...signUpData,
            [name]: value
        });

        console.log(signUpData);
    }

    const slider = {
        loop: true,
        margin: 10,
        nav: true,
        navText: [
            '<i className="fa-solid fa-arrow-left"></i>',
            '<i className="fa-solid fa-arrow-right"></i>'
        ],
        dots: false,
        responsive: {
            0: {
                items: 3
            },
            600: {
                items: 5
            },
            1000: {
                items: 8
            }
        }
    };
  return (
    <div className='crm_body_bg'>

        <section className="position-relative">

        <div className="site-width">
            <div className="headerSignup">
                    <div className="logoSec">
                        <Link to="/"><img src={`${baseUrl}front/img/logo-.png`} alt="logo" /></Link>
                    </div>
                </div> 
            </div>


            <div className="bnrForm pt-50 pb-50">
            <div className="site-width">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="bnrContent">
                            <h4>Maximise Your <span>Money</span>, Fuel Your D2C <span>Business Growth</span>!</h4>
                            <ul className="someFeaturs">
                                <li>
                                    <div className="bnrico">
                                        <img src={`${baseUrl}front/img/icon/Inventory-Exposure.png`} alt="icon" />
                                    </div>
                                    <h5>Inventory Exposure</h5>
                                </li>
                                <li>
                                    <div className="bnrico">
                                        <img src={`${baseUrl}front/img/icon/Maximum-Quality-Assurance.png`} alt="icon" />
                                    </div>
                                    <h5>Maximum Quality Assurance</h5>
                                </li>
                                <li>
                                    <div className="bnrico">
                                        <img src={`${baseUrl}front/img/icon/Zero-Hidden-Charges.png`} alt="icon" />
                                    </div>
                                    <h5>Zero Hidden Charges</h5>
                                </li>
                                <li>
                                    <div className="bnrico">
                                        <img src={`${baseUrl}front/img/icon/Great-Profit-Margin.png`} alt="icon" />
                                    </div>
                                    <h5>Great Profit Margin</h5>
                                </li>
                                <li>
                                    <div className="bnrico">
                                        <img src={`${baseUrl}front/img/icon/End-to-End-Logistics-Handling.png`} alt="icon"/>
                                    </div>
                                    <h5>End-to-End Logistics Handling</h5>
                                </li>
                            </ul>
                            <div className="bnrLogoSliderWrap">
                                <OwlCarousel className="bnrLogoSlider owl-carousel owl-theme" {...slider}>
                                    <div className="item">
                                        <div className="bnrLogoCard">
                                            <img src={`${baseUrl}front/img/clients/img1.jpg`} alt="img" />
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                                <div className="col-md-6">
                                    <div className="bannerForm">
                                        <h4>Master Global eCommerce Success!</h4>
                                        <form className="form signupForm" id="user-sign-form" onSubmit={(e)=>submitSignUpForm(e)}
                                            ref={formRef}
                                            >
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>
                                                            <input className="input" type="text" placeholder="" name="first_name" id="first_name" 
                                                            value={signUpData.first_name} onChange={(e)=>setUserData(e)} required />
                                                            <span><span>First Name *</span></span>
                                                             
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>
                                                            <input className="input" type="text" placeholder="" name="last_name" id="last_name" 
                                                            value={signUpData.last_name} onChange={(e)=>setUserData(e)} required />
                                                            <span><span>Last Name *</span></span>
                                                             
                                                        </label>
                                                    </div>
                                                </div>
                                            
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>
                                                            <input className="input" type="text" placeholder="" name="mobile" id="mobile"
                                                            value={signUpData.mobile} onChange={(e)=>setUserData(e)} required />
                                                            <span><span>Contact No. *</span></span>
                                                            
                                                        </label>
                                                    </div>
                                                </div>
                                            
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>
                                                            <input className="input" type="text" placeholder="" name="email" id="email" 
                                                            value={signUpData.email} onChange={(e)=>setUserData(e)} required />
                                                            <span><span>Email ID *</span></span>
                                                            
                                                        </label>
                                                    </div>
                                                </div>
                                            
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>
                                                            <input className="input" type="text" placeholder="" name="company_name" id="company_name"
                                                            value={signUpData.company_name} onChange={(e)=>setUserData(e)} required />
                                                            <span><span>Company Name *</span></span> 
                                                        </label>
                                                    </div>
                                                </div>
                                            
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>
                                                            <input className="input" type="password" placeholder="" name="password" id="password"
                                                            value={signUpData.password} onChange={(e)=>setUserData(e)} required />
                                                            <span><span>Password *</span></span>
                                                             
                                                        </label>
                                                    </div>
                                                </div>
                                            
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>
                                                            <input className="input" type="password" placeholder="" name="confirm_password" id="confirm_password" 
                                                            value={signUpData.confirm_password} onChange={(e)=>setUserData(e)} required />
                                                            <span><span>Comfirm Password *</span></span>
                                                            
                                                        </label>
                                                    </div>
                                                </div>
                                            
                                                <div className="col-md-12">
                                                    <div className="form-group ">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="term_n_condition" onClick={(e)=>setUserData(e)} id="flexCheckDefault" required />
                                                            <label className="form-check-label" for="flexCheckDefault">
                                                                By submitting this form, you agree to Routeway's user Privacy 
                                                                Statement.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                            <div className="defaultBtn willFullSize mt-3">
                                                <button href="tel:786-245-5125" className="">
                                                    <span className="headerCareer">Sign Up</span>
                                                </button>
                                            </div>
                                            
                                            <div className="formFooter text-center">
                                                <p>Already have an account? <Link to="/login">Login Now</Link></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
{/* ===================================================================================== */}

<div className="whyChoose pt-50 pb-50">
            <div className="site-width">
                <div className="titleSection">
                    <div className="text-center">
                        <h2>
                            Why Routeway?
                        </h2>
                        <h4>
                            Empower Your D2C Empire with the Premier Solution for Bulk Import, 4 Sourcing and Dropshipping Needs
                        </h4>
                        <span className="divider">
                            <img src={`${baseUrl}front/img/underline.png`} alt="img" />
                        </span>
                    </div>
                </div>

                <div className="serviceRow">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="serviceCard">
                                <div className="serviceIco">
                                    <img src={`${baseUrl}front/img/icon/whyicon1.png`} alt="icon" />
                                </div>
                                <div className="serviceText">
                                    <h4>Efficient Fulfillment</h4>
                                    <p>Achieve high fulfilment rates with our rigorous end-to-end quality control and diverse multi-courier shipping options for swift order processing</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="serviceCard">
                                <div className="serviceIco">
                                    <img src={`${baseUrl}front/img/icon/whyicon2.png`} alt="icon" />
                                </div>
                                <div className="serviceText">
                                    <h4>Seamless Operations</h4>
                                    <p>Ensure product quality with pre-catalogue samples and enjoy a rapid and transparent COD remittance cycle.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="serviceCard">
                                <div className="serviceIco">
                                    <img src={`${baseUrl}front/img/icon/whyicon3.png`} alt="icon" />
                                </div>
                                <div className="serviceText">
                                    <h4>Comprehensive Support</h4>
                                    <p>Access real-time order visibility with our live shipment tracking feature and benefit from round-the-clock support to maximize your success.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* =================================================================================== */}

        <div className="dropCrossBorder pt-50 pb-50">
            <div className="site-width">
                <div className="dcbContainer">
                    <div className="row ">
                        <div className="col-md-12">
                          <ul className="nav nav-pills">
                            <li className="nav-item">
                              <a className="nav-link active" data-toggle="pill" href="#dropshipping" role="tab" aria-controls="pills-dropshipping" aria-selected="true">Dropshipping</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-toggle="pill" href="#crossborder" role="tab" aria-controls="pills-crossborder" aria-selected="false">Crossborder</a>
                            </li>
                          </ul>
                          <div className="tab-content mt-3">
                          <div className="tab-pane fade show active" id="dropshipping" role="tabpanel" aria-labelledby="dropshipping-tab">
                            <div className="dropparaWrapper">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <div className="textContainer">
                                            <h4 className="normalTitle">
                                                Unlock Instant Growth with <span>Dropshipping</span> Mastery!
                                            </h4>
                                            <ul className="someFeaturs withSomePara">
                                                <li>
                                                    <div className="bnrico">
                                                        <img src={`${baseUrl}front/img/icon/Inventory-Exposure.png`} alt="icon" />
                                                    </div>
                                                    <div className="para">
                                                        <h5>Explore Best-Selling Products</h5>
                                                        <p>
                                                            Discover millions of in-demand items sourced directly from manufacturers. Sell worry-free with no inventory storage costs.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="bnrico">
                                                        <img src={`${baseUrl}front/img/icon/Inventory-Exposure.png`} alt="icon" />
                                                    </div>
                                                    <div className="para">
                                                        <h5>Maximize Your Margins</h5>
                                                        <p>
                                                            Boost profits with generous margins on wholesale prices.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="bnrico">
                                                        <img src={`${baseUrl}front/img/icon/Inventory-Exposure.png`} alt="icon" />
                                                    </div>
                                                    <div className="para">
                                                        <h5>Hassle-Free Delivery</h5>
                                                        <p>
                                                            Ensure seamless order fulfillment with direct shipping to your customers.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="bnrico">
                                                        <img src={`${baseUrl}front/img/icon/Inventory-Exposure.png`} alt="icon" />
                                                    </div>
                                                    <div className="para">
                                                        <h5>Effortless Store Integration</h5>
                                                        <p>
                                                            Easily import products to your store using our one-click 
                                                            API integration.
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="imgFull">
                                            <img src={`${baseUrl}front/img/drop-img.png`} alt="img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="crossborder" role="tabpanel" aria-labelledby="profile-tab">
                           
                            <div className="dropparaWrapper">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <div className="textContainer">
                                            <h4 className="normalTitle">
                                                Unlock Instant Growth with <span>Dropshipping</span> Mastery!
                                            </h4>
                                            <ul className="someFeaturs withSomePara">
                                                <li>
                                                    <div className="bnrico">
                                                        <img src={`${baseUrl}front/img/icon/Inventory-Exposure.png`} alt="icon" />
                                                    </div>
                                                    <div className="para">
                                                        <h5>Explore Best-Selling Products</h5>
                                                        <p>
                                                            Discover millions of in-demand items sourced directly from manufacturers. Sell worry-free with no inventory storage costs.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="bnrico">
                                                        <img src={`${baseUrl}front/img/icon/Inventory-Exposure.png`} alt="icon" />
                                                    </div>
                                                    <div className="para">
                                                        <h5>Maximize Your Margins</h5>
                                                        <p>
                                                            Boost profits with generous margins on wholesale prices.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="bnrico">
                                                        <img src={`${baseUrl}front/img/icon/Inventory-Exposure.png`} alt="icon" />
                                                    </div>
                                                    <div className="para">
                                                        <h5>Hassle-Free Delivery</h5>
                                                        <p>
                                                            Ensure seamless order fulfillment with direct shipping to your customers.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="bnrico">
                                                        <img src={`${baseUrl}front/img/icon/Inventory-Exposure.png`} alt="icon" />
                                                    </div>
                                                    <div className="para">
                                                        <h5>Effortless Store Integration</h5>
                                                        <p>
                                                            Easily import products to your store using our one-click 
                                                            API integration.
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="imgFull">
                                            <img src={`${baseUrl}front/img/drop-img.png`} alt="img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                          </div>
                         
                        </div>        
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
        
        {/* =================================================================================== */}
        
        
        <div className="integrationSec pt-50 pb-50">
            <div className="site-width">
                <div className="titleSection">
                    <div className="text-center">
                        <h2>
                            Multi Channel Integrations  
                        </h2>
                        <h4>
                            Discover SourceInfi's seamless integrations with all your favorite D2C platforms
                        </h4>
                        <span className="divider">
                            <img src={`${baseUrl}front/img/underline.png`} alt="img" />
                        </span>
                    </div>
                </div>
                <div className="integrationWrapper">
                    <div className="integrationCard">
                        <img src={`${baseUrl}front/img/integration-logo1.png`} alt="img" />
                    </div>
                    <div className="integrationCard">
                        <img src={`${baseUrl}front/img/integration-logo3.png`} alt="img" />
                    </div>
                    <div className="integrationCard">
                        <img src={`${baseUrl}front/img/integration-logo2.png`} alt="img" />
                    </div>
                </div>
            </div>
        </div>
        
        {/* =================================================================================== */}
        
        <div className="successStorires pt-50 pb-50">
            <div className="site-width">
                <div className="titleSection">
                    <div className="text-center">
                        <h2>
                            Success Stories That Inspire
                        </h2>
                        <h4>
                            Discover the journeys of our thriving dropshipping community and see how Routeway has transformed their businesses.
                        </h4>
                        <span className="divider">
                            <img src={`${baseUrl}front/img/underline.png`} alt="img" />
                        </span>
                    </div>
                </div>
                <div className="successStories">
                    <div className="successSlider owl-carousel owl-theme">
                        <div className="item">
                            <div className="sucessStoriesCard">
                                <div className="row align-items-center">
                                    <div className="col-md-3">
                                        <div className="succImg">
                                            <img src={`${baseUrl}front/img/success-stories/img1.png`} alt="img" />
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="succPara">
                                            <p>
                                                Their dropshipping service has saved us time and money, allowing us to focus on growing our brand. Such seamless order processing. The best part is the quality of the imported product. I truly appreciate their commitment to making the entire shipping process easy and de-cluttered!"
                                            </p>
                                            <div className="succAuthor">
                                                <h4>Founder of Routeway's and Co.</h4>
                                                <h5>Rachna Parmar</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer_part normal">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="footer_iner text-left">
                            <p>2024 © Routeway | All Rights Reserved</p>
                        </div>
                    </div>
                    <div className="col-md-6 aks-logo">
                        <div className="ltn__copyright-menu text-end">
                            <a href="https://www.akswebsoft.com" title="AKS Websoft Consulting Pvt. Ltd." target="_blank"> <img src={`${baseUrl}front/img/aks.png`} alt="AKS Websoft Consulting Pvt. Ltd." /></a>
                          </div>
                    </div>
                </div>
            </div>
        </div>
        {/* =================================================================================== */}

        </section>
      
    </div>
  )
}

export default UserSignUp
