import { httpClient } from "../../helper/helper"




export const loginUser = async (data)=>{

    const result = await httpClient.get('/api/admin',data).then(response =>response.data);
    return result;

}