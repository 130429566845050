import React from 'react'
import { Link } from 'react-router-dom'

export default function HomeCategory({category}) {
  return (
    <>
       <div className="item">
                      <Link to={`/category/${category.slug}`}>
                        <div className="categoryCard">
                          <div className="cateImg">
                            <img src={category.Images} alt="front/img/" />
                          </div>
                          <div className="cateTxt">
                            <h5>{category.CATEGORYNAME}</h5>
                          </div>
                        </div>
                      </Link>
                    </div>
    </>
  )
}
