import React, { useContext, useEffect } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { baseUrl } from '../../helper/helper'
import SettingCart from '../../components/settingComponents/settingCart'
import { settings } from '../../Http/data/frontData'
import { AppContext } from '../../globleDataComponent/contaxtData'

const Setting = () => {
  const { globalData, setGlobalData } = useContext(AppContext);

    
    
  return (
    <>
      <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
      <Header />
      <div className="main_content_iner overly_inner"> 
            <div className="container-fluid p-0 ">
                    <div className="row">

                    <div className="col-md-12">
                            <div className="user_crm_wrapper">
                                <div className="row">

                                    {settings.map((item)=>(
                                        <SettingCart key={item.id} data={item}/> 
                                    ))}
                                 
                                </div>
                            </div>
                            </div>

                    </div> 
            </div>
        </div>

        <Footer />
        </section>
    </>
  )
}

export default Setting
