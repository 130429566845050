

export const reportTab =[
    {
        title:"Custom Reports",
        url:'/custom-reports', 
    },
    {
        title:"Shipment Summary",
        url:'/shipment-summary', 
    },
    {
        title:"Top NDR Reasons",
        url:'/top-ndr-reasons', 
    },
    {
        title:"Daily Summary",
        url:'/daily-summary', 
    },

    {
        title:"State Wise Summary",
        url:'/state-wise-summary', 
    },
    {
        title:"Product Wise Summary",
        url:'/product-wise-summary', 
    }

]