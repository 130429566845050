import React, { useEffect, useState } from 'react'
import 'metismenu/dist/metisMenu.min.css';

const OrderStatusFilter = ({setDataFun, filterData, resetFilter, filterProductData}) => {

    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
      setIsCollapsed(!isCollapsed);
    };

     
    useEffect(()=>{
            const $ = window.$; // Access jQuery from the global window object
                if ($ && $.fn.niceSelect) {
                $('.nice_Select').niceSelect();

                $('.nice_Select').on('change', function (e) {
                    setDataFun(e);
                  });
                return ()=>{
                    $('.nice_Select').niceSelect('destroy');
                } 
                } else {
                console.error('jQuery or niceSelect is not available');
                }
        },[])


        useEffect(() => {
            const $ = window.$; // Access jQuery from the global window object
            if ($ && $.fn.datepicker) {
              // Initialize datepicker
              $('.datepicker-here1').datepicker({
                range: true,
                multipleDatesSeparator: ' - ',
                language: 'ru',
                onSelect: (formattedDate, date, inst) => {
                    setDataFun(formattedDate);
                },
              });
        
              // Cleanup function on component unmount
              return () => {
                $('.datepicker-here1').datepicker('destroy'); // Destroy datepicker instance
              };
            } else {
              console.error('jQuery or datepicker is not available');
            }
          }, [filterData]);

        
 
        
  return (
    <>
      
      <div className="col-lg-12">
                        <div className="white_card card_height_100 mb_30 filterCard">
                            <div className="white_card_header carHeadertwo">
                                <div className="m-0 box_header">
                                    <div className="main-title">
                                        <h3 className="m-0">Filters</h3>
                                        <button className="collapsed"aria-expanded={!isCollapsed}  
                                         onClick={handleToggle}>
                                            <i className="fa-solid fa-filter"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className={`white_card_body collapse ${!isCollapsed?'show':''}`} id="filter" aria-labelledby="headingOne"
                                data-parent="#accordion">
                               <form action='#' onSubmit={(e)=>filterProductData(e)} >
                                        
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label for="ProductName">Filter by</label>
                                            <select className="nice_Select wide" name='filter_by'>
                                                <option value="">-- Select --</option>
                                                <option value="By Current Status">By Current Status</option>
                                                <option value="By Milestone Action Date">By Milestone Action Date</option>
                                            </select>
                                        </div>
                                    </div>
                                 
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label for="PaymentMethod">Select date</label>
                                            <input className="form-control datepicker-here1 digits" type="text" data-range="true" data-multiple-dates-separator=" - " data-language="ru" 
                                            value={filterData.date} name='date' onChange={(e)=>setDataFun(e)}/>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label for="PaymentMethod">Milestone</label>
                                            <select className="form-control" id="PaymentMethod"  name='status' onChange={(e)=>setDataFun(e)}>
                                                <option value="">Select</option>
                                                <optgroup value="" label="Before Shipping Process">
                                                    <option value="0">1. New Order </option>
                                                    <option value="-1">2. False / Cancel Order </option>
                                                    <option value="1">3. Confirm Order </option>
                                                </optgroup>
                                                <optgroup value="" label="After Shipping Process">
                                                    <option value="3,4,5,6">1. Ready to Ship </option>
                                                    <option value="7">2. Ready to pickup</option>
                                                    <option value="8">3. In Transit</option>
                                                    <option value="9">4. Out for delivery</option>
                                                    <option value="10">5. Delivered</option>
                                                    <option value="11">6. NDR</option>
                                                    <option value="12,13,14">7. RTO</option>
                                                    <option value="2">8. Cancel</option>
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                
                                    <div className="col-md-3">
                                        <label for="PaymentMethod"
                                            style={{display: 'block', visibility: 'hidden'}}>Date</label>
                                            <button type="button" className="btn btn-secondary" onClick={()=>resetFilter()}>Reset</button>
                                            <button type="submit" className="btn btn-primary">Apply</button>
                                            <button type="button" className="btn btn-warning" style={{marginLeft:'8px'}}>Export</button>

                                    </div>
                                </div>
                                
                                </form>
                                {/* {JSON.stringify(filterData)} */}
                            </div>
                        </div>
                    </div>

    </>
  )
}

export default OrderStatusFilter
