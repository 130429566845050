import React, { useContext } from 'react'

import Header from '../../components/header'
import Footer from '../../components/footer'
import { InnerSettingsTab, settings } from '../../Http/data/frontData'
import SettingCart from '../../components/settingComponents/settingCart'
import { AppContext } from '../../globleDataComponent/contaxtData'

const AccountSetting = () => {
  const { globalData, setGlobalData } = useContext(AppContext);

  return (
    <>
      
      <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
      <Header />
      <div className="main_content_iner overly_inner"> 
            <div className="container-fluid p-0 ">
                    <div className="row">

                    <div className="col-md-12">
                            <div className="user_crm_wrapper">
                                <div className="row">

                                {InnerSettingsTab.map((item)=>(
                                        <SettingCart key={item.id} data={item}/> 
                                    ))}
                                 
                                </div>
                            </div>
                            </div>

                    </div> 

                 {/* =========================================start Change Password============================================= */}
                 
                 <div className="col-lg-12">
                    <div className="white_card card_height_100 mb_20 ">
                      <div className="white_card_header">
                        <div className="box_header m-0">
                          <div className="main-title">
                            <h3 className="m-0">Change Password</h3>
                          </div>
                        </div>
                      </div>
                      <div className="white_card_body QA_section">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="PaymentMethod">Old Password *</label>
                              <input className="form-control" type="text" defaultValue="" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="PaymentMethod">New Password *</label>
                              <input className="form-control" type="text" defaultValue="" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="PaymentMethod">Confirm Password *</label>
                              <input className="form-control" type="text" defaultValue="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                 {/* =========================================End Change Password============================================= */}

            </div>
            <div className="col-md-3">
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>
        </div>

        <Footer />
        </section>

    </>
  )
}

export default AccountSetting
