import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";
import { baseUrl } from "../helper/helper";
import $ from 'jquery';
import PushedProduct from "../components/manageProductComponents/pushedProduct";
import InventoryRequest from "../components/manageProductComponents/inventoryRequest";
 

import 'nice-select2/dist/css/nice-select2.css';
import NiceSelect from 'nice-select2';
import { AppContext } from "../globleDataComponent/contaxtData";

const ManageProducts = () => {
  const { globalData, setGlobalData } = useContext(AppContext);

    const [filterOpen, setFilterOpen] = useState(false)
    const [openTab, setOpenTab] = useState('pushedToShopify');

    const initializedRef = useRef(false); 
    useEffect(() => {
        if ($('.nice_Select').length && !initializedRef.current) {
          $('.nice_Select').each(function () {
            new NiceSelect(this);
          });
          initializedRef.current = true;
        }
      }, []);

    const tableData = {
        bLengthChange: false,
        bDestroy: true,
        language: {
          search: "<i class='ti-search'></i>",
          searchPlaceholder: 'Quick Search',
          paginate: {
            next: "<i class='ti-arrow-right'></i>",
            previous: "<i class='ti-arrow-left'></i>",
          },
        },
        columnDefs: [
          { visible: false },
        ],
        responsive: true,
        searching: true,
      }

    useEffect(() => {
        if (window.$ && window.$.fn.DataTable) {
            const table = window.$('#manage_product_table1').DataTable(tableData);
            const table2 = window.$('#manage_product_table2').DataTable(tableData);

            // Cleanup on component unmount
            //   return () => {
            //     if (window.$.fn.DataTable.isDataTable('#DataTables_Table_2')) {
            //       table.destroy(true);
            //     }
            //   };
        }
    }, []);



  return (
    <div>
      <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
        <Header />

        <div className="main_content_iner overly_inner ">
          <div className="container-fluid p-0 ">
            <div className="row">
              <div className="col-lg-12">
                <div className="white_card card_height_100 mb_30 filterCard">
                  <div className="white_card_header carHeadertwo">
                    <div className="box_header m-0">
                      <div className="main-title">
                        <h3 className="m-0">Filters</h3>
                        <button
                          className="collapsed"
                        //   data-toggle="collapse"
                        //   data-target="#filter"
                        //   aria-expanded="false"
                        //   aria-controls="filter"
                        onClick={()=>setFilterOpen(!filterOpen)}
                        >
                          <i className="fa-solid fa-filter" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`white_card_body collapse ${filterOpen?"show":''}`}
                    id="filter"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">Product Name</label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">C-Code</label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="PaymentMethod">Date</label>
                          <input
                            className="form-control datepicker-here  digits"
                            type="text"
                            data-range="true"
                            data-multiple-dates-separator=" - "
                            data-language="en"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="storeSelect">Store</label>
                          <select className="nice_Select wide" id="storeSelect">
                            <option>-- Select --</option>
                            <option value={1}>RouteWay</option>
                            <option value={2}>Shopify</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="PaymentMethod"
                          style={{ display: "block", visibility: "hidden" }}
                        >
                          Date
                        </label>
                        <button type="button" className="btn btn-secondary">
                          Reset
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="white_card card_height_100 mb_20 ">
                      <div className="white_card_header">
                        <div className="box_header m-0">
                          <div className="main-title">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <select
                                    className="nice_Select wide"
                                    id="storeSelect"
                                  >
                                    <option value={1}>RouteWay</option>
                                    <option value={2}>Shopify</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="header_more_tool">
                            <div className="dropdown">
                              <span
                                className="dropdown-toggle"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                              >
                                <i className="ti-more-alt" />
                              </span>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a className="dropdown-item" href="#">
                                  <i className="ti-trash" />
                                  Delete
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="ti-printer" />
                                  Print
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-download" /> Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                         
                      </div>
                      <div className="white_card_body QA_section orderList manageList">
                        <div className="bulder_tab_wrapper">
                          <ul className="nav" id="myTab" role="tablist">
                            <li className="nav-item">
                              <a
                                className={`nav-link ${openTab==="pushedToShopify"?"active":''}`}
                                id="newOrderTab"
                                data-toggle="tab"
                                href="#pushedToShopify"
                                // role="tab"
                                // aria-controls="pushedToShopify"
                                // aria-selected="true"
                                onClick={()=>setOpenTab('pushedToShopify')}
                              >
                                <i className="fa-solid fa-cart-plus" /> Pushed
                                To Shopify
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={`nav-link ${openTab==="inventoryRequest"?"active":''}`}
                                id="inventoryRequestTab"
                                data-toggle="tab"
                                href="#inventoryRequest"
                                // role="tab"
                                // aria-controls="inventoryRequest"
                                // aria-selected="true"
                                onClick={()=>setOpenTab('inventoryRequest')}

                              >
                                <i className="fa-solid fa-circle-exclamation" />
                                Inventory Requests
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="tab-content mt-3" id="myTabContent">
                          {/* Pushed products */}
                          <PushedProduct openTab={openTab} />
                          {/* inventory Request */}
                          <InventoryRequest openTab={openTab} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>

      {/* <HiddenCompnents pushData={pushData} setPushDataFun={setPushDataFun}  setVariantPushDataFun={setVariantPushDataFun} /> */}
    </div>
  );
};

export default ManageProducts;
