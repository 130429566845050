import React, {useEffect} from 'react'
import $ from 'jquery';


const OrderStatusByState = () => {
    useEffect(() => {
        if (window.$ && window.$.fn.DataTable) {
            const table = window.$('#DataTables_Table_2').DataTable({
                bLengthChange: false,
                bDestroy: true,
                language: {
                  search: "<i class='ti-search'></i>",
                  searchPlaceholder: 'Quick Search',
                  paginate: {
                    next: "<i class='ti-arrow-right'></i>",
                    previous: "<i class='ti-arrow-left'></i>",
                  },
                },
                columnDefs: [
                  { visible: false },
                ],
                responsive: true,
                searching: false,
              });

            // Cleanup on component unmount
            //   return () => {
            //     if (window.$.fn.DataTable.isDataTable('#DataTables_Table_2')) {
            //       table.destroy(true);
            //     }
            //   };
        }
    }, []);
  return (
    <div className="col-lg-6">
              <div className="white_card card_height_100 mb_20 ">
                <div className="white_card_header">
                  <div className="box_header m-0">
                    <div className="main-title">
                      <h3 className="m-0">Order Status By State</h3>
                    </div>
                    <div className="header_more_tool">
                      <div className="dropdown"> <span className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown"> <i className="ti-more-alt"></i> </span>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton"> <a className="dropdown-item" href="#"> <i className="ti-trash"></i> Delete</a> <a className="dropdown-item" href="#"> <i className="ti-printer"></i> Print</a> <a className="dropdown-item" href="#"> <i className="fa fa-download"></i> Download</a> </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="white_card_body QA_section">
                  <div className="QA_table ">
                    <div id="" className="dataTables_wrapper no-footer">
                      <table className="table tableDatatotal lms_table_active p-0 collapsed StatusByStateTable normalTable"
                                                    id="DataTables_Table_2">
                        <thead>
                          <tr role="row">
                            <th>State</th>
                            <th>Order Share</th>
                            <th>Delivered</th>
                            <th>RTO</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" className="odd">
                            <td tabindex="0" className="sorting_1">Uttar Pradesh</td>
                            <td className="color_text_6">16%</td>
                            <td className="color_text_6">32%</td>
                            <td className="color_text_6">66%</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td tabindex="0" className="sorting_1">Maharashtra</td>
                            <td className="color_text_6">12%</td>
                            <td className="color_text_6">32%</td>
                            <td className="color_text_6">66%</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td tabindex="0" className="sorting_1">Punjab</td>
                            <td className="color_text_6">17%</td>
                            <td className="color_text_6">32%</td>
                            <td className="color_text_6">66%</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td tabindex="0" className="sorting_1">Gujrat</td>
                            <td className="color_text_6">16%</td>
                            <td className="color_text_6">32%</td>
                            <td className="color_text_6">66%</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td tabindex="0" className="sorting_1">Tamil Nadu</td>
                            <td className="color_text_6">13%</td>
                            <td className="color_text_6">32%</td>
                            <td className="color_text_6">66%</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td tabindex="0" className="sorting_1">Uttar Pradesh</td>
                            <td className="color_text_6">15%</td>
                            <td className="color_text_6">32%</td>
                            <td className="color_text_6">66%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  )
}

export default OrderStatusByState
