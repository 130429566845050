 
import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import $ from 'jquery'
const OrderStatuschart = () => {

  
  const chartRef = useRef(null);
  let chartInstance = useRef(null);


  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    ctx.height = 100;
    if (chartInstance.current) {
        chartInstance.current.destroy(); // Clean up the old chart instance if it exists
    }

    chartInstance.current =  
    new Chart(ctx, {
      type: 'pie',
      data: {
          defaultFontFamily: 'Poppins',
          datasets: [{
              data: [33, 65, 1],  // Use numeric values, not percentage strings
              backgroundColor: ["#b80093", "#6600b5", "#b8001e"],
              hoverBackgroundColor: ["#98007a", "#530093" , "#970019"]
          }],
          labels: ["Delivered", "RTO", "Returned"]
      },
      options: {
          responsive: true,
          tooltips: {
              callbacks: {
                  label: function (tooltipItem, data) {
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var total = dataset.data.reduce(function (previousValue, currentValue) {
                          return previousValue + currentValue;
                      });
                      var currentValue = dataset.data[tooltipItem.index];
                      var percentage = Math.floor(((currentValue / total) * 100) + 0.5); // Calculate percentage
                      return data.labels[tooltipItem.index] + ': ' + percentage + '%';
                  }
              }
          },
          plugins: {
              datalabels: {
                  formatter: (value, ctx) => {
                      let sum = ctx.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
                      let percentage = (value * 100 / sum).toFixed(2) + "%";
                      return percentage;
                  },
                  color: '#fff'
              }
          }
      }
  });


    // Cleanup on component unmount
    return () => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
    };
}, []);
  return (
    <div className="col-xl-4">
              <div className="white_card mb_30 pichartitem">
                <div className="white_card_header">
                  <div className="row align-items-center justify-content-between flex-wrap">
                    <div className="col-lg-12">
                      <div className="main-title">
                        <h3 className="">Confirm Orders by Order Status</h3>
                        <h2>11,879</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="white_card_body" style={{ display:'flex', justifyContent:'center' }}>
                  <div style={{height:'250px', width:"100%",display:'flex', justifyContent:'center' ,alignItems:'center'}}>

                  <canvas style={{height: '250px'}} id="pieChartOrderStatus" ref={chartRef}></canvas>
                  </div>
                </div>
              </div>
            </div>
  )
}

export default OrderStatuschart
