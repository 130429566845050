
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const ConfirmOrder = ({activeTab, orders}) => {

    useEffect(() => {
        let table;
    
        if (window.$ && window.$.fn.DataTable) {
          // Initialize DataTable
          table = window.$('#DataTables_Table_2').DataTable();
        }
    
        // Cleanup on component unmount
        return () => {
          if (table && window.$.fn.DataTable.isDataTable('#DataTables_Table_2')) {
            // table.destroy(true);
          }
        };
      }, []); // Empty dependency array to run only on mount/unmount



  return (
    <div style={{display:`${activeTab === 'ordConfirm' ? '' : 'none'}`}}>
      
     
      <table className="table p-0 orderlisttable dataTable lms_table_active collapsed "  id='DataTables_Table_2' >
                                                            <thead>
                                                                <tr role="row">
                                                                    <th>S.no</th>
                                                                   
                                                                    <th style={{minWidth:'200px'}}> Item</th>
                                                                    <th style={{minWidth:'100px'}}> Order no</th>
                                                                    <th>Date</th>
                                                                    <th> Qty</th>
                                                                    <th> Price</th>
                                                                    <th> Margin </th>
                                                                    <th> Customer</th>
                                                                    <th> Mobile</th>
                                                                    <th> Status</th>
                                                                    
                                                                </tr>
                                                             
                                                                </thead>
                                                                <tbody >
        
                            {orders.length>0 && orders.map((order, index)=>(
                                        
                                        
                                        <tr role="row" className="odd" key={order.OID}>
                                                <td>{index+1}</td>
                                                
                                                <td tabindex="0" className="sorting_1">
                                                    {order.ProductName}
                                                </td>
                                                <td className="color_text_6" dangerouslySetInnerHTML={{ __html: order.Order_NO }}></td>
                                                <td className="color_text_6">{`${(new Date(order.Order_Date)).getDate()}/${(new Date(order.Order_Date)).toLocaleString('default', { month: 'short' })}/${(new Date(order.Order_Date)).getFullYear()}`}</td>
                                                <td className="color_text_6">{order.Quantity}</td>
                                                <td className="color_text_6"> {order.SellingPrice}</td>
                                                <td className="color_text_6">{order.Margin}</td>
                                                <td className="color_text_6">{order.Name}</td>
                                                <td className="color_text_6">{order.Mobile}</td>
                                                <td  >
                                                <Link to="#" className="status_btn1"> <span  dangerouslySetInnerHTML={{ __html: order.Statuss }}></span></Link>
                                                </td>
                                                
                                            </tr>
                                ))}
         
         </tbody>
         </table>

    </div>
  )
}

export default ConfirmOrder
