import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const ActionRequired = ({tableTab, ntrActionOrder}) => {

    useEffect(() => {
        let table;
    
        if (window.$ && window.$.fn.DataTable) {
          // Initialize DataTable
          table = window.$('#DataTables_Table_1').DataTable();
        }
    
        // Cleanup on component unmount
        return () => {
          if (table && window.$.fn.DataTable.isDataTable('#DataTables_Table_1')) {
            // table.destroy(true);
          }
        };
      }, []); // Empty dependency array to run only on mount/unmount


  return (
    <div style={{display:`${tableTab==="ActionRequired"?'':'none'}`}}>
      
      <table className="table orderlisttable dataTable lms_table_active p-0 collapsed" id='DataTables_Table_1'>
                                                            <thead>
                                                                <tr role="row">
                                                                    <th>S.no</th>
                                                                    <th  >
                                                                        Order no
                                                                    </th>
                                                                    <th >
                                                                    Order Date</th>
                                                                    <th>SKU</th>
                                                                    {/* <th><input type="checkbox" className="formcheck" id="exampleCheck1" /></th> */}
                                                                    <th> Qty</th>
                                                                    <th style={{minWidth:'250px'}}> Product</th>
                                                                    <th>
                                                                        M/SP</th>
                                                                    <th >
                                                                        Customer</th>
                                                                    <th >
                                                                        Mobile</th>
                                                                    <th>
                                                                        AWB</th>
                                                                    
                                                                    <th style={{display:`${tableTab==="ActionRequired"?'':'none'}`}}>
                                                                        Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="tab-pane fade active show"  id="ActionRequired" role="tabpanel" aria-labelledby="ActionRequired-tab">
        
                                                                {ntrActionOrder.length > 0 && ntrActionOrder.map((order, index)=>(

                                                                                <tr role="row" className="odd">
                                                                                <td>{index+1}</td>
                                                                                {/* <td><input type="checkbox" className="formcheck" id="exampleCheck1" /></td> */}
                                                                                <td tabindex="0" className="sorting_1">
                                                                                    {order.Order_No}
                                                                                </td>
                                                                                <td className="color_text_6">{`${(new Date(order.Order_Date).getDate())}/${(new Date(order.Order_Date).toLocaleString('default',{month:'long'}))}/${(new Date(order.Order_Date).getFullYear())}`}</td>
                                                                                <td className="color_text_6">{order.SKU}</td>
                                                                                <td className="color_text_6">1</td>
                                                                                <td className=""> 
                                                                                    <div  >
                                                                                    <img src={order.ProfileImage} width={60} />
                                                                                <div> {order.ProductName} </div>
                                                                                </div></td>
                                                                                <td className="color_text_6">{}</td>
                                                                                <td className="color_text_6">{order.Shi_Add_Name}</td>
                                                                                <td className="color_text_6">{order.Shi_Add_Phone}</td>
                                                                                <td className="color_text_6">{order.Awb_Code}</td>
                                                                                {/* <td><a href="#" className="status_btn">Active</a></td> */}
                                                                                <td className="color_text_6">
                                                                                    <div className="action_btns d-flex">
                                                                                        <Link href="#" className="action_btn mr_10"> <i
                                                                                                className="far fa-edit"></i> </Link>
                                                                                        <Link href="#" className="action_btn"> <i
                                                                                                className="fas fa-trash"></i> </Link>
                                                                                    </div>
                                                                                </td>
                                                                                </tr>  
                                                                ))}
                                                                   
                                                            </tbody> 
                                                             
                                                        </table>
    </div>
  )
}

export default ActionRequired
