import React, { useEffect } from 'react'
import { baseUrl } from '../../helper/helper'
import { Link, useLocation } from 'react-router-dom';

const SettingCart = ({key, data}) => {
  useEffect(()=>{
    console.log(key, data)
  });

  const location = useLocation();

  return (
    <>
             <div className={data.size_class}>
                                    <Link to={data.url}>
                                    <div className={`dashdataCard settingCard mb-3 d-flex align-items-center justify-content-between ${data.url ===location.pathname?'active':''}`}>
                                        <div className="plan_left d-flex align-items-center">
                                        <div className="thumb">
                                            <img src={`${baseUrl}${data.logo}`} alt="icon" />
                                        </div>
                                        <div>
                                            <h5>{data.name}</h5>
                                        </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
    </>
  )
}

export default SettingCart
