import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const LineChart = () => {
  const chartRef = useRef(null);  // Reference to the canvas
  const chartInstance = useRef(null);  // Reference to the Chart instance

  useEffect(() => {
    const chLine = chartRef.current;  // Canvas element reference

    if (chartInstance.current) {
      chartInstance.current.destroy();  // Destroy the previous chart instance to prevent errors
    }

    // Chart data
    const chartData = {
      labels: ["Apr 2024", "May 2024", "Jun 2024", "Jul 2024", "Aug 2024"],
      datasets: [
        {
          data: [589, 445, 483, 503, 689],
          backgroundColor: '#e6fdff',
          borderColor: '#007c84',
          borderWidth: 4,
          pointBackgroundColor: '#007c84',
        },
        {
          data: [639, 465, 493, 478, 589, 632, 674],
          backgroundColor: '#f0d7ff',  // Replace 'colors[3]'
          borderColor: '#c678dd',     // Replace 'colors[1]'
          borderWidth: 4,
          pointBackgroundColor: '#c678dd',
        }
      ]
    };

    // Create the chart instance
    chartInstance.current = new Chart(chLine, {
      type: 'line',
      data: chartData,
      options: {
        scales: {
          x: {  // 'xAxes' replaced by 'x' in Chart.js v3+
            ticks: {
              beginAtZero: false
            }
          }
        },
        plugins: {
          legend: {
            display: false  // Hide legend
          }
        },
        responsive: true  // Make chart responsive
      }
    });

    // Cleanup function to destroy chart when component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);  // Empty dependency array to run only once

  return (
    <canvas
      ref={chartRef}
      
     
    ></canvas>
  );
};

export default LineChart;
