import React, { useContext, useEffect, useRef, useState } from 'react'
import Footer from '../components/footer'
import Header from '../components/header'
import ReportHeader from '../components/reportComponents/reportHeader'
import $ from 'jquery'
 
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import 'nice-select2/dist/css/nice-select2.css';
import NiceSelect from 'nice-select2';
import { AppContext } from '../globleDataComponent/contaxtData'

const CustomReports = () => {
    const { globalData, setGlobalData } = useContext(AppContext);

  const datePickerRef = useRef(false);  

  const initializedRef = useRef(false); 

    
  useEffect(() => {
      if ($('.nice_Select').length && !initializedRef.current) {
      $('.nice_Select').each(function () {
          new NiceSelect(this);
      });
      initializedRef.current = true;
      }
  }, []);


    function selectAll(className, e) {
        const { checked } = e.target;
    
        if (checked) {
            $(`.${className}`).each((index, item) => {
                $(item).prop('checked', true); // Use .prop() to set 'checked' attribute
            });
        } else {
            $(`.${className}`).each((index, item) => {
                $(item).prop('checked', false); // Uncheck the boxes
            });
        }

        
    }

    useEffect(() => {
        // Initialize Air Datepicker
        new AirDatepicker(datePickerRef.current, {
          range: true,
          multipleDatesSeparator: ' - ',
          dateFormat: 'MM/dd/yyyy', // Change this format as needed (e.g., 'dd/MM/yyyy')
          language: {
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            months: [
              'January', 'February', 'March', 'April', 'May', 'June',
              'July', 'August', 'September', 'October', 'November', 'December'
            ],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear',
            dateFormat: 'MM/dd/yyyy',
            timeFormat: 'HH:mm',
            firstDay: 0 // Sunday as the first day of the week
          },
          position: 'bottom left', // Adjusts the calendar position
          autoClose: true, // Closes the calendar after selecting a date range
          onSelect: ({ formattedDate }) => {
            console.log('Selected date range:', formattedDate);
          }
        });
      }, []);



    const [filterOpen, setFilterOpen] = useState(false)
    return (
        <div>
            <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
                <Header />

                <div className="main_content_iner overly_inner ">
                    <div className="container-fluid p-0 ">
                        <div className="row">
                            <ReportHeader />
                            <div className="col-lg-12">
                                <div className="white_card card_height_100 mb_30 filterCard">
                                    <div className="white_card_header carHeadertwo">
                                        <div className="box_header m-0">
                                            <div className="main-title">
                                                <h3 className="m-0">Filters</h3>
                                                <button
                                                    className="collapsed"
                                                    //   data-toggle="collapse"
                                                    //   data-target="#filter"
                                                    //   aria-expanded="false"
                                                    //   aria-controls="filter"
                                                    onClick={() => setFilterOpen(!filterOpen)}
                                                >
                                                    <i className="fa-solid fa-filter" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`white_card_body collapse ${filterOpen ? "show" : ''}`}
                                        id="filter"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordion"
                                    >
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label htmlFor="ProductName">Configuration</label>
                                                    <select className="nice_Select wide" id="ProductName">
                                                        <option value="">--select--</option>
                                                        <option value={1}>Shopify</option>
                                                        <option value={2}>RouteWay</option>
                                                        <option value={3}>WordPress</option>
                                                        <option value={4}>Wix</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="PaymentMethod">Date</label>
                                                    <input
                                                        className="form-control datepicker-here  digits"
                                                        type="text"
                                                        ref={datePickerRef}
                                                        placeholder='Select date rang'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <label
                                                    htmlFor="PaymentMethod"
                                                    style={{ display: "block", visibility: "hidden" }}
                                                >
                                                    Date
                                                </label>
                                                <button type="submit" className="btn btn-primary">
                                                    <i className="fa-solid fa-magnifying-glass" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-lg-12">
                                <div className="white_card card_height_100 mb_20 reportWrapper">
                                    {/* 1st */}
                                    <div className="white_card_body QA_section orderList manageList reportCard">
                                        <div className="reportsheader">
                                            <div className="box_header m-0">
                                                <div className="main-title">
                                                    <h4 className="m-0">Orders</h4>
                                                </div>
                                                <div className="form-check " style={{ flex: "auto", marginLeft: 10 }}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id="flexCheckDefault"
                                                         onChange={(e)=>selectAll('order_checkbox',e)}
                                                    />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        Select All
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="customReportWrapper">
                                            <div className="customreportCard">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                       
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        #Number
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                        defaultChecked=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Order Date
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Amount
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Payment Type
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        First Name
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Last Name
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Address 1
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Address 2
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Phone
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        City
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        State
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Pincode
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Length
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Order Status
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Discount Applied
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Product Details
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Order Tags
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input order_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Total Order Qty
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* 2nd */}
                                    <div className="white_card_body QA_section orderList manageList reportCard">
                                        <div className="reportsheader">
                                            <div className="box_header m-0">
                                                <div className="main-title">
                                                    <h4 className="m-0">Shipment</h4>
                                                </div>
                                                <div className="form-check " style={{ flex: "auto", marginLeft: 10 }}>
                                                    <input
                                                        className="form-check-input "
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id="flexCheckDefault"
                                                        onChange={(e)=>selectAll('shipment_checkbox',e)}
                                                    />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        Select All
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="customReportWrapper">
                                            <div className="customreportCard">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input shipment_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Shipment Date
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input shipment_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Shipment Status
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input shipment_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Remittance ID
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input shipment_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Pickup Time
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input shipment_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Delivered Time
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input shipment_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        RTO Delivered Time
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input shipment_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Zone
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input shipment_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Last Status Updated
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input shipment_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Courier Name
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* 3rd */}
                                    <div className="white_card_body QA_section orderList manageList reportCard">
                                        <div className="reportsheader">
                                            <div className="box_header m-0">
                                                <div className="main-title">
                                                    <h4 className="m-0">Product </h4>
                                                </div>
                                                <div className="form-check " style={{ flex: "auto", marginLeft: 10 }}>
                                                    <input
                                                        className="form-check-input "
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id="flexCheckDefault"
                                                        onChange={(e)=>selectAll('product_checkbox',e)}
                                                    />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        Select All
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="customReportWrapper">
                                            <div className="customreportCard">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input product_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Product 1
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input product_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                        defaultChecked=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Product 2
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input product_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Product 3
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input product_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Product 4
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input product_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Product 5
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input product_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Product 6
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input product_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Product 7
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input product_checkbox"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id=""
                                                    />
                                                    <label className="form-check-label" htmlFor="">
                                                        Product 8
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <Footer />
            </section>
        </div>
    )
}

export default CustomReports
