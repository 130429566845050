import React, { useEffect } from 'react'

const InventoryRequest = ({openTab}) => {

    
    const tableData = {
        bLengthChange: false,
        bDestroy: true,
        language: {
          search: "<i class='ti-search'></i>",
          searchPlaceholder: 'Quick Search',
          paginate: {
            next: "<i class='ti-arrow-right'></i>",
            previous: "<i class='ti-arrow-left'></i>",
          },
        },
        columnDefs: [
          { visible: false },
        ],
        responsive: true,
        searching: true,
      }

    useEffect(() => {
        if (window.$ && window.$.fn.DataTable) {
            const table = window.$('#manage_product_table2').DataTable(tableData);
          

            // Cleanup on component unmount
            //   return () => {
            //     if (window.$.fn.DataTable.isDataTable('#DataTables_Table_2')) {
            //       table.destroy(true);
            //     }
            //   };
        }
    }, []);


  return (
    <div
                            className={`tab-pane fade  ${openTab==="inventoryRequest"?"active show":''}`}
                            id="inventoryRequest"
                            role="tabpanel"
                            aria-labelledby="inventoryRequest-tab"
                          >
                            <div className="QA_table">
                              <div className="dataTables_wrapper no-footer">
                                <table className="table orderlisttable dataTable lms_table_active p-0 collapsed " id="manage_product_table2">
                                  <thead>
                                    <tr role="row">
                                      <th
                                        scope="col"
                                        className="sorting_asc"
                                        tabIndex={0}
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-sort="ascending"
                                      >
                                        Request Date
                                      </th>
                                      <th>Product Details</th>
                                      <th>C-Code</th>
                                      <th>Exp. Daily Orders</th>
                                      <th>Exp. Campaign Duration</th>
                                      <th>Total Requested Inventory</th>
                                      <th>Status</th>
                                      <th width="">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody />
                                </table>
                              </div>
                            </div>
                          </div>
  )
}

export default InventoryRequest
