import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
// import $ from 'jquery'
const StateWiseSummaryTable = () => {

    
    useEffect(() => {
        if (window.$ && window.$.fn.DataTable) {
            const table = window.$('#state_wise_table').DataTable({
                bLengthChange: false,
                bDestroy: true,
                language: {
                  search: "<i class='ti-search'></i>",
                  searchPlaceholder: 'Quick Search',
                  paginate: {
                    next: "<i class='ti-arrow-right'></i>",
                    previous: "<i class='ti-arrow-left'></i>",
                  },
                },
                columnDefs: [
                  { visible: false },
                ],
                responsive: true,
                searching: true,
              }); 

            // Cleanup on component unmount
            //   return () => {
            //     if (window.$.fn.DataTable.isDataTable('#DataTables_Table_2')) {
            //       table.destroy(true);
            //     }
            //   };
        }
    }, []);



  return (
    <div className="col-md-12">
  <div className="row">
    <div className="col-lg-12">
      <div className="white_card card_height_100 mb_20 ">
        <div className="white_card_header">
          <div className="box_header m-0">
            <div className="main-title">
              <h3 className="m-0">State Wise Summary</h3>
            </div>
            <div className="header_more_tool">
              <div className="dropdown">
                <span
                  className="dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                >
                  <i className="ti-more-alt" />
                </span>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  <Link className="dropdown-item" to="#">
                    
                    <i className="ti-trash" />
                    Delete
                  </Link>
                  <Link className="dropdown-item" to="#">
                    
                    <i className="ti-printer" />
                    Print
                  </Link>
                  <Link className="dropdown-item" to="#">
                    
                    <i className="fa fa-download" /> Download
                  </Link>
                </div>
              </div>
            </div>
          </div>
           
        </div>
        <div className="white_card_body QA_section orderList manageList">
          <div className="QA_table">
            <div className="dataTables_wrapper no-footer">
              <table className="table orderlisttable dataTable lms_table_active p-0 collapsed " id="state_wise_table">
                <thead>
                  <tr role="row">
                    <th>State</th>
                    <th>Shipment Picked</th>
                    <th>In Transit</th>
                    <th>Exception</th>
                    <th>Deliverd</th>
                    <th>RTO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row" className="odd">
                    <td>Maharashtra</td>
                    <td>
                      53 <i className="fa-solid fa-cloud-arrow-down" />
                    </td>
                    <td>
                      6
                      <span>
                        (11.32%)
                        <Link to="#" className="action_btn mr_10">
                          
                          <i className="fa-solid fa-cloud-arrow-down" />
                        </Link>
                      </span>
                    </td>
                    <td>
                      2
                      <span>
                        (3.77%)
                        <Link to="#" className="action_btn mr_10">
                          
                          <i className="fa-solid fa-cloud-arrow-down" />
                        </Link>
                      </span>
                    </td>
                    <td>
                      10
                      <span>
                        (18.87%)
                        <Link to="#" className="action_btn mr_10">
                          
                          <i className="fa-solid fa-cloud-arrow-down" />
                        </Link>
                      </span>
                    </td>
                    <td>
                      35
                      <span>
                        (66.67%)
                        <Link to="#" className="action_btn mr_10">
                          
                          <i className="fa-solid fa-cloud-arrow-down" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                  <tr role="row" className="odd">
                    <td>Maharashtra</td>
                    <td>
                      53 <i className="fa-solid fa-cloud-arrow-down" />
                    </td>
                    <td>
                      6
                      <span>
                        (11.32%)
                        <Link to="#" className="action_btn mr_10">
                          
                          <i className="fa-solid fa-cloud-arrow-down" />
                        </Link>
                      </span>
                    </td>
                    <td>
                      2
                      <span>
                        (3.77%)
                        <Link to="#" className="action_btn mr_10">
                          
                          <i className="fa-solid fa-cloud-arrow-down" />
                        </Link>
                      </span>
                    </td>
                    <td>
                      10
                      <span>
                        (18.87%)
                        <Link to="#" className="action_btn mr_10">
                          
                          <i className="fa-solid fa-cloud-arrow-down" />
                        </Link>
                      </span>
                    </td>
                    <td>
                      35
                      <span>
                        (66.67%)
                        <Link to="#" className="action_btn mr_10">
                          
                          <i className="fa-solid fa-cloud-arrow-down" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default StateWiseSummaryTable
