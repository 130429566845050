import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { productsData } from '../Products/product';
import { baseUrl, getDiscount, loadScript } from '../helper/helper';
import Header from '../components/header';
import Footer from '../components/footer';
import HiddenCompnents from '../components/hiddenCompnents';
import $ from 'jquery'; 
import ProductDetailsBottomSection from '../components/productDetailsBottomSection';
import { productDeatil } from '../jsonData/productdetails';
import { landingProducts } from '../jsonData/LandigProduct';
import { AppContext } from '../globleDataComponent/contaxtData';
import ViewMoreSection from '../components/analyticComponents/productDetailsConponant/viewMoreSection';
import Calculator from '../components/analyticComponents/productDetailsConponant/calculator';



 

const ProductDetails = () => {

    const currentProductDetails = productDeatil;
    const { globalData, setGlobalData } = useContext(AppContext);

  const {id} = useParams();
  const [product, setProduct] = useState({
    "id":1,
    "SKU": "RW128",
    "Product_ID": 189,
    "CATEGORYNAME": "Beauty & Health",
    "Product_Name": "Eyebrow or Face Hair Removal",
    "CurrentStock": 10,
    "Our_Cost": 60.00,
    "docname": "https://app.routeway.in/doc/product/189_202344080417.jpg",
    "Selected": 0
  });

  useEffect(()=>{
    loadScript('../front/css/client/zoom/zoom/xzoom.min.js');
    loadScript('../front/css/client/zoom/zoom/xzoom.min.js');
    loadScript('../front/css/client/zoom/hammer/jquery.hammer.min.js');
    loadScript('../front/css/client/zoom/magnific/magnific-popup.js');
    loadScript('../front/css/client/zoom/setup.js');
  },[id]);
   
  useEffect(() => { 
        // Open sidebar on click of 'infoSideBarOpener'
        $('.pushtoBtn').click(function() {
          console.log("object Click");
          $('.pushtoSidebar').animate({ right: '0px' }, 300);
      });

      // Close sidebar on click of 'infoSideBarCloser'
      $('.pushtoCloser').click(function() {
          $('.pushtoSidebar').animate({ right: '-100%' }, 300);
      }); 
    }, []);

    useEffect(()=>{ 
      const singleProduct = landingProducts.Product.find((product)=> product.Product_ID === parseInt(id));
      setProduct(singleProduct); 
    },[id]);
 
    
  const [pushData , setPushData] = useState({
    SKU: '',
    Product_ID: 21,
    CATEGORYNAME: '',
    Product_Name: '',
    CurrentStock: 0,
    Our_Cost: 0,
    docname: 0,
    Selected: 0,
    sell_price:0,
    margin :0,
    Varient :[],
    Portal : [],
    pushProductOn:'',
  });

 
  function pushProduct(productData){ 

    const productDetails = productDeatil;
   
    setPushData(
      {
          SKU: productData.SKU,
          Product_ID: productData.Product_ID,
          CATEGORYNAME: productData.CATEGORYNAME,
          Product_Name: productData.Product_Name,
          CurrentStock: productData.CurrentStock,
          Our_Cost: productData.Our_Cost,
          docname: productDetails.Image[0].FileName,
          Selected: productData.Selected ,
          sell_price: productData.Our_Cost,
          margin :0,
          Varient: productDetails.Varient,
          Portal: productDetails.Portal,
          pushProductOn :''
      }
    )
    
  }

const setVariantPushDataFun =(e)=>{
    const { name, value } = e.target;
    setPushData(pushData => (
    {
      
      ...pushData,
      Varient: pushData.Varient.map(variant => 
        variant.Variant_Name === name 
          ? {
              ...variant, 
              sprice: value, 
              margin: value - variant.price  
            }
          : variant
      )
    }));

    console.log(pushData);
  }

  const setPushDataFun=(e)=>{
    const { name, value } = e.target; 
    setPushData({ ...pushData,  [name]: value }); 
  }
  
 


  if (!product) {
    return <div style={{display:'flex', justifyContent:'center'}}>Loading...</div>;
  }


  return (
    <>
   
       
        <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
          
          <Header />
           
          <div className="main_content_iner overly_inner ">
              <div className="container-fluid p-0 ">
                    <div className="row">
                          <div className="col-12">
                              <div className="dashboard_header mb_50">
                                  <div className="row">
                                      <div className="col-lg-6">
                                          <div className="dashboard_header_title">
                                              <h3> Product Details</h3>
                                          </div>
                                      </div>
                                      <div className="col-lg-6">
                                          <div className="dashboard_breadcam text-right">
                                              <p><Link to="/">Home</Link> <i className="fas fa-caret-right"></i> 
                                              <Link to="/category">Category Listing</Link> <i className="fas fa-caret-right"></i> Product Details</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-12">
                              <div className="white_card position-relative mb_20">
                                  <div className="card-body">



                                  <div className="card-body">
                                          <div className="row">
                                              <div className="col-lg-6 col-md-6 col-sm-12">

                                              <div className="row">
                                                <div className="col-md-12">
                                                    <div className="productImgWrap">
                                                        <div className="productMainImg">
                                                            <img className="xzoom5" id="xzoom-magnific" src={currentProductDetails.Image.length && currentProductDetails.Image[0].FileName} style={{width: '509px'}} alt='img' />
                                                        </div>
                                                        <div id="tabbed-content" className="produThumbWrap">
                                                            <div id="tab-1" className="tab-content xzoom-thumbs">
                                                                {currentProductDetails.Image.length && currentProductDetails.Image.map((image)=>(
                                                                    <a href={image.FileName}>
                                                                    <img className="xzoom-gallery5 xactive" width="117" src={image.FileName} title="" alt='img' />
                                                                </a>
                                                                ))}
                                                                {/* <a href={`${baseUrl}front/electric_bike_product/hero-electric-nyx-hx/large/Nyx-gallery-1.jpg`}>
                                                                    <img className="xzoom-gallery5 xactive" width="117" src={`${baseUrl}front/electric_bike_product/hero-electric-nyx-hx/thumb/Nyx-gallery-1.jpg`} xpreview={`${baseUrl}front/electric_bike_product/hero-electric-nyx-hx/medium/Nyx-gallery-1.jpg`} title="" alt='img' />
                                                                </a>
                                                                <a href={`${baseUrl}front/electric_bike_product/hero-electric-nyx-hx/large/Nyx-gallery-2.jpg`}>
                                                                    <img className="xzoom-gallery5" width="117" src={`${baseUrl}front/electric_bike_product/hero-electric-nyx-hx/thumb/Nyx-gallery-2.jpg`} xpreview={`${baseUrl}front/electric_bike_product/hero-electric-nyx-hx/medium/Nyx-gallery-2.jpg`} title=""alt='img' />
                                                                </a>
                                                                <a href={`${baseUrl}front/electric_bike_product/hero-electric-nyx-hx/large/Nyx-gallery-3.jpg`}>
                                                                    <img className="xzoom-gallery5" width="117" src={`${baseUrl}front/electric_bike_product/hero-electric-nyx-hx/thumb/Nyx-gallery-3.jpg`} xpreview={`${baseUrl}front/electric_bike_product/hero-electric-nyx-hx/medium/Nyx-gallery-3.jpg`} title="" alt='img' />
                                                                </a>
                                                                <a href={`${baseUrl}front/electric_bike_product/hero-electric-nyx-hx/large/Nyx-gallery-4.jpg`}>
                                                                    <img className="xzoom-gallery5" width="117" src={`${baseUrl}front/electric_bike_product/hero-electric-nyx-hx/thumb/Nyx-gallery-4.jpg`} xpreview={`${baseUrl}front/electric_bike_product/hero-electric-nyx-hx/medium/Nyx-gallery-4.jpg`} title="" alt='img' />
                                                                </a> */}
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    {/* <!-- end --> */} 
                                                </div>
                                              </div>

                                              </div>



                                              {/* ========================================================================================= */}
                                          
                                          
                                              <div className="col-lg-6 align-self-center productdetailContent">
                                        <div className="single-pro-detail">
                                            <p className="mb-1">{product.CATEGORYNAME}</p>
                                            <div className="custom-border mb-3"></div>
                                            <h3 className="pro-title">{product.Product_Name}</h3>
                                            <p className="text-muted mb-0">Morden and good look model 2024 </p>
                                            
                                            <h2 className="pro-price"> <span>{product.SKU}</span> <span>₹ {product.Our_Cost}</span></h2>
                                            <ul className="list-inline mb-2 prdouctStockReview">
                                                <li className="list-inline-item"><i className="fas fa-box-open"></i>  Inventory: <span>{product.CurrentStock}</span></li>
                                                <li className="list-inline-item"><i className="fas fa-star"></i> Supplier Score: <span>4.5/5</span></li>
                                                <li className="list-inline-item"><i className="fas fa-shopping-bag"></i> Weight: <span>500 gms</span></li>
                                                <li class="list-inline-item calculatorBtn"><a href="javascript:void(0)" data-toggle="modal" data-target="#calculatorSec"><i class="fa-solid fa-calculator"></i> Calculator </a></li>
                                            </ul>
                                            <ViewMoreSection />
                                            <button type="button" className="btn btn-primary mb-3 pushtoBtn" onClick={()=>pushProduct(product)}>Push to Shopify <i className="fa-solid fa-arrow-right"></i></button>
                                               
                                            
                                        
                                        </div>
                                    </div>

                                    {/* ======================================================================================================== */}

                                    <ProductDetailsBottomSection />


                                    
                                          
                                          </div>
                                   </div>

                                  </div>
                             </div>
                          </div>
                      </div>

              </div>
          </div>
         <Calculator />
         <Footer />
        </section>
        <HiddenCompnents pushData={pushData} setPushDataFun={setPushDataFun} setVariantPushDataFun={setVariantPushDataFun}/>
        
       
         
       
    </>
  )
}

export default ProductDetails
