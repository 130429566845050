import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const FailedToSync = ({activeTab}) => {

    
    useEffect(() => {
        let table;
    
        if (window.$ && window.$.fn.DataTable) {
          // Initialize DataTable
          table = window.$('#DataTables_Table_3').DataTable();
        }
    
        // Cleanup on component unmount
        return () => {
          if (table && window.$.fn.DataTable.isDataTable('#DataTables_Table_3')) {
            // table.destroy(true);
          }
        };
      }, []); // Empty dependency array to run only on mount/unmount

  return (
    <div style={{display:`${activeTab === 'faildToSync' ? '' : 'none'}`}}>
      
      <table className="table p-0 orderlisttable dataTable lms_table_active collapsed "  id='DataTables_Table_3' >
                                                            <thead>
                                                                <tr role="row">
                                                                    <th>S.no</th>
                                                                   
                                                                    <th style={{minWidth:'200px'}}> Item</th>
                                                                    <th style={{minWidth:'100px'}}> Order no</th>
                                                                    <th>Date</th>
                                                                    <th> Qty</th>
                                                                    <th> Price</th>
                                                                    <th> Margin </th>
                                                                    <th> Customer</th>
                                                                    <th> Mobile</th>
                                                                    <th> Status</th>
                                                                    
                                                                </tr>
                                                             
                                                                </thead>
                                                                <tbody >
        <tr role="row" className="odd">
            <td>1</td>
             
            <td tabindex="0" className="sorting_1">
                RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2
            </td>
            <td className="color_text_6">#1034 14 Apr 2024</td>
            <td className="color_text_6">21/Apr/2024</td>
            <td className="color_text_6">1</td>
            <td className="color_text_6"> 699</td>
            <td className="color_text_6">475</td>
            <td className="color_text_6">Rajeshbhai -</td>
            <td className="color_text_6">9998163653</td>
            <td><Link to="#" className="status_btn">Active</Link></td>
            
        </tr>
        <tr role="row" className="odd">
            <td>2</td>
            
            <td tabindex="0" className="sorting_1">
                RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2
            </td>
            <td className="color_text_6">#1034 14 Apr 2024</td>
            <td className="color_text_6">21/Apr/2024</td>
            <td className="color_text_6">1</td>
            <td className="color_text_6"> 699</td>
            <td className="color_text_6">475</td>
            <td className="color_text_6">Rajeshbhai -</td>
            <td className="color_text_6">9998163653</td>
            <td><Link to="#" className="status_btn">Active</Link></td>
           
             
        </tr>

        </tbody>
        </table>
    </div>
  )
}

export default FailedToSync
