import React from 'react'
import OfferModel from './offerModel'

const ViewMoreSection = () => {
  return (
    <>
      <button type="button" className="btn btn-outline-info viewDiscoutnOffer" data-toggle="modal" data-target="#viewDiscoutnOffer">View Discounted Offer</button>
    <div className="pushsomeInfo mt-3">
                                                  
                                                <div className="card_box box_shadow position-relative mb_30">
                                                    <div className="box_body" style={{padding: '0px'}}>
                                                        <div className="default-according" id="accordion">
                                                            <div className="card">
                                                                <div className="card-header" id="headingOne">
                                                                    <h5 className="mb-0">
                                                                        <button className="btn btn-link hiddenchangeBtn collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                            <i className="fa-solid fa-arrow-down"></i> RTO &amp; RVP charges are applicable and vary depending on the
                                                                            Product weight. <a href="#t" id="hiddenchangeBtnlabel">View Changes</a>
                                                                        </button>
                                                                    </h5>
                                                                </div>
                                                                <div className="collapse" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordion">
                                                                    <div className="card-body">
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">
                                                                                        <p>Charges </p><span>(For this product)</span>
                                                                                    </th>
                                                                                    <th scope="col">
                                                                                        <p>RTO</p> <span>(All inclusive)</span>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>All 3PLs</th>
                                                                                    <td>₹ 70</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <p style={{fontSize: '15px', fontWeight: '700', color: '#111'}}>
                                                                            Note:
                                                                        </p>
                                                                        <ul className="normalList">
                                                                            <li>RTO &amp; RVP will be changed to actual numbers.</li>
                                                                            <li>RVP Will be Changed on orders where Supplier is not found to be at fault.</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                
                                            
                                            </div>

                                            <OfferModel />
                                            </>
  )
}

export default ViewMoreSection
