import React from 'react'
import { baseUrl } from '../helper/helper'

const HighPerformance = () => {
  return (
    <div class="highperfPr mb_30">
                    <div class="homeprHeader">
                        <h5>High Performance Products</h5>
                       
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <a href="#">
                                <div class="heighperfPrCard">
                                    <div class="heighperfPrImg">
                                        <img src={`${baseUrl}front/img/heigh-performance-pr/img1.jpg`} alt="img" />
                                    </div>
                                    <div class="heighperfContent">
                                        <h4>Deals In <br /><span>40-50</span> %</h4>
                                        <a href="#">View : <span>38</span></a>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-4">
                            <a href="#">
                                <div class="heighperfPrCard colordefault">
                                    <div class="heighperfPrImg">
                                        <img src={`${baseUrl}front/img/heigh-performance-pr/img2.jpg`} alt="img" />
                                    </div>
                                    <div class="heighperfContent">
                                        <h4>Deals In <br /><span>50-60</span> %</h4>
                                        <a href="#">View : <span>41</span></a>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-4">
                            <a href="#">
                                <div class="heighperfPrCard">
                                    <div class="heighperfPrImg">
                                        <img src={`${baseUrl}front/img/heigh-performance-pr/img3.jpg`} alt="img" />
                                    </div>
                                    <div class="heighperfContent">
                                        <h4>Deals In <br /><span>60-70</span> %</h4>
                                        <a href="#">View : <span>10</span></a>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
  )
}

export default HighPerformance
