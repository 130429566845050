import React, { useEffect, useState } from 'react'; 
import ChatMessage from './chatMessage'; 

export default function HiddenCompnents({pushData, setPushDataFun, setVariantPushDataFun}) {


    const[errors, setErrors] = useState({});

    let newErrors  = {};
    const submitProductPushData =(e)=> {
        e.preventDefault(); 
        pushData.Varient.forEach((variant)=>{ 
            if(variant.sprice && variant.price > parseFloat(variant.sprice)){
                newErrors[`${variant.PVID}_error`] = 'Selling Price must be grater from Plateform Price';
            }else{
                newErrors[`${variant.PVID}_error`] = '';
            }
        })
        setErrors(newErrors);
        alert("Product push is under working");
      
    }

   

    const focasOut = (errorName) => {
  console.log(errorName, errors);

  const updatedErrors = { ...errors }; 

  pushData.Varient.forEach((variant) => { 
    
    if (variant.sprice && variant.price > parseFloat(variant.sprice)) {
        updatedErrors[errorName] = 'Selling Price must be greater than Platform Price';  
    } else {
      updatedErrors[errorName] = ''; 
    }
  }); 
  setErrors(updatedErrors); 
}
 


     
    
  return (
    <>
         <ChatMessage /> 

<div className="pushtoSidebar">
        <div className="pushtosideHeader">
            <h4>List on Shopify</h4>
            <a href="#h" className="pushtoCloser"><i className="fa-solid fa-xmark"></i></a>
        </div>
        <div className="pushtoProduct">
            <div className="pushtoimg">
                <img src={pushData.docname} alt="img" />
            </div>
            <div className="pushtoPrOther">
                <div className="PushppName">
                    <h5> {pushData.Product_Name}</h5>
                    <p>{pushData.SKU} <i className="fa-solid fa-copy"></i></p>
                    {/* <!-- <h4 className="psidePrice">₹ <span>220.00</span></h4> --> */}
                </div>
            </div>
        </div>
        <form action='#' onSubmit={(e)=>submitProductPushData(e)} data-parsley-validate>
        <div className="row">
           
            <div className="col-md-12">
                <div className="pushtosideHeader">
                    <h4>Select Varient</h4>
                </div>

                {pushData.Varient.length && pushData.Varient.map((variant,index)=>(
                    <div className="row selectVarientr">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="plateformPrice" className="form-label">Plateform Price</label>
                                <input type="text" className="form-control"  placeholder="" value={variant.price} readOnly   required  />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="plateformPrice" className="form-label">Set Your Selling Price (₹)</label>
                                <input type="text" className="form-control"   placeholder=""  value={variant.sprice}  required
                                onChange={(e)=>setVariantPushDataFun(e)} onBlur={()=>focasOut(`${variant.PVID}_error`)}  name={`${variant.Variant_Name}`} /> 
                                <span style={{fontSize:'10px', color:'red',}} className={variant.PVID}>{errors[`${variant.PVID}_error`]}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="plateformPrice" className="form-label">Margin (₹)</label>
                                <div className="yourMargin">
                                    <span>Your Margin</span><span className="marginShow">₹ {variant.margin}</span>
                                </div>
                            </div>
                        </div>
                </div> 
                ))}
                <div className="col-md-12">
                    <div className="pushOtherDetails">
                        <div className="form-group">
                            <label htmlFor="inputState">Push Product On</label>
                            <select id="inputState" className="form-control"  onChange={(e)=>setPushDataFun(e)} name="pushProductOn" required>
                                <option value="" >Select Portal</option>
                                {pushData.Portal.map((item)=>(
                                        <option value={item.MDAPIID}>{item.Portal}</option>
                                ))}
                                
                            </select>
                        </div>
                    </div>
                </div>
                
                {/* <div className="row selectVarientr">
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label htmlFor="plateformPrice" className="form-label">Plateform Price</label>
                            <input type="text" className="form-control"id="plateformPrice" placeholder="" value={pushData.Our_Cost} readOnly   required  />
                          </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label htmlFor="plateformPrice" className="form-label">Set Your Selling Price (₹)</label>
                            <input type="text" className="form-control" id="sell_price" placeholder=""  value={pushData.sell_price}  required
                            onChange={(e)=>setPushDataFun(e)} name='sell_price' />
                          </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label htmlFor="plateformPrice" className="form-label">Margin (₹)</label>
                            <div className="yourMargin">
                                <span>Your Margin</span><span className="marginShow">₹ {pushData.margin}</span>
                            </div>
                          </div>
                    </div>
                </div>
                <div className="row selectVarientr">
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label htmlFor="plateformPrice" className="form-label">Plateform Price</label>
                            <input type="text" className="form-control" id="plateformPrice" placeholder="" value="220.00" readOnly  />
                          </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label htmlFor="plateformPrice" className="form-label">Set Your Selling Price (₹)</label>
                            <input type="text" className="form-control" id="plateformPrice" placeholder="" value="250.00" readOnly  />
                          </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label htmlFor="plateformPrice" className="form-label">Margin (₹)</label>
                            <div className="yourMargin">
                                <span>Your Margin</span><span className="marginShow">₹ 30.00</span>
                            </div>
                          </div>
                    </div>
                </div>
                <div className="row selectVarientr">
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label htmlFor="plateformPrice" className="form-label">Plateform Price</label>
                            <input type="text" className="form-control" id="plateformPrice" placeholder="" value="220.00" readOnly />
                          </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label htmlFor="plateformPrice" className="form-label">Set Your Selling Price (₹)</label>
                            <input type="text" className="form-control" id="plateformPrice" placeholder="" value="250.00" readOnly  />
                          </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-3">
                            <label htmlFor="plateformPrice" className="form-label">Margin (₹)</label>
                            <div className="yourMargin">
                                <span>Your Margin</span><span className="marginShow">₹ 30.00</span>
                            </div>
                          </div>
                    </div>
                </div> */}
            </div>
        </div>
        <div className="pushsomeInfo">
            <div className="card_box box_shadow position-relative mb_30">
                <div className="box_body" style={{padding: '0px'}}>
                    <div className="default-according" id="accordion">
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed hiddenchangeBtn" data-toggle="collapse" data-target="#collapseOne"
                                        aria-expanded="false" aria-controls="collapseOne">
                                        <i className="fa-solid fa-arrow-down"></i> RTO & RVP charges are applicable and vary depending on the
                                        Product weight. <a href="#dummy" id="hiddenchangeBtnlabel">View Changes</a>
                                    </button>
                                </h5>
                            </div>
                            <div className="collapse" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <p>Charges </p><span>(For this product)</span>
                                                </th>
                                                <th scope="col">
                                                    <p>RTO</p> <span>(All inclusive)</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>All 3PLs</th>
                                                <td>₹ 70</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style={{fontSize: '15px', fontWeight: '700', color: '#111'}}>
                                        Note:
                                    </p>
                                    <ul className="normalList">
                                        <li>RTO & RVP will be changed to actual numbers.</li>
                                        <li>RVP Will be Changed on orders where Supplier is not found to be at fault.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <button type="submit" className="btn btn-primary mb-3 pushtoBtn">Push to Shopify <i className="fa-solid fa-arrow-right"></i></button>
           
        </div>
        </form>
    </div>

        <div id="back-top" style={{display: 'none'}}>
          <a title="Go to Top" href="#abc">
            <i className="ti-angle-up" />
          </a>
        </div>
    </>
  )
}
