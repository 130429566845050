import React from 'react'
import { baseUrl } from '../../helper/helper'

const VideoCart = ({data}) => {
  return (
    <>
       <div className="col-md-6">
                          <div className="dashdataCard supportInfoOtherCard settingCard mb-3">
                            <div className="plan_left d-flex align-items-center">
                              <div className="thumb">
                                <img src={`${baseUrl}front/img/icon/video-playlist-icon.png`} alt="icon" />
                              </div>
                              <div>
                                <h5>{data.heading}</h5>
                              </div>
                            </div>
                            <div className="videoPlayContainer">
                              <iframe
                                width="100%"
                                height={346}
                                src={data.url}
                                title={data.title}
                                frameBorder={0}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen=""
                              />
                            </div>
                          </div>
                        </div>
    </>
  )
}

export default VideoCart
