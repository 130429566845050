import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { baseUrl } from '../../helper/helper' ;
import { useNavigate } from 'react-router-dom'; 

 
import $ from 'jquery';
import 'parsleyjs';
import { loginUser } from '../../Http/controllers/userController';



const UserLogin = () => {

    const navigate = useNavigate()


    const formRef = useRef(null); 
    const [signInData, setSignInData] = useState({ 
        email : '', 
        password : '',
        
    });

    const submitLoginForm= async (e)=>{
        e.preventDefault();
         
        try{
            const result = await loginUser(signInData);
            console.log(result)
            if(result.status){
                navigate('/');
            }
        }catch(error){
            console.log(error)
        }
    }

    const setUserData =(e)=>{ 

        const { name, value } = e.target; 
            setSignInData({ 
                    ...signInData,
                    [name]: value
                }); 
             console.log(signInData);
    }


    useEffect(() => {
        
        if (formRef.current) {
          $(formRef.current).parsley();
        }
    
        // Clean up Parsley on form unmount
        return () => {
          if (formRef.current) {
            $(formRef.current).parsley().destroy();
          }
        };
      }, []);


  return (
    <div className='crm_body_bg'>
      
      <section className="position-relative">
        <div className="site-width">
            <div className="headerSignup">
                <div className="logoSec">
                    <Link to="/">
                    <img src={`${baseUrl}front/img/logo-.png`} alt="logo" /></Link>
                </div>
            </div>
           
        </div>
        <div className="bnrForm loginForm pt-50 pb-50">
            <div className="titleSection">
                <div className="text-center">
                    <h2>
                        Unlock Your Success With Seamless Dropshipping
                    </h2>
                    <span className="divider">
                        <img src={`${baseUrl}front/img/underline.png`} alt="img" />
                    </span>
                </div>
            </div>
            <div className="site-width">
                <div className="row align-items-center mainRow">
                    <div className="col-md-6">
                      
                        <div className="imgFull">
                            <img src={`${baseUrl}front/img/login-img.png`} alt="img" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="bannerForm">
                            <h4 style={{textAlign: 'center'}}>Let's Begin!</h4>
                            <form className="form signupForm" id="" action="index.html" onSubmit={(e)=>submitLoginForm(e)} ref={formRef}>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>
                                                <input className="input" type="email" placeholder="" name="email" value={signInData.email} onChange={(e)=>setUserData(e)}
                                                required />
                                                <span><span>Enter Email *</span></span>
                                                
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>
                                                <input className="input" type="password" name="password" placeholder="" value={signInData.password} onChange={(e)=>setUserData(e)} 
                                                required /> 
                                                <i className="toggle-password fa fa-fw fa-eye-slash"></i>
                                            </label>
                                        </div>
                                    </div> 
                                    <div className="col-md-12">
                                        <div className="form-group ">
                                            <a href="forget-password.html">Forget Password?</a>
                                        </div>
                                    </div>
                                </div>
                         
                                <div className="defaultBtn willFullSize mt-3">
                                    <button className=""type='submit'>
                                        <span className="headerCareer">Sign In</span>
                                    </button>
                                </div>
                                
                                <div className="formFooter text-center">
                                    <p>Don't have an account yet? <Link to="/signup">Sign Up</Link></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer_part normal">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="footer_iner text-left">
                            <p>2024 © Routeway | All Rights Reserved</p>
                        </div>
                    </div>
                    <div className="col-md-6 aks-logo">
                        <div className="ltn__copyright-menu text-end">
                            <a href="https://www.akswebsoft.com" title="AKS Websoft Consulting Pvt. Ltd." target="_blank"  > <img src={`${baseUrl}front/img/aks.png`} alt="AKS Websoft Consulting Pvt. Ltd." /></a>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    </div>
  )
}

export default UserLogin
