import React, { useEffect, useState , Suspense, useContext} from 'react'

import Sidebar from '../components/sidebar' 
import Header from '../components/header'
import Footer from '../components/footer'
import HiddenCompnents from '../components/hiddenCompnents'
import $ from 'jquery';
import { Link, useParams } from 'react-router-dom'

import { productsData, categoryData } from '../Products/product'
import HomeProduct from '../components/homeProduct'
import { landingProducts } from '../jsonData/LandigProduct'
import { productDeatil } from '../jsonData/productdetails'
import { AppContext } from '../globleDataComponent/contaxtData'
const Products = React.lazy(() => import('../components/products'))

export default function Category() {
  const { globalData, setGlobalData } = useContext(AppContext);

  const {slug} =useParams();
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentCategoryProduct, setCurrentCategoryProduct] = useState([]);
  const [pushData , setPushData] = useState({
              SKU: '',
              Product_ID: 21,
              CATEGORYNAME: '',
              Product_Name: '',
              CurrentStock: 0,
              Our_Cost: 0,
              docname: 0,
              Selected: 0,
              sell_price:0,
              margin :0,
              Varient :[],
              Portal : [],
              pushProductOn:'',
        });

        

        const setVariantPushDataFun =(e)=>{
          const { name, value } = e.target;
          setPushData(pushData => (
          {
            
            ...pushData,
            Varient: pushData.Varient.map(variant => 
              variant.Variant_Name === name 
                ? {
                    ...variant, 
                    sprice: value, 
                    margin: value - variant.price  
                  }
                : variant
            )
          }));
    
          console.log(pushData);
        }
    
        const setPushDataFun=(e)=>{
          const { name, value } = e.target; 
          setPushData({ ...pushData,  [name]: value }); 
        }
        
     
  useEffect(() => {
       
    // Open sidebar on click of 'infoSideBarOpener'
  //   $('.pushtoBtn').click(function() {
  //      $('.pushtoSidebar').animate({ right: '0px' }, 300); 
  //  });

   // Close sidebar on click of 'infoSideBarCloser'
   $('.pushtoCloser').click(function() {
       $('.pushtoSidebar').animate({ right: '-100%' }, 300);
   }); 
 
 }, []);

    useEffect(()=>{
      const CategoryDataBySlug = landingProducts.ProductCategory.find((cate)=>cate.slug === slug);
      const categoryProducts = landingProducts.Product.filter(product=>product.CATEGORYNAME ===CategoryDataBySlug.CATEGORYNAME );
      setCurrentCategory(CategoryDataBySlug);
      setCurrentCategoryProduct(categoryProducts);
    },[slug])
 

    function pushProduct(productData){ 

      const productDetails = productDeatil;
      $('.pushtoSidebar').animate({ right: '0px' }, 300);
      setPushData(
        {
            SKU: productData.SKU,
            Product_ID: productData.Product_ID,
            CATEGORYNAME: productData.CATEGORYNAME,
            Product_Name: productData.Product_Name,
            CurrentStock: productData.CurrentStock,
            Our_Cost: productData.Our_Cost,
            docname: productDetails.Image[0].FileName,
            Selected: productData.Selected ,
            sell_price: productData.Our_Cost,
            margin :0,
            Varient: productDetails.Varient,
            Portal: productDetails.Portal,
            pushProductOn :''
        }
      ) 
    }

  return (
    <>
  
        <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
          <Header/>
          <div className="main_content_iner overly_inner ">
            <div className="container-fluid p-0 ">
              <div className="row">
                <div className="col-12">
                  <div className="dashboard_header mb_50">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="dashboard_header_title">
                          <h3>{currentCategory ? currentCategory.CATEGORYNAME : 'Category'} ({currentCategoryProduct.length})</h3>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="dashboard_breadcam text-right">
                          <p><Link to="/">Home</Link> <i className="fas fa-caret-right" /> Category Listing</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="homeprList mb_30">
                    <div className="row">

                    {/* {currentCategoryProduct.map((product)=>(  
                        <Products pushProduct={pushProduct} product = {product}/>
                    ))} */}

              <Suspense fallback={<div>Loading...</div>}>
                      {currentCategoryProduct.map((product) => (
                        <Products key={product.id} pushProduct={() => pushProduct(product)} product={product} />
                      ))}
                    </Suspense>
                      
                    </div>
                    {/* <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a className="page-link" href="test" tabIndex={-1} aria-disabled="true">Previous</a>
                        </li>
                        <li className="page-item"><a className="page-link" href="test">1</a></li>
                        <li className="page-item active"><a className="page-link" href="test">2</a></li>
                        <li className="page-item"><a className="page-link" href="test">3</a></li>
                        <li className="page-item">
                          <a className="page-link" href="test">Next</a>
                        </li>
                      </ul>
                    </nav> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
       
        <HiddenCompnents pushData={pushData} setPushDataFun={setPushDataFun} setVariantPushDataFun={setVariantPushDataFun} />
    </>
  )
}
