import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { baseUrl } from '../../helper/helper'

const PushedProduct = ({openTab}) => {

    const [editProduct, setEditProduct] = useState(false)
    const tableData = {
        bLengthChange: false,
        bDestroy: true,
        language: {
          search: "<i class='ti-search'></i>",
          searchPlaceholder: 'Quick Search',
          paginate: {
            next: "<i class='ti-arrow-right'></i>",
            previous: "<i class='ti-arrow-left'></i>",
          },
        },
        columnDefs: [
          { visible: false },
        ],
        responsive: true,
        searching: true,
      }

    useEffect(() => {
        if (window.$ && window.$.fn.DataTable) {
            const table = window.$('#manage_product_table1').DataTable(tableData);
          

            // Cleanup on component unmount
            //   return () => {
            //     if (window.$.fn.DataTable.isDataTable('#DataTables_Table_2')) {
            //       table.destroy(true);
            //     }
            //   };
        }
    }, []);



  return (
    <>
    <div
    className={`tab-pane fade  ${openTab==="pushedToShopify"?"active show":''}`}
    id="pushedToShopify"
    role="tabpanel"
    aria-labelledby="pushedToShopify-tab"
  >
    <div className="QA_table">
      <div className="dataTables_wrapper no-footer">
        <table className="table orderlisttable dataTable lms_table_active p-0 collapsed " id="manage_product_table1">
          <thead>
            <tr role="row">
              <th
                scope="col"
                className="sorting_asc"
                tabIndex={0}
                rowSpan={1}
                colSpan={1}
                aria-sort="ascending"
                width="250px"
              >
                Product Details
              </th>
              <th>Pushed Date &amp; Time</th>
              <th>C-Code</th>
              <th>Routeway Price</th>
              <th width="100px">Selling Price</th>
              <th>Inventory</th>
              <th width="">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr role="row" className="odd">
              <td>
                <img
                  src={`${baseUrl}front/img/401_202300080656.jpeg`}
                  alt="img"
                  width="50px"
                />
                <a href="product-detail.html">
                  Roop Ujala Face Pack 200gm
                </a>
              </td>
              <td>09 Aug 2024, 10:16 AM</td>
              <td>R2428698</td>
              <td>₹298</td>
              <td>
                <p>₹1,059</p>
                <small className="text-success f_w_800">
                  Margin: ₹761
                </small>
              </td>
              <td>
                <i className="fa-solid fa-box-open" />
                50
                <i
                  data-toggle="tooltip"
                  title="Tooltip Link"
                  className="fa-solid fa-circle-exclamation"
                />
              </td>
              <td className="color_text_6">
                <div className="action_btns d-flex">
                  <Link
                    to="javascript:void(0)" 
                    onClick={()=>setEditProduct(!editProduct)}
                    className="action_btntwo mr_10"
                  >
                    <i className="far fa-edit" /> Edit
                  </Link>
                  <Link href="#" className="action_btntwo">
                    <i className="fa-solid fa-box-open" />
                    Request Inventory
                  </Link>
                </div>
              </td>
            </tr>
            <tr role="row" className="odd">
              <td>
                <img
                  src={`${baseUrl}front/img/401_202300080656.jpeg`}
                  alt="img"
                  width="50px"
                />
                <a href="product-detail.html">
                  Roop Ujala Face Pack 200gm
                </a>
              </td>
              <td>09 Aug 2024, 10:16 AM</td>
              <td>R2428698</td>
              <td>₹298</td>
              <td>
                <p>₹1,059</p>
                <small className="text-success f_w_800">
                  Margin: ₹761
                </small>
              </td>
              <td>
                <i className="fa-solid fa-box-open" />
                50
                <i
                  data-toggle="tooltip"
                  title="Tooltip Link"
                  className="fa-solid fa-circle-exclamation"
                />
              </td>
              <td className="color_text_6">
                <div className="action_btns d-flex">
                  <Link
                    to="#"
                    className="action_btntwo mr_10"

                   onClick={()=>setEditProduct(!editProduct)}
                  >
                    <i className="far fa-edit" /> Edit
                  </Link>
                  <a href="#" className="action_btntwo">
                    <i className="fa-solid fa-box-open" />
                    Request Inventory
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

<div class={`modal fade ${editProduct?"show":''}`} id="exampleModalCenter" tabindex="-1" role="dialog"
aria-labelledby="exampleModalCenterTitle" aria-modal="true" style={{display:`${editProduct?"block":'none'}`}} >
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
            <button type="button" class="close"  onClick={()=>setEditProduct(false)}>
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="PaymentMethod">Date</label>
                        <input class="form-control datepicker-here  digits" type="text"
                            data-range="true" data-multiple-dates-separator=" - "
                            data-language="en" />
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="PaymentMethod">DD ID</label>
                        <input class="form-control" type="text" />
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="PaymentMethod">Order ID</label>
                        <input class="form-control" type="text" />
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="PaymentMethod">Customer Name</label>
                        <input class="form-control" type="text" />
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="ProductName">Product Name</label>
                        <select class="nice_Select wide" id="ProductName">
                            <option>-- Select --</option>
                            <option>RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2</option>
                            <option>RW322 !!! Mini Electric Shaver</option>
                            <option>RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="OrderStatus">Order Status</label>
                        <select id="OrderStatus" class="nice_Select wide">
                            <option value="">Select </option>
                            <option value="">All </option>
                            <option value="1" class="">New</option>
                            <option value="2" class="">Confirmed</option>
                            <option value="3" class="d-none">Cancelled</option>
                            <option value="4" class="d-none">Pickup Initiated</option>
                            <option value="5" class="d-none">Pickup Cancelled</option>
                            <option value="6" class="d-none">Manifested</option>
                            <option value="7" class="d-none">Pickup Pending</option>
                            <option value="8" class="d-none">Pickup Completed</option>
                            <option value="9" class="d-none">In Transit</option>
                            <option value="10" class="d-none">Undelivered</option>
                            <option value="11" class="d-none">Out For Delivery</option>
                            <option value="12" class="d-none">Delivered</option>
                            <option value="13" class="d-none">RTO</option>
                            <option value="14" class="d-none">RTO In Transit</option>
                            <option value="15" class="d-none">RTO Delivered</option>
                            <option value="16" class="d-none">Booking in Process</option>
                            <option value="17" class="d-none">Cancel in Process</option>
                            <option value="18" class="d-none">Shipment Lost</option>
                            <option value="19" class="d-none">Shipment Damaged</option>
                            <option value="20" class="d-none">Order Confirmation In Process</option>
                            <option value="21" class="d-none">Shipment Cancelled</option>
                            <option value="22" class="d-none">Out for Pickup</option>
                            <option value="200" class="d-none">Cancelled on Dropdash</option>
                            <option value="201" class="d-none">Booked</option>
                        </select>

                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="PaymentMethod">Payment Method</label>
                        <select class="nice_Select wide" id="PaymentMethod">
                            <option value="">Select</option>
                            <option value="1">COD</option>
                            <option value="2">Prepaid</option>
                        </select>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="storeSelect">Store</label>
                        <select class="nice_Select wide" id="storeSelect">
                            <option>-- Select --</option>
                            <option value="1">RouteWay</option>
                            <option value="2">Shopify</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="PaymentMethod"
                        style={{display: 'block', visibility: 'hidden'}}>Date</label>
                    <button type="button" class="btn btn-secondary">Reset</button>
                    <button type="submit" class="btn btn-primary">Apply</button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary"   onClick={()=>setEditProduct(false)}>Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
</div>
</div>


<div id="back-top" style={{display: 'none'}}>
        <a title="Go to Top" href="#">
            <i class="ti-angle-up"></i>
        </a>
    </div>
</>
  )
}

export default PushedProduct
