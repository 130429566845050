import { useEffect } from "react";
import { Link } from "react-router-dom"

 

const TakenAction = ({tableTab, ntrActionOrder}) => {

    useEffect(() => {
        let table;
    
        if (window.$ && window.$.fn.DataTable) {
          // Initialize DataTable
          table = window.$('#DataTables_Table_3').DataTable();
        }
    
        // Cleanup on component unmount
        return () => {
          if (table && window.$.fn.DataTable.isDataTable('#DataTables_Table_3')) {
            // table.destroy(true);
          }
        };
      }, []); // Empty dependency array to run only on mount/unmount



  return (
    <div style={{display:`${tableTab==="ActionTaken"?'':'none'}`}}>
      
                                <table className="table orderlisttable dataTable lms_table_active p-0 collapsed" id="DataTables_Table_3">
                                                            <thead>
                                                                <tr role="row">
                                                                    <th>S.no</th>
                                                                    <th  >
                                                                        Order no
                                                                    </th>
                                                                    <th >
                                                                    Order Date</th>
                                                                    <th>SKU</th>
                                                                    {/* <th><input type="checkbox" className="formcheck" id="exampleCheck1" /></th> */}
                                                                    <th> Qty</th>
                                                                    <th style={{minWidth:'250px'}}> Product</th>
                                                                    <th>
                                                                        M/SP</th>
                                                                    <th style={{minWidth:'250px'}}>Customer</th>
                                                                    <th >
                                                                        Mobile</th>
                                                                    <th> AWB</th>

                                                                      <th>Status</th>
                                                                    
                                                                </tr>
                                                            </thead>
                                                             

                                                            <tbody >
        
                                                            {ntrActionOrder.length > 0 && ntrActionOrder.map((order, index)=>(

                                                                <tr role="row" className="odd">
                                                                <td>{index+1}</td>
                                                                
                                                                <td tabindex="0" className="sorting_1">
                                                                    {order.Order_No}
                                                                </td>
                                                                <td className="color_text_6">{`${(new Date(order.Order_Date).getDate())}/${(new Date(order.Order_Date).toLocaleString('default',{month:'long'}))}/${(new Date(order.Order_Date).getFullYear())}`}</td>
                                                                <td className="color_text_6">{order.SKU}</td>
                                                                <td className="color_text_6">1</td>
                                                                <td className=""> 
                                                                    <div  >
                                                                    <img src={order.ProfileImage} width={60} />
                                                                <div> {order.ProductName} </div>
                                                                </div></td>
                                                                <td className="color_text_6">{}</td>
                                                                <td className="color_text_6">{order.Shi_Add_Name}</td>
                                                                <td className="color_text_6">{order.Shi_Add_Phone}</td>
                                                                <td className="color_text_6">{order.Awb_Code}</td>
                                                                <td><Link to="#" className="status_btn">{order.shipment_status}</Link></td>
                                                               
                                                                </tr>  
                                                                ))}
                                                            </tbody> 
                                                            
                                                        </table>


    </div>
  )
}

export default TakenAction
