import React, { useContext, useEffect, useRef  } from 'react'
import Header from '../components/header'
import Filter from '../components/analyticComponents/fillter'
import ChartSection from '../components/analyticComponents/ChartSection'
import OrderStatusSection from '../components/analyticComponents/OrderStatusSection'
import Footer from '../components/footer'
import CountUp from 'react-countup';
//  import '../front/vendors/niceselect/js/jquery.nice-select.min.js'; 
 
 import Chart from 'chart.js/auto';

 import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-select/dist/js/bootstrap-select.min';
import PaymentModeChart from '../components/analyticComponents/paymentModeChart'
import ConfirmationStatusChart from '../components/analyticComponents/confirmationStatusChart'
import OrderStatuschart from '../components/analyticComponents/orderStatuschart'
import SalesDetailsSection from '../components/analyticComponents/salesDetailsSection'
import OrderStatusByState from '../components/analyticComponents/orderStatusByState'
import DeliverybyMargin from '../components/analyticComponents/deliverybyMargin'
import { AppContext } from '../globleDataComponent/contaxtData'
 

// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'jquery/dist/jquery.min.js'
// import 'bootstrap/dist/js/bootstrap.min.js'

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';


const Analytics = () => {
    const { globalData, setGlobalData } = useContext(AppContext);

    const chartRef = useRef(null);
    
    useEffect(()=>{

        var chBar = chartRef.current;
        if (chBar) {
            new Chart(chBar, {
                type: 'bar',
                data: {
                    labels: ["New Order", "Cancel By DS", "Cancel By System", "Delivered", "RTO"],
                    datasets: [
                        {
                            data: [13, 99, 61, 28, 112],
                            backgroundColor: '#007c84',  // Single color for all bars
                            borderRadius: 30,  // Add rounded corners with a radius of 10px
                            barThickness: 30,  // Adjust the thickness of the bars (optional)
                        }
                    ]
                },
                options: {
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            barPercentage: 0.4,
                            categoryPercentage: 0.5
                        }]
                    }
                }
            });
        }

        
    },[]);

   

    
   
  return (
    <>
 
       <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
       <Header />

       <div className="main_content_iner overly_inner">
            <div className="container-fluid p-0 ">

                <div className="row">
                    <div className="col-lg-12">
                        <Filter /> 
                        {/* ======================================================================= */}
                        </div>
                        <div className="col-md-12">
                        <div className="user_crm_wrapper">
                            <div className="row">
                                <div className="col-lg-2">
                                    <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                                        <div className="plan_left d-flex align-items-center">
                                            <div className="thumb">
                                                <img src="front/img/icon/orders.png" alt="" />
                                            </div>
                                            <div>
                                            <span>Total Orders</span>
                                            <p>47%</p>
                                                <h5><span className="counter">
                                                    <CountUp
                                                        start={0}
                                                        end={100}
                                                        duration={5000 / 1000} 
                                                        delay={0.1} 
                                                    />
                                                </span></h5>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-2">
                                    <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                                        <div className="plan_left d-flex align-items-center">
                                            <div className="thumb">
                                                <img src="front/img/icon/new-order.png" alt="" />
                                            </div>
                                            <div>
                                            <span>New Orders</span>
                                            <p>31%</p>
                                                <h5><span className="counter">
                                                     <CountUp
                                                        start={0}
                                                        end={30}
                                                        duration={5000 / 1000} 
                                                        delay={0.1} 
                                                    />
                                                    </span></h5>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div className="col-lg-2">
                                    <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                                        <div className="plan_left d-flex align-items-center">
                                            <div className="thumb">
                                                <img src="front/img/icon/Intransit.png" alt="" />
                                            </div>
                                            <div>
                                            <span>Intransit</span>
                                            <p>30%</p>
                                                <h5><span className="counter">
                                                <CountUp
                                                        start={0}
                                                        end={20}
                                                        duration={5000 / 1000} 
                                                        delay={0.1} 
                                                    /></span></h5>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div className="col-lg-2">
                                    <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                                        <div className="plan_left d-flex align-items-center">
                                            <div className="thumb">
                                                <img src="front/img/icon/Delivered.png" alt="" />
                                            </div>
                                            <div>
                                            <span>Delivered</span>
                                            <p>10%</p>
                                                <h5><span className="counter">
                                                <CountUp
                                                        start={0}
                                                        end={20}
                                                        duration={5000 / 1000} 
                                                        delay={0.1} 
                                                    />
                                                    </span></h5>
                                                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="col-lg-2">
                                    <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                                        <div className="plan_left d-flex align-items-center">
                                            <div className="thumb">
                                                <img src="front/img/icon/ndr.png" alt="" />
                                            </div>
                                            <div>
                                            <span>NDR</span>
                                            <p>8%</p>
                                                <h5><span className="counter">
                                                <CountUp
                                                        start={0}
                                                        end={10}
                                                        duration={5000 / 1000} 
                                                        delay={0.1} 
                                                    />
                                                    </span></h5>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="dashdataCard d-flex align-items-center justify-content-between">
                                        <div className="plan_left d-flex align-items-center">
                                            <div className="thumb">
                                                <img src="front/img/icon/rto.png" alt="" />
                                            </div>
                                            <div>
                                            <span>RTO</span>
                                            <p>78%</p>
                                                <h5><span className="counter">
                                                <CountUp
                                                        start={0}
                                                        end={45}
                                                        duration={5000 / 1000} 
                                                        delay={0.1} 
                                                    />
                                                    </span></h5>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    {/* ========================================================================================= */}
                   
                    <div className="col-lg-12">
                        <ChartSection />
                        <div class="row">
                            <PaymentModeChart />
                            <ConfirmationStatusChart />
                            <OrderStatuschart />
                        </div>
                        
                    
                        {/* <OrderStatusSection />
                        <OrderStatusSection />
                        <OrderStatusSection /> */}
                    </div>
                    <div class="row col-lg-12 mt-2">
                        <OrderStatusSection />
                        <SalesDetailsSection />
                        </div>

                        
                            <OrderStatusByState />
                            <DeliverybyMargin />
                </div>
            </div>
        </div>
        <Footer />
       </section>



       {/*  */}
    </>
  )
}

export default Analytics
