import React from 'react'
import BarChart from './BarChart'
import LineChart from './LineChart'

const ChartSection = () => {
  return (
    <>
      <div className="row">
                            <div className="col-xl-6">
                                <div className="white_card mb_30 card_height_100">
                                    <div className="white_card_header">
                                        <div className="row align-items-center justify-content-between flex-wrap">
                                            <div className="col-lg-4">
                                                <div className="main-title">
                                                    <h3 className="m-0">Order Status</h3>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 text-right d-flex justify-content-end">
                                                <select className="nice_Select2 max-width-220" style={{display: 'none'}}>
                                                    <option value="1">Show by month</option>
                                                    <option value="1">Show by year</option>
                                                    <option value="1">Show by day</option>
                                                </select><div className="nice-select nice_Select2 max-width-220" tabIndex="0"><span className="current">Show by month</span><div className="nice-select-search-box">
                                                    <input type="text" className="nice-select-search" placeholder="Search..." /></div><ul className="list"><li data-value="1" className="option selected">Show by month</li><li data-value="1" className="option">Show by year</li><li data-value="1" className="option">Show by day</li></ul></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white_card_body">
                                        <div className="chartjs-size-monitor">
                                            <div className="chartjs-size-monitor-expand">
                                                <div className=""></div></div>
                                                <div className="chartjs-size-monitor-shrink">
                                                    <div className="">
                                                        </div>
                                                        </div>
                                                        </div>
                                                        <BarChart />
                                        {/* <canvas id="chBar" width="673" height="336" style={{display: 'block', height: '269px', width: '539px'}} className="chartjs-render-monitor"></canvas> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="white_card mb_30 card_height_100">
                                    <div className="white_card_header">
                                        <div className="row align-items-center justify-content-between flex-wrap">
                                            <div className="col-lg-4">
                                                <div className="main-title">
                                                    <h3 className="m-0">Orders &amp; Margin</h3>
                                                </div>
                                            </div>
                                            {/* <!-- <div className="col-lg-4 text-right d-flex justify-content-end">
                                                <select className="nice_Select2 max-width-220">
                                                    <option value="1">Show by month</option>
                                                    <option value="1">Show by year</option>
                                                    <option value="1">Show by day</option>
                                                </select>
                                            </div> --> */}
                                        </div>
                                    </div>
                                    <div className="white_card_body"><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
                                    <LineChart />
                                        {/* <canvas id="chLine" width="673" height="336" style={{display: 'block', height: '269px', width: '539px'}} className="chartjs-render-monitor"></canvas> */}
                                    </div>
                                </div>
                            </div>
                        </div>
    </>
  )
}

export default ChartSection
