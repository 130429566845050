import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const DeliveredAction = ({tableTab}) => {

    useEffect(() => {
        let table;
    
        if (window.$ && window.$.fn.DataTable) {
          // Initialize DataTable
          table = window.$('#DataTables_Table_2').DataTable();
        }
    
        // Cleanup on component unmount
        return () => {
          if (table && window.$.fn.DataTable.isDataTable('#DataTables_Table_2')) {
            // table.destroy(true);
          }
        };
      }, []); // Empty dependency array to run only on mount/unmount


  return (
    <div style={{display:`${tableTab==="deliveredAction"?'':'none'}`}}>
      
      <table className="table orderlisttable dataTable lms_table_active p-0 collapsed" id='DataTables_Table_2'>
                                                            <thead>
                                                                <tr role="row">
                                                                    <th>S.no</th>
                                                                    <th>Order no</th>
                                                                    <th>Order Date</th>
                                                                    <th>SKU</th> 
                                                                    <th> Qty</th>
                                                                    <th style={{minWidth:'250px'}}> Product</th>
                                                                    <th>M/SP</th>
                                                                    <th>Customer</th>
                                                                    <th>Mobile</th>
                                                                    <th>AWB</th>
                                                                    <th>Status</th>
                                                                    
                                                                </tr>
                                                            </thead>
                                                            

                                                            <tbody>
        
                                                                <tr role="row" className="odd">
                                                                    <td>1</td>
                                                                    <td><input type="checkbox" className="formcheck"
                                                                            id="exampleCheck1" /></td>
                                                                    <td tabindex="0" className="sorting_1">
                                                                        RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2
                                                                    </td>
                                                                    <td className="color_text_6">#1034 14 Apr 2024</td>
                                                                    <td className="color_text_6">21/Apr/2024</td>
                                                                    <td className="color_text_6">1</td>
                                                                    <td className="color_text_6"> 699</td>
                                                                    <td className="color_text_6">475</td>
                                                                    <td className="color_text_6">Rajeshbhai -</td>
                                                                    <td className="color_text_6">9998163653</td>
                                                                    <td><Link href="#" className="status_btn">Active</Link></td>
                                                                     
                                                                </tr>
                                                                <tr role="row" className="odd">
                                                                    <td>2</td>
                                                                    <td><input type="checkbox" className="formcheck"
                                                                            id="exampleCheck1" /></td>
                                                                    <td tabindex="0" className="sorting_1">
                                                                        RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2
                                                                    </td>
                                                                    <td className="color_text_6">#1034 14 Apr 2024</td>
                                                                    <td className="color_text_6">21/Apr/2024</td>
                                                                    <td className="color_text_6">1</td>
                                                                    <td className="color_text_6"> 699</td>
                                                                    <td className="color_text_6">475</td>
                                                                    <td className="color_text_6">Rajeshbhai -</td>
                                                                    <td className="color_text_6">9998163653</td>
                                                                    <td><a href="#" className="status_btn">Active</a></td> 
                                                                </tr> 
                                                            </tbody>


                                                            
                                                        </table>

    </div>
  )
}

export default DeliveredAction
