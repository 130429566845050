import React, { useContext } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import ReportHeader from "../components/reportComponents/reportHeader";
import { Link } from "react-router-dom";
import { AppContext } from "../globleDataComponent/contaxtData";

const TopNdrReasons = () => {
  const { globalData, setGlobalData } = useContext(AppContext);

  return (
    <div>
      <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
        <Header />

        <div className="main_content_iner overly_inner ">
          <div className="container-fluid p-0 ">
            <div className="row">
              <ReportHeader />

              <div className="col-md-12">
                <div className="white_card card_height_100 mb_20 ">
                  <div className="white_card_body QA_section orderList">
                    <div className="QA_table  mt-3 paymentInvoiceList">
                      <div className="QA_table tab-content mt-3">
                        <div id="" className="dataTables_wrapper no-footer ">
                          <table className="table orderlisttable dataTable p-0 collapsed ">
                            <thead>
                              <tr role="row">
                                <th>NDR Reason</th>
                                <th>Total Count</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr role="row" className="odd">
                                <td>Customer Not Available</td>
                                <td>14</td>
                                <td>
                                  <Link to="#" className="action_btn mr_10">
                                    <i className="fa-solid fa-cloud-arrow-down" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-primary smallbtn"
                                  >
                                    View
                                  </Link>
                                </td>
                              </tr>
                              <tr role="row" className="odd">
                                <td>Others</td>
                                <td>14</td>
                                <td>
                                  <Link to="#" className="action_btn mr_10">
                                    <i className="fa-solid fa-cloud-arrow-down" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-primary smallbtn"
                                  >
                                    View
                                  </Link>
                                </td>
                              </tr>
                              <tr role="row" className="odd">
                                <td>Payment Not Ready</td>
                                <td>8</td>
                                <td>
                                  <Link to="#" className="action_btn mr_10">
                                    <i className="fa-solid fa-cloud-arrow-down" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-primary smallbtn"
                                  >
                                    View
                                  </Link>
                                </td>
                              </tr>
                              <tr role="row" className="odd">
                                <td>Cancelled by Customer</td>
                                <td>1</td>
                                <td>
                                  <Link to="#" className="action_btn mr_10">
                                    <i className="fa-solid fa-cloud-arrow-down" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-primary smallbtn"
                                  >
                                    View
                                  </Link>
                                </td>
                              </tr>
                              <tr role="row" className="odd">
                                <td>Premises Closed</td>
                                <td>1</td>
                                <td>
                                  <Link to="#" className="action_btn mr_10">
                                    <i className="fa-solid fa-cloud-arrow-down" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-primary smallbtn"
                                  >
                                    View
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </div>
  );
};

export default TopNdrReasons;
