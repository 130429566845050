export const ordersData ={
  "OrderStatus": [
    {
      "SellerOrderStatus": 0,
      "TOrder": 4
    },
    {
      "SellerOrderStatus": 1,
      "TOrder": 142
    },
    {
      "SellerOrderStatus": 2,
      "TOrder": 57
    }
  ],
  "NewOrder": [
    {
      "OID": 2480706,
      "SKU": "RW453",
      "ProductName": "RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2",
      "Order_By_Name": "Rajeshbhai -",
      "RWPrice": 224,
      "SellingPrice": 699,
      "Margin": 475,
      "Quantity": 1,
      "Order_NO": "#1034</br>14 Apr 2024",
      "PaymentMode": "Cash on Delivery (COD)",
      "Shi_Add_Name": "Rajeshbhai -- 9998163653",
      "Name": "Rajeshbhai -",
      "Mobile": "9998163653",
      "Order_ID": "5775650652454",
      "Order_Date": "2024-04-21T14:02:28.96",
      "ProfileImage": "",
      "Statuss": null,
      "IsServiceable": 1,
      "ECPID": 1
    },
    {
      "OID": 2480705,
      "SKU": "RW322",
      "ProductName": "RW322 !!! Mini Electric Shaver",
      "Order_By_Name": "hemsingh vishwakarma",
      "RWPrice": 320,
      "SellingPrice": 699,
      "Margin": 379,
      "Quantity": 1,
      "Order_NO": "#1033</br>14 Apr 2024",
      "PaymentMode": "Cash on Delivery (COD)",
      "Shi_Add_Name": "hemsingh vishwakarma- 9009450447",
      "Name": "hemsingh vishwakarma",
      "Mobile": "9009450447",
      "Order_ID": "5775643705638",
      "Order_Date": "2024-04-21T14:02:28.96",
      "ProfileImage": "",
      "Statuss": null,
      "IsServiceable": 1,
      "ECPID": 1
    },
    {
      "OID": 2480704,
      "SKU": "RW453",
      "ProductName": "RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2",
      "Order_By_Name": "Mr Krishna Kumar Patel",
      "RWPrice": 224,
      "SellingPrice": 699,
      "Margin": 475,
      "Quantity": 1,
      "Order_NO": "#1032</br>14 Apr 2024",
      "PaymentMode": "Cash on Delivery (COD)",
      "Shi_Add_Name": "Mr Krishna Kumar Patel- 9981164680",
      "Name": "Mr Krishna Kumar Patel",
      "Mobile": "9981164680",
      "Order_ID": "5775642231078",
      "Order_Date": "2024-04-21T14:02:28.96",
      "ProfileImage": "",
      "Statuss": null,
      "IsServiceable": 1,
      "ECPID": 1
    },
    {
      "OID": 2480703,
      "SKU": "RW453",
      "ProductName": "RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2",
      "Order_By_Name": "Jaspreet Kaur",
      "RWPrice": 224,
      "SellingPrice": 699,
      "Margin": 475,
      "Quantity": 1,
      "Order_NO": "#1031</br>14 Apr 2024",
      "PaymentMode": "Cash on Delivery (COD)",
      "Shi_Add_Name": "Jaspreet Kaur- 6239454086",
      "Name": "Jaspreet Kaur",
      "Mobile": "6239454086",
      "Order_ID": "5775641116966",
      "Order_Date": "2024-04-21T14:02:28.96",
      "ProfileImage": "",
      "Statuss": null,
      "IsServiceable": 1,
      "ECPID": 1
    }
  ],
  "Courrier": [
    {
      "courierId": 2,
      "courierName": "Blue Dart Express ( 0.00 extra charge)",
      "PreferCourier": 2,
      "Priority": 1
    },
    {
      "courierId": 3,
      "courierName": "Delhivery ( 0.00 extra charge)",
      "PreferCourier": null,
      "Priority": 2
    },
    {
      "courierId": 7,
      "courierName": "Xpressbees Surface ( 0.00 extra charge)",
      "PreferCourier": null,
      "Priority": 4
    },
    {
      "courierId": 6,
      "courierName": "Ecom Express ( 0.00 extra charge)",
      "PreferCourier": null,
      "Priority": 5
    },
    {
      "courierId": 11,
      "courierName": "Smartr ( 0.00 extra charge)",
      "PreferCourier": null,
      "Priority": 6
    },
    {
      "courierId": 9,
      "courierName": "eKart ( 0.00 extra charge)",
      "PreferCourier": null,
      "Priority": 7
    },
    {
      "courierId": 5,
      "courierName": "DTDC Surface ( 0.00 extra charge)",
      "PreferCourier": null,
      "Priority": 8
    },
    {
      "courierId": 12,
      "courierName": "Amazon ( 0.00 extra charge)",
      "PreferCourier": null,
      "Priority": 12
    },
    {
      "courierId": 13,
      "courierName": "Shadowfax ( 0.00 extra charge)",
      "PreferCourier": null,
      "Priority": 13
    }
  ],
  "Page": [
    {
      "TRecord": 4,
      "TPage": 1,
      "CPage": 1
    }
  ]
}