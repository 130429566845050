import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { loadScript } from '../../helper/helper'
import $ from 'jquery'
 

window.jQuery = window.$ =$;
const PaymentModeChart = () => {
 
  const chartRef = useRef(null);
  let chartInstance = useRef(null);


  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    ctx.height = 100;
    if (chartInstance.current) {
        chartInstance.current.destroy(); // Clean up the old chart instance if it exists
    }

    chartInstance.current = new Chart(ctx, {
        type: 'pie',
        data: {
            labels: ["COD", "Online"],
            datasets: [{
                data: [80, 20],
                backgroundColor: ["#0a7984", "#1cacba"],
                hoverBackgroundColor: ["#046d77", "#008996"]
            }]
        },
        options: {
            responsive: true,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(tooltipItem) {
                            const dataset = tooltipItem.dataset;
                            const total = dataset.data.reduce((prev, curr) => prev + curr, 0);
                            const currentValue = dataset.data[tooltipItem.dataIndex];
                            const percentage = Math.floor(((currentValue / total) * 100) + 0.5);
                            return tooltipItem.label + ': ' + percentage + '%';
                        }
                    }
                },
                datalabels: {
                    formatter: (value, ctx) => {
                        const sum = ctx.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
                        const percentage = (value * 100 / sum).toFixed(2) + "%";
                        return percentage;
                    },
                    color: '#fff'
                }
            }
        }
    });

    // Cleanup on component unmount
    return () => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
    };
}, []);

  return (
    <div className="col-xl-4">
              <div className="white_card mb_30 pichartitem">
                <div className="white_card_header">
                  <div className="row align-items-center justify-content-between flex-wrap">
                    <div className="col-lg-12">
                      <div className="main-title">
                        <h3 className="">Order by Payment Mode</h3>
                        <h2>8,945</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="white_card_body" style={{ display:'flex', justifyContent:'center' }}>
                  <div style={{height:'250px', width:"100%",display:'flex', justifyContent:'center' ,alignItems:'center'}}>

                  <canvas style={{ width: '50px', height: '190px' }} id="pieChartPaymentMode" ref={chartRef}></canvas>
                  </div>
                </div>
              </div>
            </div>
  )
}

export default PaymentModeChart
