import React, { useContext } from 'react'
import Footer from '../components/footer'
import VideoCart from '../components/supportComponents/videoCart'
import Header from '../components/header'
import { supportData } from '../Http/data/frontData'
import { AppContext } from '../globleDataComponent/contaxtData'


const Training = () => {
  const { globalData, setGlobalData } = useContext(AppContext);

  return (
    <div>
      
      <section className={`main_content dashboard_part large_header_bg ${globalData.menu?"full_main_content":''}`}>
      <Header />
      <div className="main_content_iner overly_inner"> 
            
                   {/* ======================================================================================== */}

                   <div className="supportVideos">
                      <div className="row">
                        {supportData.youTubeVideo.map((item)=>( 
                            <VideoCart key={item.id} data={item} />
                        ))}
                         
                        
                      </div>
                    </div>


                   {/* ======================================================================================== */}

        </div>

        <Footer />
        </section>
    </div>
  )
}

export default Training
